import { ENUM_CS_ROLES, ENUM_PENDING_SOURCE } from './enum';
import { ENUM_STATUS } from './enum';
import { CreateOrderFormType } from './types';

export const CS_ROLES: ENUM_CS_ROLES[] = [
  ENUM_CS_ROLES.ADMIN,
  ENUM_CS_ROLES.CS,
  ENUM_CS_ROLES.FRONT_DESK,
  ENUM_CS_ROLES.SUPER_ADMIN,
  ENUM_CS_ROLES.CS_ADMIN,
  ENUM_CS_ROLES.CS_LEAD,
  ENUM_CS_ROLES.CUSTOMER_SERVICE_INBOUND,
  ENUM_CS_ROLES.CUSTOMER_SERVICE_OUTBOUND
];

export const ROUTE_KEYS = {
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password/:token',
  PENDING_TASKS: '/pending-tasks',
  REVIEWS: '/reviews',
  SEARCH: '/search',
  SEARCH_RESULT: '/search-result/:filterType/:filterValue',
  ORDER_DETAILS: '/order/:order_code',
  NEW_ORDER: '/:type/:id',
  SETTINGS: '/settings',
  LIVE_TRIPS: '/live-trips'
};

export const ORDER_ACTION = {
  CREATE: 'create-order',
  EDIT: 'edit-order'
};

export const STORAGE_KEYS = {
  TOKEN: 'token',
  USER_ID: 'id',
  EMAIL: 'email',
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  ROLE: 'role',
  COKITCHEN: 'cokitchen',
  COKITCHEN_LIST: 'cokitchen_list',
  STAFF_NUMBER: 'staff_number',
  ONLINE: 'online'
};

export const PENDING_SOURCE: Record<ENUM_PENDING_SOURCE, any> = {
  KITCHEN: {
    text: 'KITCHEN',
    bgColor: '#EEF9EB',
    textColor: '#46AE31',
    borderColor: '#A8E39C'
  },

  DELIVERY: {
    text: 'DELIVERY',
    bgColor: '#F5F5F5',
    borderColor: '#DEDEDE',
    textColor: '#A3A3A3'
  },
  REVIEW: {
    text: 'REVIEW',
    bgColor: '#FEFAEB',
    textColor: '#F9C320',
    borderColor: '#FCE08D'
  }
};

export const ORDER_TAG = {
  surge: { text: '⚡ Surge', bgColor: '#0EA3FD' }, // blue
  late: { text: '🕑 LATE', bgColor: '#E60A2B' }, // red
  runningLate: { text: '🕜 Running late', bgColor: '#F9C320' } // yellow
};

export const ITEM_TYPE_TAG = {
  FOOD: { text: 'Food', bgColor: '#0EA3FD' }, // blue
  SHOP: { text: 'Shop', bgColor: '#F9C320' } // yellow
};

export const ORDER_STATUS: Record<ENUM_STATUS, any> = {
  ongoing: {
    text: 'Ongoing',
    bgColor: '#EEF9EB',
    textColor: '#46AE31',
    borderColor: '#A8E39C'
  }, // green
  pending: {
    text: 'Pending',
    bgColor: '#F5F5F5',
    borderColor: '#DEDEDE',
    textColor: '#A3A3A3'
  }, // gray
  ready: {
    text: 'Ready',
    bgColor: '#E6F5FF',
    textColor: '#0EA3FD',
    borderColor: '#81CFFE'
  }, // blue
  processing: {
    text: 'Processing',
    bgColor: '#FEFAEB',
    textColor: '#F9C320',
    borderColor: '#FCE08D'
  }, // yellow
  completed: {
    text: 'Completed',
    bgColor: '#EAF8E7',
    textColor: '#46AE31',
    borderColor: '#A8E39C'
  }, //green
  cancelled: {
    text: 'Cancelled',
    bgColor: '#FEE2E6',
    textColor: '#E60A2B',
    borderColor: '#FCB6C0'
  } //green
};

export const MONTHS_OF_YEAR = [
  'Janaury',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const CREATE_ORDER_LIST = [
  {
    name: 'Restaurants',
    emoji: '🏡'
  },
  {
    name: 'Main dish',
    emoji: '🍔'
  }
];

export const CreatOrderForm: CreateOrderFormType = {
  brandName: '',
  selectedMeal: '',
  is_group_order: false,
  basket: [],
  meal_categories: [],
  selectedMeals: {},
  deletedMeals: {}
};

export const BANK_NAMES = {
  ACCESS_BANK: 'Access Bank'
};
export const BRAND_NAMES = {
  GREEN_GOURMET: 'Green Gourmet',
  COCKTAIL_CLUB: 'The Cocktail Club'
};
export const ALL_POLYGONS_ID = 'ALL';

export const COKITCHEN_LOCATIONS = {
  LEKKI: 'Cokitchen Lekki'
};
