import { ALL_POLYGONS_ID } from '../../../utils/constants';
import { LiveTripOrderType } from '../../../utils/types';
import { ExtendedFilterType } from '../types';

const getOngoingTrips = (orders: LiveTripOrderType[]) =>
  orders?.filter(
    ({ calculated_order, kitchen_dispatched }) => !calculated_order.pickup && kitchen_dispatched
  );

const getPendingTrips = (orders: LiveTripOrderType[]) =>
  orders?.filter(({ kitchen_accepted }) => !kitchen_accepted);

const getReadyTrips = (orders: LiveTripOrderType[]) =>
  orders?.filter(
    ({ kitchen_dispatched, calculated_order, kitchen_prepared, shop_prepared }) =>
      kitchen_prepared && shop_prepared && !calculated_order.pickup && !kitchen_dispatched
  );

const getProcessingTrips = (orders: LiveTripOrderType[]) =>
  orders?.filter(
    ({ kitchen_prepared, shop_prepared, kitchen_dispatched, kitchen_accepted, shop_accepted }) =>
      (kitchen_accepted || shop_accepted) &&
      (!kitchen_prepared || !shop_prepared) &&
      !kitchen_dispatched
  );

export const StatusFilterConfig = (orders: LiveTripOrderType[], status: ExtendedFilterType) => {
  const statusMapping: Record<
    ExtendedFilterType,
    (orders: LiveTripOrderType[]) => LiveTripOrderType[]
  > = {
    ongoing: getOngoingTrips,
    pending: getPendingTrips,
    ready: getReadyTrips,
    processing: getProcessingTrips,
    All: (orders: LiveTripOrderType[]) => orders
  };
  return statusMapping[status](orders);
};

export const NumberOfPolygonItems = (
  orders: LiveTripOrderType[],
  polygonId: string
): Record<ExtendedFilterType, number> => {
  const polygonOrders =
    polygonId === ALL_POLYGONS_ID
      ? orders
      : orders.filter((order) => order.calculated_order.cokitchen_polygon_id === polygonId);
  return {
    ongoing: getOngoingTrips(polygonOrders).length,
    pending: getPendingTrips(polygonOrders).length,
    ready: getReadyTrips(polygonOrders).length,
    processing: getProcessingTrips(polygonOrders).length,
    All: polygonOrders.length
  };
};
