import React, { useContext } from 'react';
import { BrandMealType } from '../../../utils/types';
import { ArrowDownIcon, DeleteIcon, EditAltIcon } from 'cokitchen-icons';
import Counter from '../counter';
import './styles.scss';
import { getEditedMeals, getMealTotal } from '../../../pages/create-order/functions';
import { Button } from '@cokitchen/cokitchen-components';
import { CreateOrderContext } from '../../../pages/create-order/contexts/create-order-context';
import { BasketContext } from '../../../pages/create-order/contexts/basket-context';
import OrderActionTag from '../order-action-tags';
import { formatNumber } from '../../../utils/functions';

const BasketAccordion = ({ list }: { list: BrandMealType }) => {
  const {
    handleOpenAccordion,
    onAddClick,
    onSubtractClick,
    onEditClick,
    onDeleteClick,
    open,
    onUndoClick,
    onSaveClick,
    confirmMeal
  } = useContext(BasketContext);
  const { formData, idx, mealChanged, isEdit, setIsEdit, isDelete, type } =
    useContext(CreateOrderContext);
  const iconstyles =
    'cursor-pointer transition-all duration-100 w-6 h-6 p-1 bg-gray-11 rounded-full flex items-center justify-center text-gray-8';
  const index = formData?.selectedMeal + String(idx);
  const isOpen = open && open?.includes(list?.name);
  const deletedMealsLength = formData?.deletedMeals[list.name]?.meals?.length > 0;
  return (
    <>
      {(list?.meals?.length > 0 || deletedMealsLength) && (
        <div
          className={`pb-6 text-gray-8 border-t-[10px]  px-4 border-gray-11
          }`}>
          <div className=' pt-4 flex justify-between gap-2 items-center border-b border-[#E2E7EE] pb-2'>
            <div className=' flex gap-2 items-center'>
              <h6 className='font-medium text-gray-4 text-xs'> {list?.name}</h6>
              {type === 'edit' && getEditedMeals(list?.meals) > 0 && (
                <OrderActionTag type='edit' variant='block' />
              )}
              {type === 'edit' && deletedMealsLength && (
                <OrderActionTag type='delete' variant='block' />
              )}
            </div>
            {isOpen ? (
              <ArrowDownIcon
                onClick={() => handleOpenAccordion(list?.name)}
                className={iconstyles}
              />
            ) : (
              <ArrowDownIcon
                onClick={() => handleOpenAccordion(list?.name)}
                className={`rotate-180 ${iconstyles}`}
              />
            )}
          </div>

          {isOpen && (
            <div className='pt-3 text-xs'>
              <div>
                <div className='space-y-5 pt-[3px]'>
                  {list &&
                    list.meals.map((meal, idx) => {
                      const newIndex = meal?.name + String(idx);
                      const active = isEdit && newIndex === index;
                      const deleteActive = isDelete && newIndex === index;

                      return (
                        <div
                          key={idx}
                          className={` flex gap-3 items-start text-gray-4 justify-between transition-colors duration-500`}>
                          <div className='flex gap-2 items-start w-[80%]'>
                            <Counter
                              val={meal?.quantity}
                              onAddClick={() => onAddClick(meal, idx)}
                              onSubtractClick={() => onSubtractClick(meal, idx)}
                            />
                            <div>
                              <p className='text-sm text-gray-6 -mt-[2.2px]'>
                                {meal?.name} {formatNumber(getMealTotal(meal))}
                              </p>
                              <div className='pt-2 space-y-1'>
                                {meal?.addons &&
                                  Object.values(meal?.addons).map((addon, i) => {
                                    return (
                                      <label
                                        key={i}
                                        className='flex gap-2 items-center text-gray-4'>
                                        <p>{addon.quantity}x</p>
                                        <span className='text-gray-5 text-xs'>{addon.name}</span>
                                      </label>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                          {formData?.is_group_order ? null : active &&
                            mealChanged &&
                            meal.is_confirmed ? (
                            <div className='flex flex-col gap-2 w-[150px]'>
                              <Button
                                color='primary'
                                variant='block'
                                className='basket-btn small'
                                onClick={() => onSaveClick(meal, idx)}>
                                Save changes
                              </Button>
                              <Button
                                color='primary'
                                variant='outline'
                                className='basket-btn small'
                                onClick={() => onUndoClick(meal, idx)}>
                                Undo
                              </Button>
                            </div>
                          ) : !meal.is_confirmed ? (
                            <Button
                              color='primary'
                              variant='block'
                              className='basket-btn small'
                              onClick={() => confirmMeal(meal, idx)}>
                              Confirm
                            </Button>
                          ) : (
                            meal.is_confirmed && (
                              <div>
                                <div className='flex gap-2 items-center'>
                                  <EditAltIcon
                                    onClick={() => {
                                      setIsEdit(!isEdit);
                                      onEditClick(meal, idx);
                                    }}
                                    className={`edit-icon blue ${active && 'is-active'}`}
                                  />
                                  <DeleteIcon
                                    onClick={() => onDeleteClick(meal, idx)}
                                    className={`edit-icon red ${deleteActive && 'is-active'}`}
                                  />
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default BasketAccordion;
