import { ErrorIcon, SuccessfulIcon } from 'cokitchen-icons';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import BaseToast from '../../../components/base/toast';
import { CreateOrderFormType, SelectedMealType } from '../../../utils/types';

const DeleteToast = ({
  meal,
  brandName,
  setFormData,
  idx,
  formData,
  setIsDelete
}: {
  meal: SelectedMealType;
  brandName: string;
  formData: CreateOrderFormType;
  setFormData: (item: CreateOrderFormType) => void;
  idx: number;
  setIsDelete: (item: boolean) => void;
}) => {
  const [step, setStep] = useState(0);

  const handleDelete = async () => {
    formData.selectedMeals[brandName].meals = formData?.selectedMeals[brandName].meals.filter(
      (item, idxx) => idxx != idx
    );
    if (formData.deletedMeals[brandName]) {
      formData.deletedMeals[brandName].meals.push(meal);
    } else {
      formData.deletedMeals = {
        ...formData.deletedMeals,
        [brandName]: {
          name: brandName,
          meals: [meal]
        }
      };
    }
    setFormData({ ...formData });
    setTimeout(() => setStep(1), 1000);
  };
  const undoChanges = () => {
    formData.selectedMeals[brandName].meals.push(meal);
    formData?.deletedMeals[brandName]?.meals.pop();
    setFormData({ ...formData });
  };

  return (
    <div>
      {step === 0 && (
        <BaseToast type='error' message={`Remove ${meal.name}, Confirm delete?`}>
          <div className='text-white flex gap-1'>
            <ErrorIcon onClick={() => setIsDelete(false)} />
            <SuccessfulIcon onClick={handleDelete} />
          </div>
        </BaseToast>
      )}
      {step === 1 &&
        toast(
          <BaseToast type='success' message={`${meal.name} has been removed`}>
            <div
              className='rounded-[80px] text-xs py-2 px-4 bg-white bg-opacity-20'
              onClick={undoChanges}>
              UNDO
            </div>
          </BaseToast>
        )}
    </div>
  );
};

export default DeleteToast;
