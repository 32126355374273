import React, { createContext, ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ListType } from '../../../components/base/status-tab';
import { CreateOrderProps, OrderActionType } from '../types';
import { getBrands } from '../../../services/firebase';
import { CREATE_ORDER_LIST, CreatOrderForm, ORDER_ACTION } from '../../../utils/constants';
import {
  AddonCategoryType,
  AddonMealType,
  AddressDetailsType,
  AllSelectedMealsType,
  BrandType,
  OrderType,
  SelectedMealType
} from '../../../utils/types';
import { getFormattedMeals } from '../functions';

export const CreateOrderContext = createContext({} as CreateOrderProps);
const CreatOrderContextProvider = ({ children }: { children: ReactNode }) => {
  const [active, setActive] = useState('');
  const [loading, setLoading] = useState(false);
  const [brands, setBrands] = useState([] as BrandType[]);
  const [formData, setFormData] = useState(CreatOrderForm);
  const [addressDetails, setAddressDetails] = useState({} as AddressDetailsType);
  const [addonSelection, setAddonSelection] = useState({} as AddonMealType);
  const [addonCategory, setAddonCategory] = useState([] as AddonCategoryType[]);
  const [tabList, setTabList] = useState(CREATE_ORDER_LIST as ListType[]);
  const [idx, setIdx] = useState<number | null>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [editStep, setEditStep] = useState(true);
  const [editForm, setEditForm] = useState({} as AllSelectedMealsType);
  const [tempMeal, setTempMeal] = useState(null as SelectedMealType | null);
  const [mealChanged, setMealChanged] = useState(false);
  const [mealConfirmed, setMealConfirmed] = useState(true);
  const { pathname, state: data } = useLocation();
  const [type, setType] = useState('' as OrderActionType);
  const getFormData = () => {
    const formattedData = getFormattedMeals(data);
    if (formattedData) {
      setFormData({
        ...formData,
        selectedMeals: formattedData,
        is_group_order: (data && data?.calculated_order?.is_group_order) || false
      });
    }
    setAddressDetails(getAddressDetails());
  };

  useEffect(() => {
    const formattedData = getFormattedMeals(data);
    if (formattedData) {
      setEditForm(formattedData);
    }
  }, [data]);

  const getAddressDetails = () => {
    const orderData: OrderType = data;
    const addDetails = orderData?.calculated_order?.address_details;
    const calcOrder = orderData?.calculated_order;
    const addressData: AddressDetailsType = {
      phoneNo: addDetails?.phone_number,
      address: addDetails?.address_line,
      user_address: {
        name: 'other',
        building_number: addDetails?.building_number,
        city: addDetails?.city,
        address_line: addDetails?.address_line,
        phone_number: addDetails?.phone_number
      },
      lat: calcOrder?.lat,
      lng: calcOrder?.lng,
      cokitchenId: [calcOrder?.cokitchen_id],
      cokitchen_polygon_id: calcOrder?.cokitchen_polygon_id
    };

    return addressData;
  };

  const loadBrands = async () => {
    if (!addressDetails?.cokitchenId?.[0]) return;
    setLoading(true);
    setFormData({ ...formData, brandName: '' });
    const brand: BrandType[] = await getBrands();
    const filteredBrands = brand?.filter(
      ({ cokitchen_id }) => cokitchen_id === addressDetails?.cokitchenId[0]
    );
    setBrands(filteredBrands);
    if (!editStep) setActive(CREATE_ORDER_LIST[0].name);
    setLoading(false);
  };

  const resetTabs = async () => {
    setTabList(CREATE_ORDER_LIST);
    setAddonCategory([]);
    setActive(CREATE_ORDER_LIST[0].name);
    setIdx(null);
    setIsEdit(false);
    setMealChanged(false);
    setTempMeal(null);
    setMealConfirmed(true);
    setFormData({ ...formData, selectedMeal: '', brandName: '' });
    if (brands.length === 0) await loadBrands();
  };
  const tabIsdisabled =
    Object.keys(formData.selectedMeals).length === 0 ||
    loading ||
    (!formData?.brandName && type !== 'edit');

  useEffect(() => {
    if (brands.length === 0) loadBrands();
  }, [addressDetails]);

  useEffect(() => {
    if (type === 'create') {
      setAddressDetails(data);
    } else {
      getFormData();
    }
  }, [type]);

  useEffect(() => {
    if (pathname.includes(ORDER_ACTION.CREATE)) {
      setType('create');
      setEditStep(false);
    } else {
      setType('edit');
    }
  }, [pathname]);

  useEffect(() => {
    if (type == 'edit' && active == CREATE_ORDER_LIST[0].name) setEditStep(false);
  }, [active, type]);

  return (
    <CreateOrderContext.Provider
      value={{
        setActive,
        active,
        loading,
        brands,
        setFormData,
        formData,
        setAddonSelection,
        addonSelection,
        addonCategory,
        setAddonCategory,
        tabList,
        setTabList,
        idx,
        setIdx,
        isEdit,
        setIsEdit,
        addressDetails,
        setAddressDetails,
        setLoading,
        tabIsdisabled,
        resetTabs,
        editStep,
        setEditStep,
        mealChanged,
        setMealChanged,
        setTempMeal,
        tempMeal,
        type,
        isDelete,
        setIsDelete,
        editForm,
        mealConfirmed,
        setMealConfirmed
      }}>
      {children}
    </CreateOrderContext.Provider>
  );
};

export default CreatOrderContextProvider;
