import React from 'react';
import DetailsCard from '../../../../components/base/cards/details-card';
import { AddonCategoryType, AddonMealType, AddonType } from '../../../../utils/types';

const SingleSelectionAddon = ({
  filteredAddOn,
  handleSelectAddon,
  addonSelection
}: {
  filteredAddOn: AddonCategoryType[];
  handleSelectAddon: (addon: AddonType, item: AddonCategoryType) => void;
  addonSelection: AddonMealType;
}) => {
  return (
    <div>
      {filteredAddOn[0]?.meal_category_selection_type.name === 'SINGLE_SELECTION' && (
        <div className='space-y-6'>
          {filteredAddOn[0].summary && (
            <p className='pb-4 font-medium'>* {filteredAddOn[0].summary}</p>
          )}
          <div className='grid md:grid-cols-2 lg:grid-cols-3 justify-between gap-4 gap-y-6'>
            {filteredAddOn[0]?.addons.map((addon, i) => (
              <DetailsCard
                key={i}
                title={`${addon.meal_data.name} `}
                cardType='SINGLE_SELECTION'
                price={addon.meal_data.amount}
                checked={addon?.meal_data?.id === addonSelection[filteredAddOn[0]?.id]?.id}
                onChange={() => handleSelectAddon(addon, filteredAddOn[0])}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleSelectionAddon;
