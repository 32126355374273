import React, { FC, useEffect, useState } from 'react';
import { CoKitchenType } from '../../../utils/types';
import { Dropdown, DropdownItem, DropdownList, Input } from '@cokitchen/cokitchen-components';
import { ReactComponent as ChevronDownIcon } from '../../../assets/icons/chevron-down.svg';
import { COKITCHEN_LOCATIONS, STORAGE_KEYS } from '../../../utils/constants';

type Props = {
  label?: string;
  activeKitchen: CoKitchenType;
  setActiveKitchen: (arg: CoKitchenType) => void;
};

const CokitchenSelector: FC<Props> = ({ label, activeKitchen, setActiveKitchen }) => {
  const [allCokitchens, setAllCokitchens] = useState([] as CoKitchenType[]);

  const setDefaultLocation = () => {
    const cokitchenArray: CoKitchenType[] = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.COKITCHEN_LIST) || '[]'
    );
    setAllCokitchens(cokitchenArray);
    const initKitchen =
      cokitchenArray.find(({ name }) => name === COKITCHEN_LOCATIONS.LEKKI) ?? cokitchenArray[0];
    setActiveKitchen(initKitchen);
  };

  useEffect(() => {
    setDefaultLocation();
  }, []);

  return (
    <Dropdown
      content={
        <div className='flex gap-x-2 items-center cursor-pointer'>
          {label && <label className='text-gray-8'>{label}:</label>}
          <div className='px-3 py-[6px] inline-flex items-center text-sm font-medium rounded-[20px] bg-red-1'>
            {activeKitchen.name}
            <ChevronDownIcon />
          </div>
        </div>
      }>
      <DropdownList className='max-h-[300px] min-w-[200px]'>
        {allCokitchens.map((p, i) => (
          <DropdownItem key={i} onClick={() => setActiveKitchen(p)}>
            <Input type='radio' checked={activeKitchen.id === p.id} readOnly label={p.name} />
          </DropdownItem>
        ))}
      </DropdownList>
    </Dropdown>
  );
};

export default CokitchenSelector;
