import React from 'react';
import { ROUTE_KEYS } from '../../utils/constants';
import { useNavigate } from 'react-router';
import { Login as NewLogin } from '@cokitchen/cokitchen-auth';
import { LoginResponse } from '@cokitchen/cokitchen-auth/dist/lib/login/types';
import Logo from '../../assets/logo.svg';
import { initKitchenData } from '../../utils/functions';

const Login = () => {
  const navigate = useNavigate();

  const handleLoginSuccess = async (res: LoginResponse) => {
    await initKitchenData();
    if (res) navigate(ROUTE_KEYS.PENDING_TASKS);
  };

  return (
    <div className='md:h-[100vh] p-4 sm:p-8 md:p-16 md:pt-8'>
      <div className='flex justify-between items-center bg-white'>
        <div>
          <img src={Logo} className='auth-login__logo' alt='Food court' />
        </div>
        <p className='bg-[#F2F2F2] rounded-[6px] px-5 py-3 px-auto'>Customer support</p>
      </div>
      <main className='auth-login__card mx-auto rounder-xl'>
        <NewLogin dashboard='cs' onLogin={handleLoginSuccess} title='Login' />
      </main>
    </div>
  );
};

export default Login;
