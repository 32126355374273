import React from 'react';
import { ROUTE_KEYS } from '../../utils/constants';
import { LogoutIcon, SettingsIcon } from 'cokitchen-icons';
import { useNavigate } from 'react-router-dom';
import { DropdownItem, DropdownList } from '@cokitchen/cokitchen-components';

const HeaderDropdown = () => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.clear();
    navigate(ROUTE_KEYS.LOGIN);
  };

  const list = [
    {
      name: 'settings',
      onclick: () => navigate(ROUTE_KEYS.SETTINGS),
      icon: <SettingsIcon className='w-5 h-5' />,
      iconColor: 'text-gray-4'
    },
    {
      name: 'logout',
      onclick: logout,
      icon: <LogoutIcon className='w-4 h-4' />,
      styles: 'text-red-4'
    }
  ];
  return (
    <DropdownList>
      {list.map((item, i) => (
        <DropdownItem key={i} onClick={item.onclick} className={`${item?.styles} capitalize`}>
          <span className={`pr-4 ${item?.iconColor}`}>{item?.icon}</span>
          {item?.name}
        </DropdownItem>
      ))}
    </DropdownList>
  );
};

export default HeaderDropdown;
