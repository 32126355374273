import React, { ReactNode, createContext, useState } from 'react';
import { UserProfileProps } from '../types';
import { UserType } from '../../../../utils/types';
import { ENUM_DATE_RANGE } from '../../../../utils/enum';
import { getDateRangeSelector } from '@cokitchen/cokitchen-components';

export const UserProfileContext = createContext({} as UserProfileProps);

const list = [{ name: 'Transactions' }, { name: 'orders' }, { name: 'issues' }];

const UserProfileProvider = ({ children }: { children: ReactNode }) => {
  const [active, setActive] = useState<string>(list[0].name);
  const [filters, setFilters] = useState({
    range: ENUM_DATE_RANGE.ALL,
    date: getDateRangeSelector(ENUM_DATE_RANGE.ALL)
  });
  const [user, setUser] = useState({} as UserType);

  return (
    <UserProfileContext.Provider
      value={{
        list,
        active,
        setActive,
        user,
        setUser,
        filters,
        setFilters
      }}>
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileProvider;
