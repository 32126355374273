import React, { useEffect, useRef, useState } from 'react';
import DashboardLayout from '../../layouts/dashboard.layout';
import CreateIssue from '../../components/pending-tasks/create-issue';
import { IssueType } from '../../utils/types';
import MarkAsDoneModal from '../../components/pending-tasks/mark-as-done-modal';
import {
  Button,
  DateRangeSelector,
  ENUM_DATE_RANGE,
  getDateRangeSelector
} from '@cokitchen/cokitchen-components';
import { BackIcon } from 'cokitchen-icons';
import { ExtendedFilterType } from './types';
import { apiService } from '../../services/base.service';
import IssueCard from '../../components/pending-tasks/issue-card';
import InfiniteScroll from 'react-infinite-scroll-component';

const ALL_TABS: Record<ExtendedFilterType, string> = {
  ALL: 'All',
  KITCHEN: 'Kitchen',
  DELIVERY: 'Delivery',
  REVIEW: 'Review'
};
const navStyles = {
  active: 'border-b border-b-[4px] border-b-[#E60A2B] mb-0 text-[#1A1A1A]',
  inactive: 'text-[#858585]'
};

const PendingTasks = () => {
  const isMounted = useRef(false);
  const [activeTab, setActiveTab] = useState<ExtendedFilterType>('ALL');
  const [loading, setLoading] = useState(true);
  const [showMarkModal, setShowMarkModal] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState({} as IssueType);
  const [showCompleted, setShowCompleted] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [issues, setIssues] = useState<IssueType[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [totalNumber, setTotalNumber] = useState(0);
  const [filters, setFilters] = useState(getDateRangeSelector(ENUM_DATE_RANGE.THIS_WEEK));

  const fetchCurrentTask = async (isNew?: boolean) => {
    if (isNew) setLoading(true);
    const { startDate: start_date, endDate: end_date } = filters;
    const params: any = {
      start_date,
      end_date
    };
    if (activeTab !== 'ALL') params.department = activeTab;
    const state = showCompleted ? 'completed' : 'pending';
    try {
      const page = isNew ? 1 : pageNumber;
      const res = await apiService.get(`cs/issue/${state}/page/${page}`, params);
      const newIssues = res.data.data ?? [];
      const allIssues = isNew ? newIssues : [...issues, ...newIssues];
      setIssues(allIssues);
      setTotalNumber(res.data?.total);
    } finally {
      isMounted.current = true;
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCurrentTask(true);
  }, []);

  useEffect(() => {
    if (pageNumber > 1) fetchCurrentTask();
  }, [pageNumber]);

  useEffect(() => {
    if (isMounted.current) {
      setTotalNumber(0);
      setHasMore(false);
      setPageNumber(1);
      fetchCurrentTask(true);
    }
  }, [showCompleted, activeTab, filters]);

  useEffect(() => {
    setHasMore(issues.length < totalNumber);
  }, [issues]);

  const fetchMoreData = () => {
    setPageNumber((p) => p + 1);
  };

  const openMarkModal = (issue: IssueType) => {
    setShowMarkModal(true);
    setSelectedIssue(issue);
  };

  const addPendingIssue = (issue: IssueType) => {
    if (!showCompleted) {
      setIssues([issue, ...issues]);
      setTotalNumber(totalNumber + 1);
      setRefreshKey(refreshKey + 1);
    }
  };

  const removeDoneIssue = () => {
    const newIssues = issues.filter((p) => p.id !== selectedIssue.id);
    setIssues(newIssues);
    setTotalNumber(totalNumber - 1);
    setRefreshKey(refreshKey + 1);
  };

  return (
    <DashboardLayout>
      <div className='flex justify-between mb-6'>
        <div>
          <h1 className='font-semibold text-2xl mb-2'>
            {showCompleted ? 'Completed' : 'Pending'} Tasks ({totalNumber})
          </h1>
          {showCompleted ? (
            <Button variant='link' color='gray' onClick={() => setShowCompleted(false)}>
              <BackIcon className='w-4 h-4' /> Back to Pending tasks
            </Button>
          ) : (
            <Button
              variant='link'
              color='primary'
              className='underline'
              onClick={() => setShowCompleted(true)}>
              Go to Completed Tasks
            </Button>
          )}
        </div>
        <div>
          <CreateIssue onCreateNewIssue={addPendingIssue} />
        </div>
      </div>
      <div className='flex justify-between items-center gap-4 border-b border-gray-border'>
        <ul className='flex justify-start no-scroll-bar grow'>
          {Object.entries(ALL_TABS).map(([key, value], index) => (
            <li
              role='button'
              key={index}
              onClick={() => setActiveTab(key as ExtendedFilterType)}
              className={`flex mr-6 last:mr-0 cursor-pointer py-4 whitespace-nowrap
            ${key === activeTab ? navStyles.active : navStyles.inactive}`}>
              {value}
            </li>
          ))}
        </ul>
        <DateRangeSelector onChange={(v) => setFilters(v.date)} />
      </div>
      <div key={refreshKey}>
        {loading ? (
          <div className='text-2xl leading-[40vh] text-center'>Loading....</div>
        ) : issues.length === 0 ? (
          <div className='text-2xl leading-[40vh] text-center'>
            There are no {showCompleted ? 'completed' : 'pending'} issues
            {activeTab !== 'ALL' ? ' with the selected filter' : ''}
          </div>
        ) : (
          <InfiniteScroll
            hasMore={hasMore}
            next={() => fetchMoreData()}
            dataLength={issues.length}
            loader={<p className='text-center mt-8'>Loading more issues...</p>}
            endMessage={<p className='text-center mt-8'>End of list</p>}>
            <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4 mt-6'>
              {issues.map((t) => (
                <IssueCard
                  issue={t}
                  key={t.id}
                  isAllSelected={activeTab === 'ALL'}
                  isComplete={showCompleted}
                  markIssue={() => openMarkModal(t)}
                />
              ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
      <MarkAsDoneModal
        show={showMarkModal}
        onClose={() => setShowMarkModal(false)}
        onSuccess={removeDoneIssue}
        issue={selectedIssue}
      />
    </DashboardLayout>
  );
};

export default PendingTasks;
