import { UploadIcon } from 'cokitchen-icons';
import moment from 'moment';
import React from 'react';
import { formatNumber } from '../../../utils/functions';
import { TransactionType } from '../../../utils/types';
import TransactionTag from '../transaction-tag';

const UserTransactionCard = ({ item }: { item: TransactionType }) => {
  const { amount, created_at, description, transaction_action: type } = item;
  return (
    <div className='w-full text-xs flex  justify-between text-gray-4 items-center border-t border-[#EFF0F1] py-3 '>
      <div className='w-[35%]  flex items-center gap-2'>
        <UploadIcon
          className={`bg-[#f2f2f2] w-9 h-9 p-2 flex justify-center items-center rounded-full text-gray-6 ${
            type == 'Debit' && 'rotate-180'
          }`}
        />

        <div>
          <p className='text-base font-medium text-gray-6'>{formatNumber(amount)}</p>
          <p>{moment(created_at).format('Do MMM, YYYY, hh:mm a')}</p>
        </div>
      </div>
      <div className='w-[10%] '>
        <TransactionTag type={type} />
      </div>
      <p className='w-[35%] text-left'>{description}</p>
    </div>
  );
};

export default UserTransactionCard;
