import React from 'react';
import { AddCircleIcon } from 'cokitchen-icons';

const Counter = ({
  val,
  onAddClick,
  onSubtractClick
}: {
  val: number;
  onAddClick: () => void;
  onSubtractClick: () => void;
}) => {
  return (
    <div className=' flex items-center gap-2'>
      <div
        onClick={onSubtractClick}
        className='w-4 h-4 rounded-full flex justify-center items-center border border-gray-5 text-gray-5 cursor-pointer'>
        -
      </div>
      <p className='text-gray-6 font-medium text-xs'>{val ? val : 0}</p>
      <div onClick={onAddClick} className='cursor-pointer'>
        <AddCircleIcon />
      </div>
    </div>
  );
};

export default Counter;
