import React, { FC, useContext, useState } from 'react';
import { ReactComponent as TitleIcon } from '../../../assets/icons/update-surge-modal-icon.svg';

import { toast } from 'react-toastify';
import { ToastMessage, BaseModal, Input, Button } from '@cokitchen/cokitchen-components';
import { apiService } from '../../../services/base.service';
import { UserProfileContext } from './context';
interface DebitWalletProps {
  show: boolean;
  onClose: (withRes?: boolean) => void;
}

interface DebitWalletFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  disabled: boolean;
}

const DebitWalletFooter: FC<DebitWalletFooterProps> = ({
  onCancel,
  onConfirm,
  loading,
  disabled
}) => (
  <div className='grid grid-cols-2 gap-x-4'>
    <Button small color='primary' variant='outline' className='mx-auto' onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      loading={loading}
      disabled={disabled}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const DebitWalletModal: FC<DebitWalletProps> = ({ show, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const [reason, setReason] = useState('');
  const { user } = useContext(UserProfileContext);
  const debitWallet = async () => {
    setLoading(true);
    const payload = {
      user_id: user?.id,
      reason,
      amount
    };
    try {
      await apiService.post('user/wallet/subtract', payload);
      toast(<ToastMessage type='success' message='Wallet Debited successfully' />);
      onClose(true);
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      isOpen={show}
      title='Remove Funds'
      titleIcon={<TitleIcon />}
      titleIconColor='info'
      onRequestClose={() => onClose()}
      footer={
        <DebitWalletFooter
          loading={loading}
          onCancel={() => onClose()}
          onConfirm={debitWallet}
          disabled={Number(amount) < 1 || !reason}
        />
      }>
      <div>
        <Input
          className='mb-2'
          type='number'
          label='Amount'
          onChange={(ev) => setAmount(ev.target.value)}
          value={amount}
          placeholder='Amount'
        />

        <Input
          className='mb-2'
          label='Reason'
          onChange={(ev) => setReason(ev.target.value)}
          value={reason}
          placeholder='Reason'
        />
      </div>
    </BaseModal>
  );
};

export default DebitWalletModal;
