import React, { FC, useState } from 'react';
import { UserAltIcon, MailIcon, PhoneIcon, CopyIcon } from 'cokitchen-icons';
import UserProfileSideModal from '../../modals/user-profile-modal';
import { copyTextToClipboard, formatPhoneNumber } from '../../../utils/functions';
import { ToastMessage } from '@cokitchen/cokitchen-components';
import { toast } from 'react-toastify';

type ItemType = {
  details: { rider_name: string; rider_email: string; rider_phone: string; rider_id: string };
};

const RiderDetailsBody: FC<ItemType> = ({ details }) => {
  const [showUserModal, setShowUserModal] = useState(false);
  const handleCopyClick = (text: string) => {
    copyTextToClipboard(text)
      .then(() => {
        toast(<ToastMessage type='success' message='Copied to clipboard' />);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const RIDER_DETAILS = [
    {
      name: details.rider_name,
      icon: <UserAltIcon />,
      onClick: () => setShowUserModal(true)
    },
    {
      name: details.rider_email,
      icon: <MailIcon />
    },
    {
      name: formatPhoneNumber(details.rider_phone),
      icon: <PhoneIcon />,
      withCopy: true,
      onClick: () => handleCopyClick(details?.rider_phone)
    }
  ];
  return (
    <div className='pl-6 mt-4 grow space-y-2 pt-1'>
      {RIDER_DETAILS?.map(({ name, icon, onClick, withCopy }, i) => (
        <div
          className={`flex gap-3 text-sm text-gray-5 items-center ${onClick && 'cursor-pointer'}`}
          onClick={onClick}
          key={i}>
          {icon}
          {name}
          {withCopy && (
            <span>
              <CopyIcon className='text-blue-5' />
            </span>
          )}
        </div>
      ))}
      {showUserModal && (
        <UserProfileSideModal id={details.rider_id} closeModal={() => setShowUserModal(false)} />
      )}
    </div>
  );
};

export default RiderDetailsBody;
