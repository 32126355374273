import React, { FC, useEffect, useState } from 'react';
import { UserAltIcon, MailIcon, PhoneIcon, CopyIcon } from 'cokitchen-icons';
import { copyTextToClipboard, formatPhoneNumber } from '../../../utils/functions';
import { OrderType } from '../../../utils/types';
import { apiService } from '../../../services/base.service';
import UserProfileSideModal from '../../modals/user-profile-modal';
import { toast } from 'react-toastify';
import { ToastMessage } from '@cokitchen/cokitchen-components';
type Props = {
  order: OrderType;
};

const PersonalDetailsBody: FC<Props> = ({ order }) => {
  const [showUserModal, setShowUserModal] = useState(false);

  const handleCopyClick = (text: string) => {
    copyTextToClipboard(text)
      .then(() => {
        toast(<ToastMessage type='success' message='Copied to clipboard' />);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const USER_DETAILS = [
    {
      name: `${order.user.first_name} ${order.user.last_name}`,
      icon: <UserAltIcon />,
      onClick: () => setShowUserModal(true)
    },
    {
      name: order.user.email,
      icon: <MailIcon />
    },
    {
      name: formatPhoneNumber(order.user.phone_number),
      icon: <PhoneIcon />,
      withCopy: true,
      onClick: () => handleCopyClick(order?.user?.phone_number)
    }
  ];

  const getReviews = async () => {
    await apiService.get(`cs/order/${order?.id}/reviews`, null);
  };

  useEffect(() => {
    if (order?.id) getReviews();
  }, [order]);

  return (
    <div className='mt-4 grow space-y-2 pt-1'>
      {USER_DETAILS?.map(({ name, icon, onClick, withCopy }, i) => (
        <div
          key={i}
          className={`${onClick && 'cursor-pointer'} flex justify-between items-center px-6`}>
          <div className=' flex gap-3 text-sm text-gray-5 items-center'>
            <span>{icon}</span>
            <span onClick={onClick} className=' flex items-center gap-2'>
              {name}
              {withCopy && (
                <span>
                  <CopyIcon className='text-blue-5' />
                </span>
              )}
            </span>
          </div>
        </div>
      ))}
      {showUserModal && (
        <UserProfileSideModal id={order?.user_id} closeModal={() => setShowUserModal(false)} />
      )}
    </div>
  );
};

export default PersonalDetailsBody;
