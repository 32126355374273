import React, { FC } from 'react';
import { OrderDetailsType } from '../../../utils/types';
import Accordion from '../../base/accordion';

type MealAccordionType = {
  user_name: string;
  meals: OrderDetailsType[];
};

type ItemType = {
  isGroupOrder: boolean;
  fullName: string;
  orderDetails: OrderDetailsType[];
  subGroupOrder: MealAccordionType[];
  open: string[];
  // setOpen: (item: string[]) => void;
  handleOpenAccordion: (item: string) => void;
};

const OrderDetailsBody: FC<ItemType> = ({
  isGroupOrder,
  fullName,
  orderDetails,
  open,
  handleOpenAccordion,
  subGroupOrder
}) => {
  return (
    <div className='order-body'>
      <div>
        {isGroupOrder && (
          <span className='pl-5 t-2 text-sm'>
            {'' + 1 + '.'} Host - {fullName}
          </span>
        )}
      </div>

      {orderDetails.map((item, idx) => {
        const index = String(idx);
        return (
          <div
            key={idx}
            className={` ${orderDetails.length - 1 === idx ? '' : 'border-b border-gray-border '}`}>
            <Accordion
              open={open.includes(index)}
              handleOpenAccordion={() => handleOpenAccordion(index)}
              list={item}
            />
          </div>
        );
      })}

      {subGroupOrder &&
        subGroupOrder.map((item, idx) => (
          <>
            <span className='pl-5 text-sm'>
              {idx + 2 + '.'} {item?.user_name}
            </span>
            {item?.meals.map((itm, i) => {
              const index = String(i + item?.user_name);
              return (
                <div
                  key={index}
                  className={` ${
                    itm?.meal.length - 1 === i ? '' : 'border-b border-gray-border '
                  }`}>
                  <Accordion
                    open={open.includes(index)}
                    handleOpenAccordion={() => handleOpenAccordion(index)}
                    list={itm}
                  />
                </div>
              );
            })}
          </>
        ))}
    </div>
  );
};

export default OrderDetailsBody;
