import React from 'react';
import NoRiderYetImage from '../../../assets/images/no-rider-yet.png';

const NoOrderYet = () => {
  return (
    <div className='center'>
      <div>
        <img src={NoRiderYetImage} alt='pickup order' width={180} height={180} />
        <div className='text-center'>No rider yet</div>
      </div>
    </div>
  );
};

export default NoOrderYet;
