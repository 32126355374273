import React, { useContext, useEffect } from 'react';
import BackButton from '../../../components/base/back-button';
import Loader from '../../../components/base/loader';
import { ListType } from '../../../components/base/status-tab';
import { CREATE_ORDER_LIST } from '../../../utils/constants';
import { getEmoji } from '../../../utils/functions';
import { AddonCategoryType } from '../../../utils/types';
import { checkMealChanged, updateAddons } from '../functions';
import AddOns from './addon-type';
import Brands from './brands';
import CreateOrderTab from './create-order-tab';
import MealCategories from './meal-categories';
import { CreateOrderContext } from '../contexts/create-order-context';

const LeftSection = () => {
  const {
    setActive,
    active,
    loading,
    formData,
    setFormData,
    addonSelection,
    addonCategory,
    setTabList,
    idx,
    isEdit,
    editStep,
    type,
    tempMeal,
    setMealChanged
  } = useContext(CreateOrderContext);
  const getTabList = (item: AddonCategoryType[]) => {
    const list: ListType[] = [];
    const sortedItem = item.sort((a, b) => a.position - b.position);
    for (let i = 0; i < sortedItem.length; i++) {
      list.push({ name: sortedItem[i]?.name, emoji: getEmoji(sortedItem[i]?.name) });
    }
    if (isEdit) {
      setTabList([...list]);
    } else {
      setTabList([...CREATE_ORDER_LIST, ...list]);
    }
    setActive(list[0]?.name);
  };

  useEffect(() => {
    if (addonCategory.length !== 0) getTabList(addonCategory);
  }, [addonCategory]);

  useEffect(() => {
    if (formData?.selectedMeals[formData?.brandName]?.meals?.length > 0) {
      updateAddons(formData, addonSelection, idx);
    }
  }, [addonSelection]);
  useEffect(() => {
    if (tempMeal !== null && idx !== null) {
      setMealChanged(checkMealChanged(type, tempMeal, formData, idx) || false);
    }
  }, [formData]);

  useEffect(() => {
    setFormData({ ...formData });
  }, [addonSelection]);
  return (
    <div className='col-span-2'>
      <BackButton />
      <p className='font-medium text-xl pb-8'>{type === 'create' ? 'Create' : 'Edit'} Order</p>
      <div className='w-full no-scroll-bar gap-3'>
        <CreateOrderTab />
      </div>

      <div className='pt-10'>
        {loading ? (
          <Loader />
        ) : type == 'edit' && editStep ? (
          <div className='grid place-content-center text-sm text-gray-4 font-medium h-[50vh]'>
            {formData?.is_group_order
              ? "You can't edit group orders at this time, check back later"
              : 'Tap on the edit/delete icon to alter order items'}
          </div>
        ) : active === CREATE_ORDER_LIST[0].name ? (
          <Brands />
        ) : active === CREATE_ORDER_LIST[1].name ? (
          <MealCategories />
        ) : (
          addonCategory.length > 0 && <AddOns />
        )}
      </div>
    </div>
  );
};

export default LeftSection;
