import { AddCircleIcon } from 'cokitchen-icons';
import React from 'react';

const SettingsTitleBar = ({
  title,
  withAdd,
  onAddClick
}: {
  title: string;
  withAdd?: boolean;
  onAddClick?: () => void;
}) => {
  return (
    <div className='w-full rounded-[4px] px-3 py-2 flex justify-between items-center text-xs  bg-gray-11 '>
      <p>{title}</p>

      {withAdd && (
        <div
          className=' flex items-center justify-center gap-1 text-red-5 cursor-pointer'
          onClick={onAddClick}>
          <AddCircleIcon />
          Add Number
        </div>
      )}
    </div>
  );
};

export default SettingsTitleBar;
