import React, { FC, ReactNode } from 'react';
import Header from '../components/header';

type Props = {
  children: ReactNode;
  className?: string;
  headerClassName?: string;
};

const DashboardLayout: FC<Props> = ({ children, className = '', headerClassName = '' }) => {
  return (
    <div className='min-h-screen flex flex-col'>
      <div className={headerClassName}>
        <Header />
      </div>
      <main className='grow flex flex-col'>
        <div className={`grow container px-4 md:px-8 mx-auto py-8 md:pt-10 ${className}`}>
          {children}
        </div>
      </main>
    </div>
  );
};
export default DashboardLayout;
