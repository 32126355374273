import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownList,
  Input,
  InputWithSymbol,
  SideModal,
  ToastMessage
} from '@cokitchen/cokitchen-components';
import React, { useEffect, useState } from 'react';
import { ReactComponent as AddIcon } from '../../../assets/icons/big-add.svg';
import { ArrowDownIcon } from 'cokitchen-icons';
import { ExistingCSProfileType, PhoneType } from '../../../utils/types';
import { apiService } from '../../../services/base.service';
import { toast } from 'react-toastify';
import { STORAGE_KEYS } from '../../../utils/constants';

type ActionType = 'create' | 'edit';

const AddContactFooter = ({
  handleConfirm,
  disabled,
  loading
}: {
  handleConfirm: () => void;
  disabled: boolean;
  loading: boolean;
}) => {
  return (
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      onClick={handleConfirm}
      loading={loading}
      disabled={disabled}>
      Confirm
    </Button>
  );
};

const PHONE_LIST: PhoneType[] = ['call', 'whatsapp'];
const AddContactModal = ({
  show,
  onClose,
  phoneNoData
}: {
  show: boolean;
  onClose: (withRes?: boolean) => void;
  phoneNoData: ExistingCSProfileType;
}) => {
  const EMPTY_FORM = {
    phone_number: '',
    is_online: false,
    is_whatsapp_reachable: false,
    is_call_reachable: false
  };

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(EMPTY_FORM);
  const [phoneType, setPhoneType] = useState(['call'] as PhoneType[]);
  const [type, setType] = useState<ActionType>('create');

  const toggleWhatsappLine = () => {
    if (phoneType.includes('whatsapp')) {
      formData.is_whatsapp_reachable = true;
    } else {
      formData.is_whatsapp_reachable = false;
    }
    setFormData({ ...formData });
  };

  const toggleCallLine = () => {
    if (phoneType.includes('call')) {
      formData.is_call_reachable = true;
    } else {
      formData.is_call_reachable = false;
    }
    setFormData({ ...formData });
  };

  const changedIsReachable = () => {
    return (
      formData?.is_call_reachable != phoneNoData?.is_call_reachable ||
      formData?.is_whatsapp_reachable != phoneNoData?.is_whatsapp_reachable
    );
  };

  const changedPhoneNo = () => {
    return phoneNoData?.phone_number !== formData?.phone_number;
  };

  const isDisabled = () => {
    if (type == 'edit') {
      return !(changedPhoneNo() || changedIsReachable());
    } else {
      return !formData?.phone_number || !phoneType;
    }
  };

  const createPhoneNo = async () => {
    const payload = {
      phone_numbers: [{ ...formData }]
    };
    try {
      const res = await apiService.post('cs/staff/phone-number', { ...payload });
      if (res)
        toast(
          <ToastMessage
            type='success'
            message={type == 'edit' ? 'Phone Updated successfully' : 'Phone Added successfully'}
          />
        );
      return res;
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    }
  };

  const updatePhoneNo = async () => {
    const user_id = localStorage.getItem(STORAGE_KEYS?.USER_ID);
    const payload = {
      user_id,
      old_number: phoneNoData?.phone_number,
      new_number: formData?.phone_number
    };
    try {
      const res = await apiService.post('cs/staff/change-number', payload);
      if (res) toast(<ToastMessage type='success' message='Phone Updated successfully' />);
      return res;
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    }
  };

  const editPhoneNo = async () => {
    let res;
    if (changedPhoneNo() && changedIsReachable()) {
      const [createRes, updateRes] = await Promise.all([updatePhoneNo(), createPhoneNo()]);
      if (createRes && updateRes) onClose(true);
    } else if (changedIsReachable()) {
      res = await createPhoneNo();
      if (res) onClose(true);
    } else if (changedPhoneNo()) {
      res = await updatePhoneNo();
      if (res) onClose(true);
    }
  };

  const handleConfirm = async () => {
    setLoading(true);
    if (type == 'create') {
      const res = await createPhoneNo();
      if (res) onClose(true);
    } else {
      await editPhoneNo();
    }
    setLoading(false);
  };

  const handlePhoneType = (item: PhoneType) => {
    if (phoneType.includes(item)) {
      if (phoneType.length === 1) return;
      const arr = phoneType.filter((type) => item != type);
      setPhoneType(arr);
    } else {
      setPhoneType([...phoneType, item]);
    }
  };

  const getPhoneType = () => {
    const { is_whatsapp_reachable, is_call_reachable } = phoneNoData;
    const arr: PhoneType[] = [];
    if (is_whatsapp_reachable) arr.push('whatsapp');
    if (is_call_reachable) arr.push('call');

    setPhoneType(arr);
  };

  const formatFormData = () => {
    if (phoneNoData) {
      setType('edit');
      getPhoneType();

      const { phone_number, is_online, is_call_reachable, is_whatsapp_reachable } = phoneNoData;

      setFormData({
        ...formData,
        phone_number,
        is_online,
        is_call_reachable,
        is_whatsapp_reachable
      });
    } else {
      setFormData({
        ...formData
      } as ExistingCSProfileType);
    }
  };
  useEffect(() => {
    toggleWhatsappLine();
    toggleCallLine();
  }, [phoneType]);

  useEffect(() => {
    formatFormData();
  }, [phoneNoData]);

  useEffect(() => {
    setType('create');
  }, []);

  return (
    <SideModal
      onRequestClose={() => {
        onClose();
        setFormData(EMPTY_FORM);
      }}
      title={`${type == 'edit' ? 'Edit' : 'Add'} Contact No`}
      bgColor='dark-blue'
      icon={<AddIcon />}
      isOpen={show}
      footer={
        <AddContactFooter
          loading={loading}
          disabled={isDisabled()}
          handleConfirm={() => handleConfirm()}
        />
      }>
      <div className='relative z-0'>
        <InputWithSymbol
          name='mobile_no'
          label='Mobile no.'
          placeholder='+234 0124 422 333'
          small
          onChange={(e) => setFormData({ ...formData, phone_number: e.target.value })}
          value={formData?.phone_number}
          symbol='flag'
        />
        <div className='absolute top-[32px] right-0 z-50'>
          <Dropdown
            placement='bottom-end'
            content={
              <div className='p-3'>
                <button className='text-red-5 bg-red-9 flex items-center py-2 px-3 rounded-[100px] text-[10px]'>
                  {phoneType
                    .sort((a, b) => a?.localeCompare(b))
                    ?.map((item, i) => (
                      <span key={i} className='capitalize'>
                        {item}
                        {phoneType.length - 1 != i ? ', ' : ''}
                      </span>
                    ))}
                  <ArrowDownIcon />
                </button>
              </div>
            }>
            <DropdownList>
              {PHONE_LIST.map((item, i) => (
                <DropdownItem
                  className='capitalize flex items-center'
                  key={i}
                  onClick={() => handlePhoneType(item)}>
                  <Input type='checkbox' readOnly checked={phoneType.includes(item)} />
                  <p className='pl-3'>{item}</p>
                </DropdownItem>
              ))}
            </DropdownList>
          </Dropdown>
        </div>
      </div>
    </SideModal>
  );
};

export default AddContactModal;
