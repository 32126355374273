import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserProfileSideModal from '../../../components/modals/user-profile-modal';
import { apiService } from '../../../services/base.service';
import { UserType } from '../../../utils/types';
import Loader from '../../../components/base/loader';
import NoSearchResult from '../no-search-result';
import { Table, TableData, TableRow } from '@cokitchen/cokitchen-components';

const TABLE_HEADERS = [
  'Name',
  'Date Joined',
  'Phone number',
  'Email',
  'Wema Acct(Bani)',
  'Providus Acct(Bani)',
  'Free Deliveries',
  ''
];

const UserSearchResult = () => {
  const [loading, setLoading] = useState(false);
  const [pageloading, setPageLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [users, setUsers] = useState([] as UserType[]);

  const [id, setId] = useState('');
  const [showUserModal, setShowUserModal] = useState(false);

  const { filterType: filter_type, filterValue: filter_value } = useParams();
  const navigate = useNavigate();
  if (!filter_value || !filter_type) navigate(-1);
  const getUsers = async (value = 1) => {
    setLoading(true);
    const filter = { filter_type, filter_value };
    try {
      const res = await apiService.post(`cs/search/keyword/${value}`, filter);
      const { total, data } = res.data;
      setUsers(data);
      setPageCount(total);
    } finally {
      setLoading(false);
    }
  };

  const changePage = (val: { selected: number }) => {
    const pageNumber = val?.selected + 1;
    getUsers(pageNumber);
  };

  const loadData = async () => {
    setPageLoading(true);
    await getUsers();
    setPageLoading(false);
  };

  const viewProfile = (item: UserType) => {
    setId(item?.id);
    setShowUserModal(true);
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      {pageloading ? (
        <Loader />
      ) : users?.length === 0 ? (
        <NoSearchResult />
      ) : (
        <Table
          headers={TABLE_HEADERS}
          loading={loading}
          pageCount={Math.ceil(pageCount / 10)}
          onPageChange={changePage}>
          {users?.map((item, index) => (
            <TableRow key={index}>
              <TableData className='text-left py-6 capitalize'>
                {item?.first_name} {item?.last_name}
              </TableData>
              <TableData className='text-left text-[#858585]'>
                <span>{moment(item.created_at).format('Do MMM, YYYY')}</span>
              </TableData>
              <TableData>{item?.phone_number}</TableData>
              <TableData>{item?.email}</TableData>
              <TableData>
                {item?.bani_account_number || (
                  <div className='flex justify-center bg-[#F0F0F0] border border-1 border-[#DEDEDE] text-[#858585] w-[100px] py-1 rounded-sm'>
                    Not available
                  </div>
                )}
              </TableData>
              <TableData>
                {item?.bani_account_number_providus || (
                  <div className='flex justify-center bg-[#F0F0F0] border border-1 border-[#DEDEDE] text-[#858585] w-[100px] py-1 rounded-sm'>
                    Not available
                  </div>
                )}
              </TableData>
              <TableData className='text-center text-green-5'>{item?.free_deliveries}</TableData>
              <TableData>
                <button className='underline text-[#E60A2B]' onClick={() => viewProfile(item)}>
                  View Profile
                </button>
              </TableData>
            </TableRow>
          ))}
        </Table>
      )}
      {showUserModal && <UserProfileSideModal id={id} closeModal={() => setShowUserModal(false)} />}
    </div>
  );
};

export default UserSearchResult;
