export enum ENUM_SEARCH_KEY {
  FULL_NAME = 'full_name',
  EMAIL = 'email',
  NAME = 'name',
  ORDER_CODE = 'order_code',
  PHONE_NUMBER = 'phone_number'
}

export enum ENUM_PENDING_SOURCE {
  KITCHEN = 'KITCHEN',
  REVIEW = 'REVIEW',
  DELIVERY = 'DELIVERY'
}

export enum ENUM_STATUS {
  ONGOING = 'ongoing',
  PROCESSING = 'processing',
  PENDING = 'pending',
  READY = 'ready',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled'
}

export enum ENUM_DATE_RANGE {
  TODAY = 'Today',
  THIS_WEEK = 'This week',
  YESTERDAY = 'Yesterday',
  THIS_MONTH = 'This month',
  LAST_MONTH = 'Last month',
  ALL = 'All Time',
  SELECT = 'Select Date'
}

export enum ENUM_BANK_NAMES {
  ACCESS = 'Access Bank',
  GT = 'GT Bank',
  WEMA = 'Wema Bank',
  PROVIDUS = 'Providus Bank',
  HERITAGE = 'Heritage Bank',
  FCMB = 'FCMB',
  '9PSB' = '9 Payment Service Bank',
  STANBIC = 'Stanbic Bank'
}
export enum ENUM_ITEM_TYPES {
  FOOD = 'FOOD',
  SHOP = 'SHOP',
  ALL = 'ALL'
}

export enum LIVE_TRIP_ENUM_STATUS {
  ONGOING = 'ongoing',
  PROCESSING = 'processing',
  PENDING = 'pending',
  READY = 'ready'
}

export enum ENUM_CS_ROLES {
  FRONT_DESK = 'FRONT_DESK',
  CS = 'CUSTOMER_SERVICE',
  ADMIN = 'ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
  CS_ADMIN = 'CUSTOMER_SERVICE_ADMIN',
  CS_LEAD = 'CUSTOMER_SERVICE_LEAD',
  CUSTOMER_SERVICE_INBOUND = 'CUSTOMER_SERVICE_INBOUND',
  CUSTOMER_SERVICE_OUTBOUND = 'CUSTOMER_SERVICE_OUTBOUND'
}
