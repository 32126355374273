import React, { FC, Fragment } from 'react';
import { OrderDetailsType } from '../../../utils/types';
import { ArrowFilledUpIcon } from 'cokitchen-icons';
type AccordionType = {
  list: OrderDetailsType;
  open: boolean | null;
  handleOpenAccordion: () => void;
};

const Accordion: FC<AccordionType> = ({ open, handleOpenAccordion, list }) => {
  return (
    <>
      <div className='pb-6'>
        <div className='px-6 pt-4 flex justify-between gap-2 items-center'>
          <h6 className='font-medium text-gray-6 text-sm'> {list?.brand} </h6>
          {open ? (
            <ArrowFilledUpIcon
              onClick={handleOpenAccordion}
              className=' cursor-pointer transition-all duration-100'
            />
          ) : (
            <ArrowFilledUpIcon
              onClick={handleOpenAccordion}
              className='rotate-180 cursor-pointer transition-all duration-100'
            />
          )}
        </div>

        {open && (
          <div className='pt-3 text-xs px-6 space-y-4'>
            {list &&
              list.meal.map((itm, idx) => {
                return (
                  <Fragment key={idx}>
                    <div key={idx} className='flex gap-2 items-start'>
                      <p className='text-[9px] text-red-5 border border-gray-border rounded-full w-[22px] h-[22px] flex justify-center items-center'>
                        x{itm.quantity}
                      </p>
                      <div>
                        <p className='pt-[3px]'>{itm.meal}</p>
                        <div className='pt-2'>
                          {itm.addons.map((itms, i) => {
                            return (
                              <label key={i} className='flex gap-2 items-center'>
                                <p className='text-red-4'>{itm.quantity}x</p>
                                <span className='text-gray-5'>{itms.name}</span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    {itm.order_note && (
                      <div className='flex gap-1'>
                        <p className='font-medium text-gray-6 '>Note: </p>
                        <p className='font-medium text-gray-5 '> {itm.order_note}</p>
                      </div>
                    )}
                  </Fragment>
                );
              })}
          </div>
        )}
      </div>
    </>
  );
};

export default Accordion;
