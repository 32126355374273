import React from 'react';
import { ReactComponent as CancelTripIcon } from '../../../assets/icons/cancel-trip.svg';
import { ReactComponent as CompleteTripIcon } from '../../../assets/icons/complete-trip.svg';
import { ReactComponent as ChangeAddressIcon } from '../../../assets/icons/change-address.svg';
import { ReactComponent as DispatchOrderIcon } from '../../../assets/icons/dispatch-order.svg';
import { ListObjectType } from '../../base/dropdown-list/types';
import { getAdmin } from '../../../utils/functions';
export type LiveCardMenuFunctionType =
  | 'completeTrip'
  | 'cancelTrip'
  | 'changeAddress'
  | 'confirmPayment';

export const LIVE_CARD_LIST: Record<string, ListObjectType[]> = {
  ongoing: [
    {
      name: 'Complete trip',
      icon: <CompleteTripIcon />,
      function: 'completeTrip',
      className: 'text-[#46AE31]'
    },
    {
      name: 'Cancel trip',
      icon: <CancelTripIcon />,
      function: 'cancelTrip',
      className: 'text-[#E60A2B]'
    },

    {
      name: 'Change Address',
      icon: <ChangeAddressIcon />,
      function: 'changeAddress'
    }
  ],
  pending: [
    {
      name: 'Cancel trip',
      icon: <CancelTripIcon />,
      function: 'cancelTrip',
      className: 'text-[#E60A2B]'
    },
    {
      name: 'Confirm Payment',
      icon: <DispatchOrderIcon />,
      function: 'confirmPayment',
      className: !getAdmin() ? 'hidden' : ''
    },
    {
      name: 'Change Address',
      icon: <ChangeAddressIcon />,
      function: 'changeAddress'
    }
  ],
  processing: [
    {
      name: 'Change Address',
      icon: <ChangeAddressIcon />,
      function: 'changeAddress'
    }
  ],
  ready: [
    {
      name: 'Complete trip',
      icon: <CompleteTripIcon />,
      function: 'completeTrip',
      className: 'text-[#46AE31]'
    },
    {
      name: 'Cancel trip',
      icon: <CancelTripIcon />,
      function: 'cancelTrip',
      className: 'text-[#E60A2B]'
    },
    {
      name: 'Change Address',
      icon: <ChangeAddressIcon />,
      function: 'changeAddress'
    }
  ]
};
