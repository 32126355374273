import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASECONFIG_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASECONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASECONFIG_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASECONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASECONFIG_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASECONFIG_APP_ID
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const getBrands = async () => {
  const brandsArr: any = [];
  const colRef = collection(db, 'brands');
  const docsSnap = await getDocs(colRef);
  docsSnap.forEach((doc) => {
    brandsArr.push(doc.data());
  });
  return brandsArr;
};
