import React, { useEffect, useState } from 'react';
import { formatNumber } from '../../../utils/functions';
import { OrderDetailsType, OrderType } from '../../../utils/types';
import OrderDetailsBody from './order-details-body';
import OrderDetailsHeader from './order-details-header';
import './styles.scss';

type Props = {
  order: OrderType;
};

type MealAccordionType = {
  user_name: string;
  meals: OrderDetailsType[];
};

export const OrderDetails = ({ order }: Props) => {
  const [showAll, setShowAll] = useState(false);
  const calcOrder = order?.calculated_order;

  const numberOfBrands = calcOrder?.brand_ids?.length;
  const numberOfMeals = calcOrder?.meals.reduce((mealsAcc, meal) => {
    const innerMeals = meal?.meals?.length;
    mealsAcc += innerMeals;
    return mealsAcc;
  }, 0);

  const [open, setOpen] = useState<string[]>(['0']);
  const handleOpenAccordion = (name: string) => {
    if (open?.includes(name)) {
      const filteredIdx = open?.filter((brandName) => brandName !== name);
      setOpen(filteredIdx);
    } else {
      setOpen([...open, name]);
    }
  };

  const idxArr: string[] = [];

  const brands = order.calculated_order.meals;
  const subBrands = order.calculated_order.sub_calculated_order_ids;
  const orderDetails = brands.map((brand, idx) => {
    idxArr.push(String(idx));
    return {
      brand: brand.brand.name,
      amount: brand.amount,
      quantity: 1,
      meal: brand.meals.map((meal) => {
        return {
          meal: meal.name,
          amount: meal.amount,
          quantity: meal.quantity,
          order_note: meal.order_note,
          addons: meal.addons.map((addon) => {
            return {
              name: addon.meal_data.name,
              quantity: addon?.quantity
            };
          })
        };
      })
    };
  });

  const subGroupOrder: MealAccordionType[] = [];
  subBrands.forEach((item) => {
    const name = item?.user?.first_name + ' ' + item?.user?.last_name;

    const arr = {
      user_name: name,
      meals: item.meals.map((brand, i) => {
        idxArr.push(String(i + name));
        return {
          brand: brand.brand.name,
          amount: brand.amount,
          quantity: 1,
          meal: brand.meals.map((meal) => {
            return {
              meal: meal.name,
              amount: meal.amount,
              quantity: meal.quantity,
              order_note: meal.order_note,
              addons: meal.addons.map((addon) => {
                return {
                  name: addon.meal_data.name,
                  quantity: addon?.quantity
                };
              })
            };
          })
        };
      })
    };
    subGroupOrder.push(arr);
  });

  const showAllMeals = () => {
    setShowAll(!showAll);
  };
  const isGroupOrder = order?.calculated_order?.is_group_order;
  const fullName =
    order?.calculated_order?.user?.first_name + ' ' + order?.calculated_order?.user?.last_name;

  useEffect(() => {
    if (showAll) {
      setOpen(idxArr);
    } else {
      setOpen([]);
    }
  }, [showAll]);

  return (
    <div className='flex flex-col overflow-hidden max-h-[300px] rounded-[8px] border border-gray-border pt-6 pb-1'>
      <OrderDetailsHeader
        totalOrderAmount={formatNumber(calcOrder?.total_amount)}
        totalNoOfItems={numberOfMeals}
        totalNoOfBrands={numberOfBrands}
        note={order?.cancelled_note}
      />
      <OrderDetailsBody
        isGroupOrder={isGroupOrder ?? false}
        fullName={fullName}
        orderDetails={orderDetails}
        open={open}
        handleOpenAccordion={handleOpenAccordion}
        subGroupOrder={subGroupOrder}
      />

      <div className='flex gap-4 px-3 py-1 mt-auto'>
        <button onClick={showAllMeals} className='order-info'>
          {showAll ? 'Collapse All' : 'See All'}
        </button>
        {/* {orderInProgress && (
          <button onClick={handleClick} className='order-info'>
            Edit
          </button>
        )} */}
      </div>
    </div>
  );
};

export default OrderDetails;
