import React, { useContext, useState } from 'react';
import { AddCircleIcon, ArrowDownIcon, NoteIcon } from 'cokitchen-icons';
import AddressModal from './address-modal';
import PaystackModal from './paystack-modal';
import { UserProfileContext } from './context';
import { Dropdown, DropdownItem, DropdownList } from '@cokitchen/cokitchen-components';

const ActionDropdown = () => {
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showRefModal, setShowRefModal] = useState(false);

  const createOrder = () => {
    setShowAddressModal(!showAddressModal);
  };
  const paystackRef = () => {
    setShowRefModal(!showRefModal);
  };

  const ACTIONS_ARRAY = [
    {
      name: 'create order',
      icon: <AddCircleIcon />,
      onClick: createOrder
    },
    {
      name: 'create paysack ref',
      icon: <NoteIcon />,
      onClick: paystackRef
    }
  ];
  const { user } = useContext(UserProfileContext);

  return (
    <div>
      <Dropdown
        placement='bottom-start'
        content={
          <span className='flex cursor-pointer items-center gap-1 border border-gray-9 bg-gray-11 w-[90px] text-sm text-gray-6 justify-center py-1 rounded-[20px] font-medium hover:bg-gray-12 transition-all duration-500'>
            Actions
            <span>
              <ArrowDownIcon />
            </span>
          </span>
        }>
        <DropdownList>
          {ACTIONS_ARRAY.map(({ name, icon, onClick }, i) => (
            <DropdownItem key={i} onClick={onClick} className='flex items-center'>
              {icon}
              <span className='text-sm text-gray-6 capitalize ml-2'>{name}</span>
            </DropdownItem>
          ))}
        </DropdownList>
      </Dropdown>
      <AddressModal
        phoneNo={user?.phone_number}
        id={user?.id}
        onClose={() => setShowAddressModal(!showAddressModal)}
        show={showAddressModal}
      />

      <PaystackModal show={showRefModal} onClose={paystackRef} id={user?.id} />
    </div>
  );
};

export default ActionDropdown;
