import React, { useEffect, useState } from 'react';
import { CoKitchenPolygons, CoKitchenType } from '../../../utils/types';
import { ALL_POLYGONS_ID, STORAGE_KEYS } from '../../../utils/constants';
import Slider, { Settings } from 'react-slick';

type TabProps = {
  activePolygon: CoKitchenPolygons;
  setActivePolygon: (item: any) => void;
  activeKitchen?: CoKitchenType;
  dottedPolygons?: string[];
  defaultPolygon?: CoKitchenPolygons;
  className?: string;
  smallText?: boolean;
  hasAll?: boolean;
  hideHr?: boolean;
  isModalView?: boolean;
};
export const PolygonsTab: React.FC<TabProps> = ({
  activePolygon,
  dottedPolygons,
  setActivePolygon,
  activeKitchen,
  defaultPolygon,
  className = '',
  smallText,
  hasAll,
  hideHr,
  isModalView
}) => {
  const [finalList, setFinalList] = useState([] as CoKitchenPolygons[]);
  const [activeLocation, setActiveLocation] = useState({} as CoKitchenType);
  const [polygons, setPolygons] = useState([] as CoKitchenPolygons[]);
  const cokitchenArray: CoKitchenType[] = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.COKITCHEN_LIST) ?? '[]'
  );

  const cokitchenId = localStorage.getItem(STORAGE_KEYS.COKITCHEN);

  const setDefaultLocation = () => {
    const activeCokitchen =
      activeKitchen ?? cokitchenArray.find(({ id }) => id === cokitchenId) ?? cokitchenArray[0];
    setActiveLocation(activeCokitchen);
  };

  const updateList = () => {
    const sortedList = polygons?.sort((a, b) => b?.name.localeCompare(a?.name)) ?? [];
    let list = [...sortedList];
    if (hasAll) {
      const allItem = { name: 'All', id: ALL_POLYGONS_ID } as CoKitchenPolygons;
      list = [allItem, ...sortedList];
    }
    setFinalList(list);
    if (list.length > 1) setActivePolygon?.(defaultPolygon ?? list[0]);
  };

  useEffect(() => {
    setDefaultLocation();
  }, [activeKitchen]);

  useEffect(() => {
    if (activeLocation) setPolygons(activeLocation.cokitchen_polygons);
  }, [activeLocation]);

  useEffect(() => {
    updateList();
  }, [hasAll, polygons]);

  const sliderSettings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    variableWidth: true,
    slidesToShow: isModalView ? 4 : 6,
    slidesToScroll: 2,
    centerMode: false,
    swipe: true,
    draggable: true,
    prevArrow: (
      <svg
        width='12'
        height='12'
        viewBox='0 0 12 12'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6 0.625C8.965 0.625 11.375 3.035 11.375 6C11.375 8.965 8.965 11.375 6 11.375C3.035 11.375 0.625 8.965 0.625 6C0.625 3.035 3.035 0.625 6 0.625ZM6 10.625C8.55 10.625 10.625 8.55 10.625 6C10.625 3.45 8.55 1.375 6 1.375C3.45 1.375 1.375 3.45 1.375 6C1.375 8.55 3.45 10.625 6 10.625Z'
          fill='#858585'
        />
        <path
          d='M6.63 3.86C6.725 3.86 6.82 3.895 6.895 3.97C7.04 4.115 7.04 4.355 6.895 4.5L5.395 6L6.895 7.5C7.04 7.645 7.04 7.885 6.895 8.03C6.75 8.175 6.51 8.175 6.365 8.03L4.6 6.265C4.455 6.12 4.455 5.88 4.6 5.735L6.365 3.97C6.44 3.895 6.535 3.86 6.63 3.86Z'
          fill='#858585'
        />
      </svg>
    ),
    nextArrow: (
      <svg
        width='12'
        height='13'
        viewBox='0 0 12 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6 11.875C3.035 11.875 0.625 9.465 0.625 6.5C0.625 3.535 3.035 1.125 6 1.125C8.965 1.125 11.375 3.535 11.375 6.5C11.375 9.465 8.965 11.875 6 11.875ZM6 1.875C3.45 1.875 1.375 3.95 1.375 6.5C1.375 9.05 3.45 11.125 6 11.125C8.55 11.125 10.625 9.05 10.625 6.5C10.625 3.95 8.55 1.875 6 1.875Z'
          fill='#858585'
        />
        <path
          d='M5.37 8.63996C5.275 8.63996 5.18 8.60496 5.105 8.52996C4.96 8.38496 4.96 8.14496 5.105 7.99996L6.605 6.49996L5.105 4.99996C4.96 4.85496 4.96 4.61496 5.105 4.46996C5.25 4.32496 5.49 4.32496 5.635 4.46996L7.4 6.23496C7.545 6.37996 7.545 6.61996 7.4 6.76496L5.635 8.52996C5.56 8.60496 5.465 8.63996 5.37 8.63996Z'
          fill='#858585'
        />
      </svg>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: isModalView ? 3 : 5,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: isModalView ? 3 : 4,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: isModalView ? 2 : 3,
          slidesToScroll: 2
        }
      }
    ]
  };

  const navStyles = {
    active: 'border-b-[#E60A2B] mb-0 text-[#1A1A1A]',
    inactive: 'border-transparent text-[#858585]'
  };

  return (
    <div className={className}>
      <Slider {...sliderSettings}>
        {finalList.map((i) => (
          <div
            role='button'
            key={i.name}
            onClick={() => setActivePolygon(i)}
            className={`border-b-4 flex items-center gap-1 cursor-pointer py-4 whitespace-nowrap ${
              smallText ? 'text-sm' : 'text-base'
            } ${i.name === activePolygon.name ? navStyles.active : navStyles.inactive}`}>
            {i.name}
            {dottedPolygons?.includes(i.name) && (
              <span className='ml-1 inline-block w-2 h-2 rounded-full bg-red-5' />
            )}
          </div>
        ))}
      </Slider>
      {!hideHr && <hr className='border-gray-border' />}
    </div>
  );
};
