import React from 'react';

export type BankType = 'bani' | 'manual';
const BankTag = ({ type }: { type: BankType }) => {
  const styles = {
    bani: 'text-blue-5 bg-blue-1 border-blue-5',
    manual: 'text-green-5 bg-green-1 border-green-5'
  };
  return (
    <div
      className={`w-fit font-medium px-3 py-1 border text-2xs rounded-[2px] ${
        type === 'bani' ? styles.bani : styles.manual
      }`}>
      {type === 'bani' ? 'Bani' : 'Manual'}
    </div>
  );
};

export default BankTag;
