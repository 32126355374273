import React, { useContext } from 'react';
import StatusTabs from '../../../base/status-tab';
import { UserProfileContext } from '../context';
import UserIssues from './user-issues';
import UserOrders from './user-orders';
import UserTransactions from './user-transactions';

const UserFooter = () => {
  const { list, active, setActive, user } = useContext(UserProfileContext);

  return (
    <div className='pt-8'>
      <StatusTabs list={list} active={active} setActive={setActive} />
      {active === list[0].name && <UserTransactions user={user} />}
      {active === list[1].name && <UserOrders user={user} />}
      {active === list[2].name && <UserIssues user={user} />}
    </div>
  );
};

export default UserFooter;
