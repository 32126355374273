import React, { FC, useEffect, useState } from 'react';
import OrderStatus from '../../base/order-status';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';
import { LiveTripOrderType } from '../../../utils/types';
import { getLiveTripOrderStatus } from '../../../utils/functions';
import { LIVE_CARD_LIST, LiveCardMenuFunctionType } from './menu-config';
import BaseDropdownList from '../../base/dropdown-list';
import { ListObjectType } from '../../base/dropdown-list/types';
import { Dropdown } from '@cokitchen/cokitchen-components';

type LiveTripCardType = {
  order: LiveTripOrderType;
  onSelectMenu: (option: LiveCardMenuFunctionType) => void;
};

const LiveTripCardHeader: FC<LiveTripCardType> = ({ order, onSelectMenu }) => {
  const type = getLiveTripOrderStatus(order);

  const sendOrderDetails = (item: ListObjectType) => {
    if (item.function) onSelectMenu(item.function);
  };

  const [list, setList] = useState<ListObjectType[]>([]);
  useEffect(() => {
    const list = LIVE_CARD_LIST[type] as ListObjectType[];
    setList(list);
  }, [type]);

  return (
    <div className='px-4 flex justify-between items-center'>
      <OrderStatus type={type} />
      {type !== 'completed' && (
        <Dropdown
          content={
            <MenuIcon className='bg-[#FEF0F2] w-7 h-7 p-[7px] rounded-full cursor-pointer transition-all duration-200 hover:bg-red-3' />
          }>
          <BaseDropdownList order={order} list={list} onSelect={sendOrderDetails} />
        </Dropdown>
      )}
    </div>
  );
};

export default LiveTripCardHeader;
