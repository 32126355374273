import React from 'react';

const OrderNotFound = () => {
  return (
    <h2 className='font-bold text-center md:h-[200px] flex justify-center items-center mx-auto text-lg md:text-2xl'>
      Not Found
    </h2>
  );
};

export default OrderNotFound;
