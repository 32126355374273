import React from 'react';
import DashboardLayout from '../../layouts/dashboard.layout';
import { useNavigate, useParams } from 'react-router-dom';
import { ENUM_SEARCH_KEY } from '../../utils/enum';
import OrderSearchResult from './order-search-result';
import UserSearchResult from './user-search-result';
import BackButton from '../../components/base/back-button';
import { ROUTE_KEYS } from '../../utils/constants';

const SearchResult = () => {
  const { filterType, filterValue } = useParams();
  const navigate = useNavigate();
  if (!filterType) navigate(-1);
  return (
    <DashboardLayout
      className={`${filterType !== ENUM_SEARCH_KEY?.ORDER_CODE && 'no-scroll-bar'} space-y-6`}>
      <div>
        <p className='font-medium text-xl pb-1'>
          Showing search results for <span className='uppercase'>{filterValue}</span>
        </p>
        <BackButton text='Back to search' link={ROUTE_KEYS.SEARCH} />
      </div>
      {filterType === ENUM_SEARCH_KEY.ORDER_CODE ? <OrderSearchResult /> : <UserSearchResult />}
    </DashboardLayout>
  );
};

export default SearchResult;
