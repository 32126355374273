import React, { FC, useState } from 'react';
import { IssueType } from '../../../utils/types';
import { Button, BaseModal, ToastMessage } from '@cokitchen/cokitchen-components';
import { UserIcon } from 'cokitchen-icons';
import { apiService } from '../../../services/base.service';
import { toast } from 'react-toastify';

interface FinishOrderModalProps {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  issue: IssueType;
}

interface FinishOrderFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const MarkAsDoneFooter: FC<FinishOrderFooterProps> = ({ onCancel, onConfirm, loading }) => (
  <div className='grid grid-cols-2 gap-x-4'>
    <Button small color='primary' variant='outline' className='mx-auto' onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      loading={loading}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const MarkAsDoneModal: FC<FinishOrderModalProps> = ({ show, onClose, onSuccess, issue }) => {
  const [loading, setLoading] = useState(false);
  const markAsDone = async () => {
    setLoading(true);
    try {
      const res = await apiService.patch(`cs/issue/done/${issue.id}`, null);
      toast(<ToastMessage type='success' message={res.data.message} />);
      onSuccess();
      onClose();
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    } finally {
      setLoading(false);
    }
  };

  const checkState = () => {
    markAsDone();
  };
  return (
    <BaseModal
      isOpen={show}
      title='Mark as done'
      description={`Are you sure you want to mark ${issue.user?.first_name}'s issue as resolved`}
      titleIcon={<UserIcon />}
      titleIconColor='info'
      onRequestClose={onClose}
      footer={<MarkAsDoneFooter loading={loading} onCancel={onClose} onConfirm={checkState} />}
    />
  );
};

export default MarkAsDoneModal;
