import React, { FC } from 'react';
import { ArrowDownCircleIcon } from 'cokitchen-icons';
import { numberWithCommas } from '../../../utils/functions';

type StatsType = 'increase' | 'decrease' | 'online';

export type BaseStatsType = {
  title: string;
  value: string;
  withNaira?: boolean;
  time?: string;
  type?: StatsType;
  weeklyVal?: string | number;
  small?: boolean;
  activeDateRange?: string;
};
const BaseStats: FC<BaseStatsType> = ({
  title,
  value,
  withNaira = false,
  time,
  type,
  weeklyVal = '',
  small,
  activeDateRange
}) => {
  return (
    <div className={`flex flex-col items-start ${small ? 'gap-1' : 'gap:3'}`}>
      <label className={`${small ? 'text-[10px]' : 'text-xs'} uppercase text-gray-8`}>
        {title}
      </label>
      <label
        className={`${
          small ? 'font-medium text-base' : 'font-semibold text-xl md:text-3xl'
        }  text-gray-6 `}>
        {withNaira && '₦ '} {numberWithCommas(value)} {time && ` ${time}`}
      </label>
      {type === 'increase' ? (
        <label className={`text-green-5 text-sm flex items-center gap-1 ${small && 'text-[10px]'}`}>
          <ArrowDownCircleIcon className='rotate-180' />
          <span className='font-medium'>
            {withNaira && '₦ '} {numberWithCommas(weeklyVal)}
          </span>
          <span className=' text-gray-8 lowercase'> {activeDateRange} </span>
        </label>
      ) : type === 'decrease' ? (
        <label className={`text-red-5 text-sm flex items-center gap-1 ${small && 'text-[10px]'}`}>
          <ArrowDownCircleIcon />
          <span className='font-medium'>
            {withNaira && '₦ '} {numberWithCommas(weeklyVal)}
          </span>
          <span className=' text-gray-8 lowercase'> {activeDateRange} </span>
        </label>
      ) : (
        type === 'online' && (
          <label
            className={`text-green-5 text-sm flex items-center gap-1 ${small && 'text-[10px]'}`}>
            <p className='w-2 h-2 rounded-full bg-green-5'></p>
            {weeklyVal ? (
              <>
                <span className='font-medium'>{numberWithCommas(weeklyVal)}</span>
                <span> online </span>
              </>
            ) : (
              <span> Live </span>
            )}
          </label>
        )
      )}
    </div>
  );
};

export default BaseStats;
