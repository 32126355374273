import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ResetPassword as NewResetPassword } from '@cokitchen/cokitchen-auth';
import Logo from '../../assets/logo.svg';

const ResetPassword = () => {
  const { token }: any = useParams();
  const navigate = useNavigate();
  const login = () => {
    navigate('/login');
  };
  return (
    <div className='auth-login__main min-h-screen bg-white px-0  md:px-16 pt-8'>
      <div className='flex justify-between items-center bg-white'>
        <div>
          <img src={Logo} className='auth-login__logo' alt='Food court' />
        </div>
        <p className='bg-[#F2F2F2] rounded-[6px] px-5 py-3 px-auto'>Customer support</p>
      </div>
      <main className='auth-login__card mt-[5rem]'>
        <NewResetPassword
          token={token}
          onRouteToLogin={login}
          description='Enter and confirm a new password'
        />
      </main>
    </div>
  );
};

export default ResetPassword;
