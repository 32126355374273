import React, { FC, useState } from 'react';
import { ReactComponent as TitleIcon } from '../../../assets/icons/update-surge-modal-icon.svg';

import { toast } from 'react-toastify';
import { ToastMessage, BaseModal, Button } from '@cokitchen/cokitchen-components';
import { apiService } from '../../../services/base.service';
import { BankType } from '../banks';
import { BANK_NAMES } from '../../../utils/constants';

interface ToggleStatusProps {
  show: boolean;
  onClose: (noBankSelected?: boolean, withRes?: boolean) => void;
  bank: BankType;
}

interface ToggleStatusFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ToggleStatusFooter: FC<ToggleStatusFooterProps> = ({ onCancel, onConfirm, loading }) => (
  <div className='grid grid-cols-2 gap-x-4'>
    <Button small color='primary' variant='outline' className='mx-auto' onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      loading={loading}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const ToggleStatusModal: FC<ToggleStatusProps> = ({ show, onClose, bank }) => {
  const [loading, setLoading] = useState(false);

  const activeText = () => {
    if (bank?.active) {
      return 'On';
    } else {
      return 'Off';
    }
  };

  const updateStatus = async () => {
    setLoading(true);
    let payload;
    let res;
    try {
      if (bank?.fullname !== BANK_NAMES?.ACCESS_BANK) {
        payload = {
          bank_name: bank.name,
          is_on: bank.active
        };
        res = await apiService.post('cs/bani/toggle', payload);
      } else {
        payload = {
          is_on: bank.active
        };
        res = await apiService.post('cs/manual-access/toggle', payload);
      }

      if (res) {
        toast(
          <ToastMessage
            type='success'
            message={`${bank?.fullname} Turned ${activeText()} successfully`}
          />
        );
        onClose(true, true);
      }
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    } finally {
      setLoading(false);
    }
  };

  const getStatus = () => {
    if (bank?.active) {
      return 'on';
    } else {
      return 'off';
    }
  };

  return (
    <BaseModal
      isOpen={show}
      title={`Turn ${getStatus()} Bank`}
      description={`Are you sure you want to turn ${getStatus()} ${
        bank?.fullname
      } on the mobile app `}
      titleIcon={<TitleIcon />}
      titleIconColor='info'
      onRequestClose={() => onClose(true)}
      footer={
        <ToggleStatusFooter
          loading={loading}
          onCancel={() => onClose(true)}
          onConfirm={updateStatus}
        />
      }
    />
  );
};

export default ToggleStatusModal;
