import { BackIcon, BrandsIcon } from 'cokitchen-icons';
import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button } from '@cokitchen/cokitchen-components';
import Loader from '../../components/base/loader';
import OrderStatus from '../../components/base/order-status';
import OrderNotFound from '../../components/order-details-content/order-not-found';
import PersonalDetails from '../../components/order-details-content/personal-details';
import RiderDetails from '../../components/order-details-content/rider-details';
import OrderInfo from '../../components/order-details-content/order-details';
import DashboardLayout from '../../layouts/dashboard.layout';
import { apiService } from '../../services/base.service';
import { getOrderStatus } from '../../utils/functions';
import { LiveTripOrderType, OrderType } from '../../utils/types';
import DeliveryDetail from '../../components/order-details-content/delivery-details';
import TrackOrder from '../../components/order-details-content/track-order';
import { SocketIOConnection } from '../../utils/socket_client';
import CancelTripModal from '../../components/modals/cancel-trip-modal';
import ReactToPrint from 'react-to-print';
import OrderReceiptProvider from '../../components/order-receipt';
import ReceiptContent from '../../components/order-receipt/receipt-content';
import CompleteTripModal from '../../components/modals/complete-trip-modal';
import { STORAGE_KEYS } from '../../utils/constants';
import { ENUM_CS_ROLES } from '../../utils/enum';
const getOrderApi = (order_code: string) => apiService.get('orders/code/' + order_code, {});

type Props = {
  liveTripOrderCode?: string;
  headerClass?: string;
};
const OrderDetails = ({ liveTripOrderCode, headerClass }: Props) => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);

  const [notFound, setNotFound] = useState(false);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState<OrderType | undefined>(undefined);
  const [showChangeAddressModal, setShowChangeAddressModal] = useState(false);

  const receiptRef = useRef<HTMLInputElement>(null);
  const params = useParams();
  const order_code = params.order_code ?? liveTripOrderCode;

  if (!order_code) return <OrderNotFound />;
  const navigate = useNavigate();

  const getOrder = async () => {
    try {
      const res = await getOrderApi(order_code);
      const order_data = res.data.order;
      if (res.status != 200 || order_data.id == undefined) {
        setLoading(false);
        setNotFound(true);
      }
      setOrder(res.data.order);

      connectSocket(res.data.order.id);
    } finally {
      setLoading(false);
    }
  };

  const addressModal = (withRes?: boolean) => {
    if (withRes) getOrder();
    setShowChangeAddressModal(!showChangeAddressModal);
  };
  const connectSocket = async (order_id: string) => {
    const conn = await SocketIOConnection.getConnection();
    conn.onReconnect(async () => {
      conn.joinRoom(roomName, process.env.REACT_APP_WEBOCKETAPP_KEY);
      const res = await getOrderApi(order_code);
      setOrder(res.data.order);
    });
    const roomName = `order:${order_id}`;
    conn.addEventListener(roomName, 'updated', (data: any) => {
      setOrder((curValue) => ({ ...curValue, ...data }));
    });
    conn.joinRoom(roomName, process.env.REACT_APP_WEBOCKETAPP_KEY);
  };

  const orderInProgress = !(order?.completed || order?.cancelled);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const getCsAdmin = () => {
    return role === ENUM_CS_ROLES.CS_ADMIN;
  };

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <DashboardLayout headerClassName={headerClass}>
      {loading ? (
        <Loader />
      ) : notFound || !order ? (
        <OrderNotFound />
      ) : (
        <Fragment>
          <div className='flex items-center'>
            <div className=' grow flex items-center'>
              <Link
                to='..'
                onClick={(e) => {
                  e.preventDefault();
                  navigate(-1);
                }}>
                <BackIcon className='text-gray-8 w-5 h-5' />
              </Link>
              <div className='font-semibold text-2xl mx-2'>
                Order #{order.order_code?.toUpperCase()}
              </div>
              <OrderStatus type={getOrderStatus(order)} />
            </div>
            <ReactToPrint
              trigger={() => (
                <button className='flex items-center gap-1 border-b font-medium text-sm text-gray-5'>
                  <BrandsIcon />
                  Print docket
                </button>
              )}
              content={() => receiptRef.current}
            />

            <div className='receipt py-4' ref={receiptRef}>
              <OrderReceiptProvider order={order}>
                <ReceiptContent />
              </OrderReceiptProvider>
            </div>
          </div>
          <div className='mt-8 gap-x-8'>
            <div className='grid min-h-[250px] lg:grid-cols-3 gap-4 mb-4'>
              <RiderDetails order={order} />
              <PersonalDetails order={order} />
              <OrderInfo order={order} />
            </div>
            <div className='flex gap-4 flex-col lg:flex-row'>
              {!order?.calculated_order?.pickup && (
                <DeliveryDetail
                  order={order}
                  addressModal={addressModal}
                  show={showChangeAddressModal}
                />
              )}
              <TrackOrder order={order} />
            </div>
            {orderInProgress && (
              <div className='flex mt-12 justify-center gap-x-4'>
                <Button
                  onClick={() => setShowCancelModal(true)}
                  small
                  color='primary'
                  variant='outline'>
                  Cancel trip
                </Button>
              </div>
            )}
          </div>
          {showCancelModal && (
            <CancelTripModal
              order={order as LiveTripOrderType}
              onClose={() => {
                setShowCancelModal(false);
              }}
            />
          )}
          {getCsAdmin() && showCompleteModal && (
            <CompleteTripModal
              order={order as LiveTripOrderType}
              onClose={() => {
                setShowCompleteModal(false);
              }}
            />
          )}
        </Fragment>
      )}
    </DashboardLayout>
  );
};

export default OrderDetails;
