import React from 'react';
import UserProfileProvider from './context';

import UserProfileContent from './user-profile-content';

type UserProfileModalProps = {
  closeModal: () => void;
  id: string;
};

const UserProfileSideModal = ({ closeModal, id }: UserProfileModalProps) => {
  return (
    <UserProfileProvider>
      <UserProfileContent closeModal={closeModal} id={id} />
    </UserProfileProvider>
  );
};

export default UserProfileSideModal;
