import React from 'react';
import { ROUTE_KEYS } from '../utils/constants';
import AuthRoute from './AuthRoute';
import UnauthRoute from './UnauthRoute';
import Login from '../pages/auth/login';
import PendingTasks from '../pages/pending-tasks';
import Search from '../pages/search';
import Reviews from '../pages/reviews';
import OrderDetails from '../pages/order-details';
import CreateOrder from '../pages/create-order';
import Settings from '../pages/settings';
import ResetPassword from '../pages/auth/reset_password';
import SearchResult from '../pages/search/search-result';
import LiveTrips from '../pages/live-trips';

type RouteType = {
  element: JSX.Element;
  path: string;
};

const ALL_ROUTES: RouteType[] = [
  {
    path: ROUTE_KEYS.LOGIN,
    element: (
      <UnauthRoute>
        <Login />
      </UnauthRoute>
    )
  },
  {
    path: ROUTE_KEYS.RESET_PASSWORD,
    element: (
      <UnauthRoute>
        <ResetPassword />
      </UnauthRoute>
    )
  },
  {
    path: ROUTE_KEYS.PENDING_TASKS,
    element: (
      <AuthRoute>
        <PendingTasks />
      </AuthRoute>
    )
  },
  {
    path: ROUTE_KEYS.REVIEWS,
    element: (
      <AuthRoute>
        <Reviews />
      </AuthRoute>
    )
  },
  {
    path: ROUTE_KEYS.SEARCH,
    element: (
      <AuthRoute>
        <Search />
      </AuthRoute>
    )
  },
  {
    path: ROUTE_KEYS.SEARCH_RESULT,
    element: (
      <AuthRoute>
        <SearchResult />
      </AuthRoute>
    )
  },
  {
    path: ROUTE_KEYS.ORDER_DETAILS,
    element: (
      <AuthRoute>
        <OrderDetails />
      </AuthRoute>
    )
  },
  {
    path: ROUTE_KEYS.NEW_ORDER,
    element: (
      <AuthRoute>
        <CreateOrder />
      </AuthRoute>
    )
  },
  {
    path: ROUTE_KEYS.SETTINGS,
    element: (
      <AuthRoute>
        <Settings />
      </AuthRoute>
    )
  },
  {
    path: ROUTE_KEYS.LIVE_TRIPS,
    element: (
      <AuthRoute>
        <LiveTrips />
      </AuthRoute>
    )
  }
];

export default ALL_ROUTES;
