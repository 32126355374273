import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackIcon } from 'cokitchen-icons';

type BackButtonColor = 'red' | 'gray';

const BackButton = ({
  link,
  text = 'Back',
  color = 'gray',
  onClick
}: {
  link?: string;
  text?: string;
  color?: BackButtonColor;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();
  const goBack = () => {
    if (link) {
      navigate(link);
    } else if (onClick) {
      onClick?.();
    } else {
      navigate(-1);
    }
  };
  return (
    <div
      className={`cursor-pointer flex gap-2 items-center text-sm font-medium mb-3 ${
        color === 'gray' ? 'text-gray-8' : color === 'red' && 'text-red-5'
      }`}
      onClick={goBack}>
      <BackIcon stroke='none' className='w-4 h-4' />
      <span>{text}</span>
    </div>
  );
};

export default BackButton;
