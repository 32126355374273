import React from 'react';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock.svg';
import { getSimpleDateFormat } from '../../../utils/functions';
import { LiveTripOrderType } from '../../../utils/types';
import { formatTime } from '../../../utils/functions';

type Props = {
  order: LiveTripOrderType;
  onClick: () => void;
};

const LiveTripCardFooter = ({ order, onClick }: Props) => {
  return (
    <div className='border-t border-gray-border text-xs p-4 flex justify-between items-center'>
      <div className='flex items-center gap-2'>
        <ClockIcon />
        <div className='flex items-center gap-2'>
          {getSimpleDateFormat(order.created_at)}
          <div className='bg-gray-2 min-w-[8px] w-2 h-2 rounded-full'></div>
          {formatTime(order.created_at)}
        </div>
      </div>
      <p className='underline text-red-4' onClick={onClick}>
        View details
      </p>
    </div>
  );
};

export default LiveTripCardFooter;
