import React from 'react';
import { EditAltIcon, DeleteIcon } from 'cokitchen-icons';
import './styles.scss';
import { formatNumber } from '../../../utils/functions';
export type ActionType = 'remove' | 'add' | 'edit' | 'delete';
type VariantType = 'outline' | 'block';

const OrderActionTag = ({
  type,
  variant,
  val = '0'
}: {
  type: ActionType;
  variant: VariantType;
  val?: string | number;
}) => {
  return (
    <div
      className={`action-tag text-lg ${variant === 'block' ? 'block-tag' : 'outline-tag'} ${
        type === 'add' || type === 'edit' ? 'add' : 'remove'
      }`}>
      {variant === 'block' ? (
        type === 'delete' ? (
          <DeleteIcon />
        ) : (
          type === 'edit' && <EditAltIcon />
        )
      ) : variant === 'outline' && type === 'remove' ? (
        '-'
      ) : (
        type === 'add' && '+'
      )}

      <p>
        {variant === 'block'
          ? type === 'delete'
            ? 'deleted'
            : type === 'edit' && 'edited'
          : variant === 'outline' && formatNumber(val)}
      </p>
    </div>
  );
};

export default OrderActionTag;
