import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { ToastMessage, BaseModal, Button } from '@cokitchen/cokitchen-components';
import { ExistingCSProfileType } from '../../../utils/types';
import { apiService } from '../../../services/base.service';
import { DeleteIcon } from 'cokitchen-icons';
interface DeleteNumberProps {
  show: boolean;
  onClose: (fetchPhoneNo?: true) => void;
  phoneNo: ExistingCSProfileType;
}

interface DeleteNumberFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const DeleteNumberFooter: FC<DeleteNumberFooterProps> = ({ onCancel, onConfirm, loading }) => (
  <div className='grid grid-cols-2 gap-x-4'>
    <Button small color='primary' variant='outline' className='mx-auto' onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      loading={loading}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const DeleteNumberModal: FC<DeleteNumberProps> = ({ show, onClose, phoneNo }) => {
  const [loading, setLoading] = useState(false);

  const confirmDeleteNumber = async () => {
    setLoading(true);
    try {
      const res = await apiService.delete(`cs/staff/delete-phone-number/${phoneNo?.id}`);
      if (res?.data) {
        toast(<ToastMessage type='success' message='Phone Number Deleted successfully' />);
        onClose(true);
      }
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      isOpen={show}
      title='Delete Phone Number'
      description={`Are you sure you want delete ${phoneNo?.phone_number}`}
      titleIcon={<DeleteIcon className='text-red-5' />}
      titleIconColor='danger'
      onRequestClose={() => onClose()}
      footer={
        <DeleteNumberFooter
          loading={loading}
          onCancel={() => onClose()}
          onConfirm={confirmDeleteNumber}
        />
      }
    />
  );
};

export default DeleteNumberModal;
