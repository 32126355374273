import React, { useContext } from 'react';
import StatusTabs from '../../../components/base/status-tab';
import { CreateOrderContext } from '../contexts/create-order-context';

const CreateOrderTab = () => {
  const { setActive, active, tabIsdisabled, tabList } = useContext(CreateOrderContext);
  return (
    <div className='flex gap-2'>
      <StatusTabs
        list={tabList}
        type='outline'
        active={active}
        setActive={setActive}
        disabled={tabIsdisabled}
      />
    </div>
  );
};

export default CreateOrderTab;
