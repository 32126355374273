import { Button, Input, ToastMessage } from '@cokitchen/cokitchen-components';
import React, { useEffect, useState } from 'react';
import { apiService } from '../../../../services/base.service';
import { toast } from 'react-toastify';
import StatusTabs, { ListType } from '../../../base/status-tab';
import { AddressDataType } from '.';
import { CardIcon, CheckFilledIcon } from 'cokitchen-icons';
import CardWithHeader from '../../../base/cards/card-with-header';
import { noBaniAccountNo } from '../../../../utils/functions';
import { UserType } from '../../../../utils/types';

type DebitWalletProps = {
  user_id: string;
  setDebitWallet: (item: boolean) => void;
  setChangeAddrBtn: (item: boolean) => void;
  loading: boolean;
  setLoading: (item: boolean) => void;
  user: UserType;
  data: AddressDataType;
};
const DebitWallet = ({
  user_id,
  loading,
  setLoading,
  setDebitWallet,
  setChangeAddrBtn,
  user,
  data
}: DebitWalletProps) => {
  const [amount, setAmount] = useState('');
  const [paymentType, setPaymentType] = useState('' as string);
  const [cardArr, setCardArr] = useState([]);
  const [currCard, setCurrCard] = useState('');
  const [step, setStep] = useState(0);
  const getCards = async () => {
    const res = await apiService.get(`cs/user/one/${user_id}/cards`, {});
    const response = res?.data?.data;
    const arr = response?.map((card: any) => ({
      id: card?.id,
      title: `*** ${card.last_four_digit}`,
      icon: <CardIcon />
    }));
    setCardArr(arr);
  };
  const debitWallet = async () => {
    setLoading(true);
    const payload = {
      user_id,
      amount: amount,
      reason: 'Remove Delivery Fee'
    };
    try {
      const res = await apiService.post('user/wallet/subtract', payload);
      if (res) {
        toast(<ToastMessage type='success' message='Wallet Debited Successfully' />);
        setDebitWallet(false);
        setChangeAddrBtn(true);
      }
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err?.message} />);
    } finally {
      setLoading(false);
    }
  };

  const PAYMENT_ARR: ListType[] = [
    {
      name: 'wallet',
      className: data.added_or_deducted_fee > Number(user?.balance) ? 'hidden' : ''
    },
    { name: 'card' },
    { name: 'credit' },
    { name: 'transfer' }
  ];

  const cardPayment = async () => {
    const res = await apiService.post(`logistics/super-admin/order/charge-card`, {
      card_id: currCard,
      amount: String(data.added_or_deducted_fee)
    });
    return res;
  };

  const creditPayment = async () => {
    const payload = {
      owe: String(data.added_or_deducted_fee),
      reason: 'Changed Delivery Address',
      user_id
    };
    const res = await apiService.post(`admin/user/owe`, payload);
    return res;
  };

  const handlePayment = async () => {
    // create order
    let res;
    if (paymentType === PAYMENT_ARR[1].name) {
      res = await cardPayment();
    } else if (paymentType === PAYMENT_ARR[2].name) {
      res = await creditPayment();
    } else if (paymentType === PAYMENT_ARR[0].name) {
      res = await debitWallet();
    }

    if (res?.data) {
      setStep(1);
    }
  };
  const disabledBtn = () => {
    return (
      loading ||
      !paymentType ||
      (paymentType == PAYMENT_ARR[1].name && currCard === '') ||
      (paymentType == PAYMENT_ARR[0].name && (!amount || Number(amount) == 0))
    );
  };

  useEffect(() => {
    getCards();
  }, []);

  useEffect(() => {
    if (paymentType === PAYMENT_ARR[3].name) {
      setStep(1);
    } else {
      setStep(0);
    }
  }, [paymentType]);

  useEffect(() => {
    if (step == 1) {
      setChangeAddrBtn(true);
    } else {
      setChangeAddrBtn(false);
    }
  }, [step]);

  return (
    <div className='mt-6 space-y-5'>
      <StatusTabs active={paymentType} setActive={setPaymentType} list={PAYMENT_ARR} />

      {paymentType === PAYMENT_ARR[0].name && (
        <>
          <Input
            small
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            placeholder={`Enter amount greater than 0`}
          />

          <p className='font-semibold mt-2 text-green-5'>Wallet Balance: {user?.balance}</p>
        </>
      )}
      {paymentType === PAYMENT_ARR[1].name && (
        <div>
          {cardArr?.map(({ id, title, icon }, i) => (
            <div
              className={`flex p-3 items-center cursor-pointer justify-between ${
                currCard === id && 'bg-gray-11 '
              }`}
              key={i}
              onClick={() => {
                setCurrCard(id);
              }}>
              <div className='w-7 h-7 p-1 flex justify-center items-center rounded-[100%] border border-gray-12'>
                {icon}
              </div>
              <label className='w-[80%] text-gray-6 font-medium text-sm'>
                {title}
                <label></label>
              </label>
              <CheckFilledIcon />
            </div>
          ))}
        </div>
      )}
      {paymentType === PAYMENT_ARR[3].name && (
        <div className='mt-5 h-[110px] overflow-scroll no-scroll-bar'>
          <CardWithHeader title='bani account number'>
            {noBaniAccountNo(user) && '-'}
            {user?.bani_account_number && <p>Wema - {user?.bani_account_number}</p>}
            {user?.bani_account_number_gt && <p>Gtbank - {user?.bani_account_number_gt}</p>}
            {user?.bani_account_number_heritage && (
              <p>Heritage - {user?.bani_account_number_heritage}</p>
            )}
            {user?.bani_account_number_fcmb && <p>FCMB - {user?.bani_account_number_fcmb}</p>}
            {user?.bani_account_number_providus && (
              <p>Providus - {user?.bani_account_number_providus}</p>
            )}
            {user?.bani_account_number_9ps && <p>9Mobile Bank - {user?.bani_account_number_9ps}</p>}
            {user?.bani_account_number_stanbic && (
              <p>Stanbic - {user?.bani_account_number_stanbic}</p>
            )}
          </CardWithHeader>
        </div>
      )}
      {step === 0 && (
        <div className=' flex gap-2 h-10'>
          <Button
            color='primary'
            variant='outline'
            className='h-[100%]'
            small
            onClick={handlePayment}
            loading={loading}
            disabled={disabledBtn()}>
            Debit User
          </Button>
        </div>
      )}
    </div>
  );
};

export default DebitWallet;
