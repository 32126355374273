import React from 'react';
import { ClockIcon } from 'cokitchen-icons';
import { IssueType } from '../../../utils/types';
import moment from 'moment';

const UserIssueCard = ({ item }: { item: Partial<IssueType> }) => {
  const firstTextToUpper = () => {
    if (item?.issue_text) {
      const text = item?.issue_text?.charAt(0).toUpperCase() + item?.issue_text?.slice(1);

      return text;
    }
  };
  return (
    <div className='border py-3 border-[#E4E6E7] text-xs rounded-lg min-w-[360px]'>
      <p className='px-4 text-gray-6 pb-3 text-left'>{firstTextToUpper()}</p>
      <hr className='text-[#E4E6E7] pt-3' />
      <div className='flex text-gray-5 px-4 items-center gap-2'>
        <ClockIcon />
        <p>{moment(item.created_at).format('MMM DD, YYYY')}</p>
      </div>
    </div>
  );
};

export default UserIssueCard;
