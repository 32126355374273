import React, { useState } from 'react';
import { Input, Button } from '@cokitchen/cokitchen-components';
import { useNavigate } from 'react-router-dom';
import { ENUM_SEARCH_KEY } from '../../utils/enum';
import './styles.scss';
import DashboardLayout from '../../layouts/dashboard.layout';
const Search = () => {
  const navigate = useNavigate();
  const [filterValue, setFilterValue] = useState('' as ENUM_SEARCH_KEY);
  const [searchValue, setSearchValue] = useState<string>('');

  const options = [
    { label: 'Full name', value: ENUM_SEARCH_KEY.FULL_NAME },
    { label: 'Email', value: ENUM_SEARCH_KEY.EMAIL },
    { label: 'Username', value: ENUM_SEARCH_KEY.NAME },
    { label: 'Order Number', value: ENUM_SEARCH_KEY.ORDER_CODE },
    { label: 'Phone Number', value: ENUM_SEARCH_KEY.PHONE_NUMBER }
  ];

  const searchFunc = () => {
    navigate(`/search-result/${filterValue}/${searchValue}`);
  };

  return (
    <DashboardLayout className='search grid place-content-center'>
      <div className='w-[90%] mx-auto md:max-w-[800px] space-y-4'>
        <div className='w-full flex items-center relative mx-auto'>
          <Input
            type='search'
            placeholder='Type in anything'
            className='search-input'
            small
            onChange={(e: any) => setSearchValue(e.target.value)}
            name='searchValue'
            value={searchValue}
          />
          <Button
            variant='block'
            color='primary'
            small
            className='search-btn'
            onClick={searchFunc}
            disabled={!filterValue || !searchValue}>
            Search
          </Button>
        </div>

        <p className='text-xs text-gray-5 text-left flex justify-start'>
          Filter your search by searching with:
        </p>
        <div className='pt-2  flex justify-center items-center md:justify-around  flex-wrap gap-y-6 w-full'>
          {options.map(({ value, label }, i) => (
            <Input
              key={i}
              value={value}
              label={label}
              inputClass='mt-[1.23px]'
              type='radio'
              name='search-filter'
              className='pr-7'
              onChange={() => setFilterValue(value)}
            />
          ))}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Search;
