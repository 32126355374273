import React, { FC, useEffect, useRef, useState } from 'react';
import { BrandType, ReviewType } from '../../utils/types';
import ReviewCard from '../../components/review/review-card';
import MarkAsResolvedModal from '../../components/review/mark-as-resolved-modal';
import { apiService } from '../../services/base.service';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  DateRangeSelector,
  ENUM_DATE_RANGE,
  getDateRangeSelector
} from '@cokitchen/cokitchen-components';

type Props = {
  activeBrand: BrandType;
  setActiveBrand: (brand: BrandType) => void;
  allBrands: BrandType[];
};

const ALL_TABS = {
  all: 'All',
  done: 'Resolved',
  pending: 'Unresolved'
} as const;

const navStyles = {
  active: 'border-b border-b-[4px] border-b-[#E60A2B] mb-0 text-[#1A1A1A]',
  inactive: 'text-[#858585]'
};

const BrandReviews: FC<Props> = ({ activeBrand, setActiveBrand, allBrands }) => {
  const isMounted = useRef(false);
  const [status, setStatus] = useState<keyof typeof ALL_TABS>('all');
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState<ReviewType[]>([]);
  const [showMarkModal, setShowMarkModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState({} as ReviewType);
  const [filters, setFilters] = useState(getDateRangeSelector(ENUM_DATE_RANGE.THIS_WEEK));
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalNumber, setTotalNumber] = useState(0);

  const fetchSingleBrandReviews = async (pageNo: number) => {
    try {
      setLoading(true);
      const { startDate: start_date, endDate: end_date } = filters;
      const params: any = {
        start_date,
        end_date
      };
      if (status !== 'all') params.status = status;
      const res = await apiService.get(
        `marketing/brand/one/reviews/${activeBrand.id}/${pageNo}`,
        params
      );
      const { brand_reviews } = res.data;
      const revs = pageNo === 1 ? brand_reviews.results : [...reviews, ...brand_reviews.results];
      setReviews(revs);
      setTotalNumber(brand_reviews.total);
    } finally {
      isMounted.current = true;
      setLoading(false);
    }
  };

  const fetchAllBrandReviews = async (pageNo: number) => {
    try {
      setLoading(true);
      const { startDate: start_date, endDate: end_date } = filters;
      const params: any = {
        start_date,
        end_date
      };
      if (status !== 'all') params.status = status;
      const res = await apiService.get(
        `marketing/brand/reviews/85c7d173-56ed-44c8-8335-ef0eecabf454/${pageNo}`,
        params
      );
      const { brand_reviews } = res.data;
      const revs = pageNo === 1 ? brand_reviews.results : [...reviews, ...brand_reviews.results];
      setReviews(revs);
      setTotalNumber(brand_reviews.total);
    } finally {
      isMounted.current = true;
      setLoading(false);
    }
  };

  const fetchBrandReviews = (defaultPage?: number) => {
    const pageNo = defaultPage ?? pageNumber;
    if (activeBrand.id === 'ALL') {
      fetchAllBrandReviews(pageNo);
      return;
    }
    fetchSingleBrandReviews(pageNo);
  };

  useEffect(() => {
    fetchBrandReviews();
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setPageNumber(1);
      fetchBrandReviews(1);
    }
  }, [status, filters]);

  useEffect(() => {
    setHasMore(reviews.length < totalNumber);
  }, [reviews]);

  useEffect(() => {
    if (pageNumber > 1) fetchBrandReviews();
  }, [pageNumber]);

  const openMarkModal = (review: ReviewType) => {
    setShowMarkModal(true);
    setSelectedReview(review);
  };

  const fetchMoreData = () => {
    setPageNumber((p) => p + 1);
  };

  const getBrand = (rev: ReviewType) => {
    return allBrands.find((a) => a.id === rev.brand_id) ?? allBrands[0];
  };

  const updateResolvedIssue = () => {
    const reviewIndex = reviews.findIndex((r) => r.id === selectedReview.id);
    const updatedReview: ReviewType = {
      ...reviews[reviewIndex],
      done: true,
      pending: false,
      rate: '5.00'
    };
    const otherReviews = reviews.filter((r) => r.id !== selectedReview.id);
    otherReviews.splice(reviewIndex, 0, updatedReview);
    setReviews(otherReviews);
  };

  return (
    <div>
      {activeBrand.id !== 'ALL' && (
        <p className='font-medium text-lg mb-4'>
          Showing reviews for {activeBrand?.name} ({totalNumber})
        </p>
      )}
      <div className='flex justify-between items-center gap-4 border-b border-gray-border mb-6'>
        <ul className='flex justify-start no-scroll-bar grow'>
          {Object.entries(ALL_TABS).map(([key, value], index) => (
            <li
              role='button'
              key={index}
              onClick={() => setStatus(key as any)}
              className={`flex mr-6 last:mr-0 cursor-pointer py-4 whitespace-nowrap
          ${key === status ? navStyles.active : navStyles.inactive}`}>
              {value}
            </li>
          ))}
        </ul>
        <DateRangeSelector onChange={(v) => setFilters(v.date)} />
      </div>
      {reviews.length === 0 ? (
        <div className='text-2xl leading-[40vh] text-center'>
          {loading ? (
            'Loading...'
          ) : (
            <span>
              There are no reviews
              {status !== 'all' ? ' with the selected filter' : ''}
            </span>
          )}
        </div>
      ) : (
        <InfiniteScroll
          hasMore={hasMore}
          next={() => fetchMoreData()}
          dataLength={reviews.length}
          loader={<p className='text-center mt-8'>Loading more reviews...</p>}
          endMessage={<p className='text-center mt-8'>End of list</p>}>
          <div className='grid gap-6 grid-cols-3'>
            {reviews.map((review) => (
              <ReviewCard
                key={review.id}
                review={review}
                brand={getBrand(review)}
                isAllSelected={activeBrand.id === 'ALL'}
                isResolved={+review.rate > 4 && !review.pending}
                markIssue={() => openMarkModal(review)}
                onBrandSelect={() => setActiveBrand(getBrand(review))}
              />
            ))}
          </div>
        </InfiniteScroll>
      )}
      <MarkAsResolvedModal
        show={showMarkModal}
        onClose={() => setShowMarkModal(false)}
        onSuccess={updateResolvedIssue}
        review={selectedReview}
        itemType={activeBrand.item_type}
      />
    </div>
  );
};

export default BrandReviews;
