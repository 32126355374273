import React from 'react';
import { ReactComponent as EmptyIcon } from '../../../assets/icons/empty-search-result.svg';

const NoSearchResult = () => {
  return (
    <div className='flex justify-center items-center'>
      <div>
        <EmptyIcon />
        <p className='py-3'>No search result found</p>
      </div>
    </div>
  );
};

export default NoSearchResult;
