import React from 'react';
import { ReactComponent as BagIcon } from '../../../assets/icons/bag.svg';
const EmptyBasket = () => {
  return (
    <div className='h-[100%] grid place-content-center'>
      <div className='relative'>
        <BagIcon />
        <span className='bg-red-4 rounded-full w-5 h-5 flex justify-center items-center text-xs text-white absolute -right-[6px] bottom-4'>
          0
        </span>
      </div>
    </div>
  );
};

export default EmptyBasket;
