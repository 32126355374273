import React, { useContext, useState } from 'react';
import DetailsCard, { DetailsCardType } from '../../../components/base/cards/details-card';
import { AddonCategoryType, MealType } from '../../../utils/types';
import { CreateOrderContext } from '../contexts/create-order-context';
import { doesMealExist, formatAddonCategory, mealIsConfirmed } from '../functions';
import { BRAND_NAMES, CREATE_ORDER_LIST } from '../../../utils/constants';

const MealCategories = () => {
  const [addonLoading, setAddonLoading] = useState(false);

  const {
    formData,
    setFormData,
    setAddonSelection,
    setAddonCategory,
    setIdx,
    setActive,
    tabList,
    setMealConfirmed,
    setTabList
  } = useContext(CreateOrderContext);
  const mealCategory = formData?.meal_categories;
  const selectedMeal = formData?.selectedMeal;

  const handleSelectMeal = async (item: MealType) => {
    setMealConfirmed(false);
    setFormData({ ...formData, selectedMeal: item?.name });
    if (item.item_type === 'SHOP' || formData?.brandName === BRAND_NAMES?.COCKTAIL_CLUB) return;
    if (item.name === formData.selectedMeal) {
      setActive(tabList[2].name);
      return;
    }
    setIdx(null);
    setAddonCategory([]);
    setAddonLoading(true);
    await getAddonCategory(item);
    setAddonLoading(false);
  };
  const getAddonCategory = async (item: MealType) => {
    const addon_cat = await formatAddonCategory(item?.id);
    getDefaultAddons(addon_cat, item);
    if (addon_cat.length > 0) setAddonCategory(addon_cat);
  };

  const getDefaultAddons = (addon_cat: AddonCategoryType[], item: MealType) => {
    const brandName = formData?.brandName;
    const idx = formData?.selectedMeals[brandName]?.meals?.length - 1;
    const defaultAddons: any = {};
    addon_cat.map((item: AddonCategoryType) =>
      item.addons.map((itm) => {
        if (item?.meal_default_id === itm?.meal_data?.id)
          if (item?.meal_category_selection_type?.name === 'SINGLE_SELECTION') {
            defaultAddons[item.id] = {
              name: itm.meal_data.name,
              id: itm.meal_data.id,
              quantity: 1,
              price: Number(itm.meal_data?.amount)
            };
          } else {
            defaultAddons[itm.meal_addon_id] = {
              name: itm.meal_data.name,
              id: itm.meal_data.id,
              quantity: 1,
              price: Number(itm.meal_data?.amount)
            };
          }
      })
    );
    setAddonSelection(defaultAddons);
    if (!mealIsConfirmed(formData, item)) {
      setTabList(CREATE_ORDER_LIST);
      formData.selectedMeals[brandName].meals[idx] = {
        id: item?.id,
        brandName: formData?.brandName,
        quantity: 1,
        name: item?.name,
        price: Number(item?.amount),
        addons: { ...defaultAddons },
        item_type: item?.item_type,
        is_addon: item?.is_addon,
        is_confirmed: false
      };
    } else {
      formData.selectedMeals[formData?.brandName].meals = [
        ...formData.selectedMeals[formData?.brandName].meals,
        {
          id: item?.id,
          brandName: formData?.brandName,
          quantity: 1,
          name: item?.name,
          price: Number(item?.amount),
          addons: { ...defaultAddons },
          item_type: item?.item_type,
          is_addon: item?.is_addon,
          is_confirmed: false
        }
      ];
    }
  };

  const handleAddMeal = (meal: MealType) => {
    const brandName = formData?.brandName;
    const curr = formData.selectedMeals[brandName].meals;
    const idx = curr?.length - 1;

    const mealExists = doesMealExist(meal?.id, formData);
    if (mealExists) {
      mealExists.quantity = mealExists?.quantity + 1;
    } else if (!mealIsConfirmed(formData, meal)) {
      formData.selectedMeals[brandName].meals[idx] = {
        name: meal?.name,
        id: meal?.id,
        price: Number(meal?.amount),
        quantity: 1,
        brandName,
        item_type: meal?.item_type,
        is_addon: meal?.is_addon,
        is_confirmed: false
      };
    } else {
      formData.selectedMeals[brandName].meals = [
        ...curr,
        {
          name: meal?.name,
          id: meal?.id,
          price: Number(meal?.amount),
          quantity: 1,
          brandName,
          item_type: meal?.item_type,
          is_addon: meal?.is_addon,
          is_confirmed: false
        }
      ];
    }
    setFormData({
      ...formData
    });
  };

  const handleSubtractMeal = (meal: MealType) => {
    const brandName = formData?.brandName;
    const curr = formData.selectedMeals[brandName].meals;

    const mealExists = doesMealExist(meal?.id, formData);
    if (mealExists && mealExists.quantity > 1) {
      mealExists.quantity = mealExists?.quantity - 1;
    } else if (mealExists) {
      formData.selectedMeals[brandName].meals = curr.filter((item) => item !== mealExists);
    }

    setFormData({
      ...formData
    });
  };

  const getCardType = (meal: MealType): DetailsCardType => {
    if (
      meal?.item_type === 'FOOD' &&
      formData?.brandName !== BRAND_NAMES?.COCKTAIL_CLUB &&
      !meal?.is_addon
    )
      return 'BRAND';
    return 'MULTI_SELECTION_WITH_QUANTITY';
  };

  return (
    <div className='space-y-8'>
      {mealCategory
        .sort((a, b) => a.position - b.position)
        .map((item) => (
          <div key={item.id} className='space-y-3'>
            <p className='font-medium text-lg'>{item?.name}</p>
            <div className='grid md:grid-cols-2 lg:grid-cols-3 justify-between gap-4 gap-y-6 '>
              {item?.meals
                .sort((a, b) => a.position - b.position)
                .map((meal, i) => (
                  <DetailsCard
                    cardType={getCardType(meal)}
                    disabled={addonLoading}
                    active={selectedMeal === meal?.name}
                    onClick={() => handleSelectMeal(meal)}
                    title={meal?.name}
                    key={i}
                    price={meal?.amount}
                    quantity={doesMealExist(meal?.id, formData)?.quantity}
                    loading={selectedMeal === meal?.name && addonLoading}
                    onAddClick={() => handleAddMeal(meal)}
                    onSubtractClick={() => handleSubtractMeal(meal)}
                  />
                ))}
            </div>
          </div>
        ))}
    </div>
  );
};

export default MealCategories;
