import React from 'react';
import { DeleteIcon, EditAltIcon } from 'cokitchen-icons';

const ProfileDetails = ({
  title,
  text,
  call,
  whatsapp,
  onEditClick,
  withEdit,
  withDelete,
  onDelClick,
  onClick
}: {
  title: string;
  text: string;
  call?: boolean;
  whatsapp?: boolean;
  onEditClick?: () => void;
  onDelClick?: () => void;
  onClick?: () => void;
  withEdit?: boolean;
  withDelete?: boolean;
}) => {
  return (
    <div className='space-y-2' onClick={onClick}>
      <label className='text-xs text-gray-5 flex items-center'>
        {withEdit && (
          <EditAltIcon
            className='w-5 h-5 p-1 hover:bg-blue-1 mr-1 cursor-pointer text-blue-5'
            onClick={onEditClick}
          />
        )}

        {withDelete && (
          <DeleteIcon
            className='w-5 h-5 p-1 hover:bg-red-1 mr-1 cursor-pointer text-red-5'
            onClick={onDelClick}
          />
        )}
        {title}
      </label>
      <div className='flex items-center gap-2'>
        <p className='text-sm'>{text}</p>
        {call && (
          <p className='text-[10px] capitalize text-red-5 bg-red-9 py-[3px] px-3 rounded-[100px]'>
            call
          </p>
        )}

        {whatsapp && (
          <p className='text-[10px] capitalize text-red-5 bg-red-9 py-[3px] px-3 rounded-[100px]'>
            whatsapp
          </p>
        )}
      </div>
    </div>
  );
};

export default ProfileDetails;
