import React, { FC, useState } from 'react';
import { ReactComponent as TitleIcon } from '../../../assets/icons/update-surge-modal-icon.svg';

import { toast } from 'react-toastify';
import { ToastMessage, BaseModal, Input, Button } from '@cokitchen/cokitchen-components';
import { apiService } from '../../../services/base.service';

interface PaystackModalProps {
  show: boolean;
  onClose: () => void;
  id: string;
}

interface PaystackModalFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  disabled: boolean;
}

const PaystackModalFooter: FC<PaystackModalFooterProps> = ({
  onCancel,
  onConfirm,
  loading,
  disabled
}) => (
  <div className='grid grid-cols-2 gap-x-4'>
    <Button small color='primary' variant='outline' className='mx-auto' onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      loading={loading}
      disabled={disabled}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const PaystackModal: FC<PaystackModalProps> = ({ show, onClose, id }) => {
  const [loading, setLoading] = useState(false);
  const [ref, setref] = useState('');

  const createPaystackRef = async () => {
    setLoading(true);
    const payload = {
      reference: ref,
      user_id: id
    };
    try {
      await apiService.post('logistics/super-admin/user/order/create', payload);
      toast(<ToastMessage type='success' message='Debt Updated successfully' />);
      onClose();
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      isOpen={show}
      title={`Create user's last order from Paystack
      `}
      titleIcon={<TitleIcon />}
      titleIconColor='info'
      onRequestClose={onClose}
      footer={
        <PaystackModalFooter
          loading={loading}
          onCancel={onClose}
          onConfirm={createPaystackRef}
          disabled={!ref}
        />
      }>
      <Input
        className='mb-2'
        label='Paystack Reference'
        onChange={(ev) => setref(ev.target.value)}
        value={ref}
        placeholder='paystack reference'
      />
    </BaseModal>
  );
};

export default PaystackModal;
