import React, { createContext, ReactNode } from 'react';
import { OrderType } from '../../utils/types';
import { OrderReceiptProps } from './types';

export const OrderReceiptContext = createContext({} as OrderReceiptProps);
const OrderReceiptProvider = ({ order, children }: { order: OrderType; children: ReactNode }) => {
  const orderId = order && order.order_code && order.order_code?.toUpperCase();
  const calculatedOrder = order?.calculated_order;
  const isGroupOrder = calculatedOrder?.is_group_order ?? false;
  const orders = order?.calculated_order?.meals;
  const orderCreatedAt = order?.created_at;
  let orderDetails = order?.order_details;
  const calculatedTotal = parseInt(String(calculatedOrder?.total_amount));
  const paid = order?.paid;
  const orderType = order?.order_type;

  const serviceTotal = Number(calculatedOrder?.service_charge);
  const deliveryFee =
    Number(calculatedOrder?.free_delivery && '0') ||
    Number(calculatedOrder?.delivery_fee) + Number(calculatedOrder?.delivery_service_charge);

  // To calculate total price of each meal

  const mealTotal = (meals: any) => {
    const total = meals?.amount * meals?.quantity;
    const addonTotal = meals?.addons?.reduce((acc: any, curr: any) => {
      return acc + parseFloat(curr.amount);
    }, 0);
    return total + addonTotal;
  };

  const shopPriceArray: number[] = [];
  orders?.map((meal) =>
    meal.meals.map((item) => {
      if (item?.item_type === 'SHOP') {
        const eachPrice = mealTotal(item);
        shopPriceArray.push(eachPrice);
      } else {
        return null;
      }
    })
  );

  const totalShopPrice = shopPriceArray?.reduce((acc, curr) => {
    return acc + curr;
  }, 0);

  const prevAmount = parseInt(calculatedOrder?.prev_price);
  const extraCharges = serviceTotal + deliveryFee;
  const subTotal = calculatedTotal - deliveryFee;
  // Reducer function to compute total
  const getTotal = (items: any[]) => {
    const total = items.reduce(function (acc, curr) {
      return acc + curr.quantity;
    }, 0);
    return total;
  };

  // To calculate the total number of items bought
  const getItemsTotal = (ords = orders) => {
    let i;
    const tot = [];
    for (i = 0; i < ords.length; i++) {
      tot.push(getTotal(ords[i].meals));
    }
    const allTotals = tot.reduce((acc, curr) => {
      return acc + curr;
    }, 0);

    return allTotals;
  };

  //if order is a group order
  const subOrders = calculatedOrder?.sub_calculated_order_ids ?? [];
  // calculated total for group order
  const calculatedGroupTotal = () => {
    const alltotal = subOrders?.reduce((acc, curr) => {
      return acc + (parseFloat(curr.total_amount) - extraCharges);
    }, 0);

    return alltotal;
  };

  const discountValue =
    prevAmount && !calculatedOrder?.is_group_order ? prevAmount - calculatedTotal : 0;

  orderDetails = typeof orderDetails === 'string' ? JSON.parse(orderDetails) : orderDetails;
  const walletAmount =
    typeof orderDetails === 'string' && JSON.parse(orderDetails)?.wallet_amount
      ? JSON.parse(orderDetails)?.wallet_amount
      : typeof orderDetails === 'object' && orderDetails?.wallet_amount
      ? orderDetails?.wallet_amount
      : 0;

  const isCutlery =
    typeof orderDetails === 'string'
      ? JSON.parse(orderDetails)?.cutlery
      : typeof orderDetails === 'object'
      ? orderDetails?.cutlery
      : true;

  const amountDueGroupOrder =
    !paid && walletAmount && orderDetails?.use_wallet
      ? calculatedGroupTotal() - parseInt(walletAmount)
      : !paid && !orderDetails?.use_wallet
      ? calculatedGroupTotal() - 0
      : 0;

  const amountDue =
    !paid && walletAmount && orderDetails?.use_wallet
      ? calculatedTotal - parseInt(walletAmount)
      : !paid && !orderDetails?.use_wallet
      ? calculatedTotal - 0
      : 0;

  return (
    <OrderReceiptContext.Provider
      value={{
        calculatedOrder,
        isGroupOrder,
        orders,
        getItemsTotal,
        orderCreatedAt,
        order,
        mealTotal,
        subOrders,
        isCutlery,
        discountValue,
        calculatedGroupTotal,
        subTotal,
        deliveryFee,
        serviceTotal,
        extraCharges,
        calculatedTotal,
        paid,
        amountDueGroupOrder,
        amountDue,
        orderType,
        orderId,
        totalShopPrice
      }}>
      {children}
    </OrderReceiptContext.Provider>
  );
};

export default OrderReceiptProvider;
