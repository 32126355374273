import React from 'react';
import { LIVE_TRIP_ENUM_STATUS } from '../../../utils/enum';
import { ExtendedFilterType } from '../../../pages/live-trips/types';

export type TabProps = {
  filter: ExtendedFilterType;
  setActiveFilter: (item: ExtendedFilterType) => void;
  numberOfItems?: Record<ExtendedFilterType, number>;
};
export const StatusTabs: React.FC<TabProps> = ({ setActiveFilter, filter, numberOfItems }) => {
  const status: ExtendedFilterType[] = [
    'All',
    LIVE_TRIP_ENUM_STATUS.ONGOING,
    LIVE_TRIP_ENUM_STATUS.READY,
    LIVE_TRIP_ENUM_STATUS.PROCESSING,
    LIVE_TRIP_ENUM_STATUS.PENDING
  ];
  const styles = {
    active: 'bg-[#E60A2B] text-white',
    inactive: 'bg-[#F5F5F5] text-[#858585]'
  };
  return (
    <ul className='flex justify-start'>
      {status.map((item, index) => (
        <li
          key={index}
          onClick={() => {
            setActiveFilter(item);
          }}
          className={`leading-[40px] flex items-center justify-center rounded-full px-5 mr-4 last:mr-0 cursor-pointer capitalize ${
            filter === item ? styles.active : styles.inactive
          }`}>
          {item}
          {numberOfItems && item !== 'All' && (
            <span
              className={`text-xs ml-1 w-[24px] h-[24px] ${
                filter === item ? 'bg-[#ff6776]' : 'bg-red-5'
              } text-white rounded-full inline-flex items-center justify-center`}>
              {numberOfItems[item]}
            </span>
          )}
        </li>
      ))}
    </ul>
  );
};
