import React, { FC, useState } from 'react';
import { ReviewType } from '../../../utils/types';
import { Button, BaseModal, ToastMessage } from '@cokitchen/cokitchen-components';
import { UserIcon } from 'cokitchen-icons';
import { apiService } from '../../../services/base.service';
import { toast } from 'react-toastify';

interface Props {
  show: boolean;
  onClose: () => void;
  onSuccess: () => void;
  review: ReviewType;
  itemType: 'FOOD' | 'SHOP';
}

interface FinishOrderFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const MarkAsResolvedFooter: FC<FinishOrderFooterProps> = ({ onCancel, onConfirm, loading }) => (
  <div className='grid grid-cols-2 gap-x-4'>
    <Button small color='primary' variant='outline' className='mx-auto' onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      loading={loading}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const MarkAsResolvedModal: FC<Props> = ({ show, onClose, onSuccess, review, itemType }) => {
  const [loading, setLoading] = useState(false);
  const markAsResolved = async () => {
    setLoading(true);
    try {
      const url =
        itemType === 'FOOD' ? `brand/review/done/${review.id}` : `shop/review/done/${review.id}`;
      const res = await apiService.patch(url, null);
      toast(<ToastMessage type='success' message={res.data.message} />);
      onSuccess();
      onClose();
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      isOpen={show}
      title='Mark as resolved'
      description={`Are you sure you want to mark ${review.user?.first_name}'s review as resolved`}
      titleIcon={<UserIcon />}
      titleIconColor='info'
      onRequestClose={onClose}
      footer={
        <MarkAsResolvedFooter loading={loading} onCancel={onClose} onConfirm={markAsResolved} />
      }
    />
  );
};

export default MarkAsResolvedModal;
