import React from 'react';
import { Navigate } from 'react-router-dom';
import { CS_ROLES, ROUTE_KEYS, STORAGE_KEYS } from '../utils/constants';
import { ENUM_CS_ROLES } from '../utils/enum';

const UnauthRoute = ({ children }: { children: JSX.Element }) => {
  const token = localStorage.getItem(STORAGE_KEYS.TOKEN);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);

  const denyAccess = () => {
    return !token || (token && !CS_ROLES.includes(role as ENUM_CS_ROLES));
  };
  return denyAccess() ? children : <Navigate to={ROUTE_KEYS.PENDING_TASKS} />;
};
export default UnauthRoute;
