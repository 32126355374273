import React, { useContext } from 'react';
import DetailsCard from '../../../components/base/cards/details-card';
import { CREATE_ORDER_LIST } from '../../../utils/constants';
import { BrandType, MealCategoryType } from '../../../utils/types';
import { CreateOrderContext } from '../contexts/create-order-context';
import { formatMealCategory } from '../functions';

const Brands = () => {
  const { brands, formData, setFormData, setActive, mealConfirmed } =
    useContext(CreateOrderContext);
  const brandName = formData?.brandName;

  const handleSelectBrand = (item: BrandType) => {
    if (!mealConfirmed && item.name !== formData.brandName) {
      return;
    } else {
      const meal_categories_array: MealCategoryType[] = formatMealCategory(
        JSON.parse(item?.meal_categories)
      );

      if (item?.name !== formData?.brandName) {
        setFormData({
          ...formData,
          brandName: item?.name,
          meal_categories: meal_categories_array,
          selectedMeals: {
            ...formData.selectedMeals,
            [item?.name]: {
              name: item.name,
              meals:
                formData.selectedMeals[item?.name]?.meals?.length > 0
                  ? [...formData.selectedMeals[item?.name].meals]
                  : []
            }
          }
        });
      }
    }
    setTimeout(() => setActive(CREATE_ORDER_LIST[1].name), 500);
  };
  return (
    <div className='grid md:grid-cols-2 lg:grid-cols-3 justify-between gap-4 gap-y-6'>
      {brands.map((item, i) => (
        <DetailsCard
          active={brandName === item?.name}
          onClick={() => handleSelectBrand(item)}
          title={item?.name}
          cardType='BRAND'
          key={i}>
          {item?.summary}
        </DetailsCard>
      ))}
    </div>
  );
};

export default Brands;
