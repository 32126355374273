import React, { useContext } from 'react';
import EmptyBasket from './empty-basket';
import BasketMeals from './basket-meals';
import './styles.scss';
import BasketFooter from './basket-footer';
import BasketHeader from './basket-header';
import { CreateOrderContext } from '../contexts/create-order-context';
import BasketContextProvider from '../contexts/basket-context';
import { getTotalMeals } from '../functions';

const RightSection = () => {
  const { formData } = useContext(CreateOrderContext);
  return (
    <BasketContextProvider>
      <div className='right-section'>
        <BasketHeader />
        {getTotalMeals(formData?.selectedMeals) === 0 ? (
          <EmptyBasket />
        ) : (
          <div className='h-[80%] flex flex-col justify-between gap-10 relative'>
            <BasketMeals />
            <BasketFooter />
          </div>
        )}
      </div>
    </BasketContextProvider>
  );
};

export default RightSection;
