import React, { useEffect, useState } from 'react';
import { apiService } from '../../../../services/base.service';
import { IssueType, UserType } from '../../../../utils/types';
import UserIssueCard from '../../../base/cards/user-issue-card';
import Loader from '../../../base/loader';
import { Paginate } from '@cokitchen/cokitchen-components';

const UserIssues = ({ user }: { user: UserType }) => {
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [issues, setIssues] = useState([] as Partial<IssueType>[]);

  const loadIssues = async (pageNo = 1) => {
    setLoading(true);
    const { id: user_id } = user;
    const res = await apiService.post(`user/one/issues/${pageNo}`, {
      user_id
    });
    const response = res?.data;
    const data = response?.data;

    const pageCount = Math.ceil(response?.total / 10);
    setPageCount(pageCount);
    setIssues(data);
    setLoading(false);
  };

  const getSelectedPage = (val: { selected: number }) => {
    const pageNo = val?.selected + 1;
    loadIssues(pageNo);
  };

  useEffect(() => {
    loadIssues();
  }, []);

  return (
    <div className='pt-8 pb-3 space-y-6'>
      {loading ? (
        <Loader />
      ) : issues.length === 0 ? (
        <div className={`flex items-center justify-center h-full  font-semibold`}>
          No Issues To Show
        </div>
      ) : (
        <div className='grid lg:grid-cols-2 gap-6'>
          {issues?.map((issue) => (
            <UserIssueCard key={issue.id} item={issue} />
          ))}
        </div>
      )}
      <Paginate onPageChange={getSelectedPage} pageCount={pageCount} />
    </div>
  );
};

export default UserIssues;
