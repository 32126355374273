import React from 'react';
import { TransactionActionType } from '../../../utils/types';
import './styles.scss';

const TransactionTag = ({ type }: { type: TransactionActionType }) => {
  return (
    <div className={`transaction-tag ${type === 'Credit' ? 'credit' : 'debit'}`}>
      <p className='dot'> </p>
      <p>{type === 'Credit' ? 'credit' : 'debit'}</p>
    </div>
  );
};

export default TransactionTag;
