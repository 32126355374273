import React, { Fragment, useContext } from 'react';
import { ReactComponent as FoodCourtLogo } from '../../assets/logo.svg';
import { OrderReceiptContext } from '.';
import moment from 'moment';
import { formatNumber } from '../../utils/functions';
const ReceiptContent = () => {
  const {
    calculatedOrder,
    isGroupOrder,
    orders,
    getItemsTotal,
    orderCreatedAt,
    order,
    mealTotal,
    isCutlery,
    discountValue,
    calculatedGroupTotal,
    subTotal,
    subOrders,
    deliveryFee,
    serviceTotal,
    extraCharges,
    calculatedTotal,
    paid,
    amountDueGroupOrder,
    amountDue,
    orderType,
    orderId,
    totalShopPrice
  } = useContext(OrderReceiptContext);
  const FOOTER_LIST = [
    {
      name: 'Cutlery',
      value: isCutlery ? 'YES' : 'NO',
      style: 'font-bold'
    },
    {
      name: 'Discount',
      value: formatNumber(discountValue)
    },
    {
      name: 'Service fee',
      value: formatNumber(serviceTotal)
    },
    {
      name: 'Subtotal',
      value: isGroupOrder ? formatNumber(calculatedGroupTotal()) : formatNumber(subTotal)
    },
    {
      name: 'Delivery fee',
      value: formatNumber(deliveryFee)
    },

    {
      name: 'Order total',
      value: isGroupOrder
        ? formatNumber(calculatedGroupTotal() + extraCharges)
        : formatNumber(calculatedTotal)
    },
    {
      name: 'Amount due',
      value: !paid
        ? isGroupOrder
          ? formatNumber(amountDueGroupOrder + extraCharges)
          : formatNumber(amountDue)
        : null
    },

    {
      name: 'Payment',
      value: orderType?.name,
      style: 'font-bold'
    }
  ];
  return (
    <Fragment>
      {/* Receipt View Start */}
      <div className='px-3 text-center text-black font-medium'>
        <div className='flext justify-center flex flex-col items-center'>
          <FoodCourtLogo />
          <p className='font-bold'>#{orderId}</p>
          {!isGroupOrder && calculatedOrder?.pickup ? (
            <p className='font-bold'>PICKUP</p>
          ) : (
            <p className='font-bold'>DELIVERY</p>
          )}
          {isGroupOrder ? <p className='font-bold '>Group Order</p> : null}
          {isGroupOrder ? (
            <label>
              {calculatedOrder?.sub_calculated_order_ids?.length + 1}
              {calculatedOrder?.sub_calculated_order_ids?.length + 1 === 1 ? 'person' : 'people'}
            </label>
          ) : (
            <label>
              {`${orders && orders.length} ${orders && orders.length === 1 ? 'brand' : 'brands'}`},
              {` ${orders && getItemsTotal()} ${
                orders && getItemsTotal() === 1 ? 'item' : 'items'
              }`}
            </label>
          )}
          <p>
            {orderCreatedAt && moment(orderCreatedAt).format('DD/MM/YYYY ')} at
            {orderCreatedAt && moment(orderCreatedAt).format(' LT')}
          </p>
        </div>
        {isGroupOrder ? (
          <div className='flex justify-between items-start mb-1'>
            <p>
              <span className='bold'>
                1.{' '}
                {order?.calculated_order?.user?.first_name +
                  ' ' +
                  order?.calculated_order?.user?.last_name}
              </span>
              (host)
            </p>

            <p>
              {`   ${orders && orders.length} ${
                orders && orders.length === 1 ? 'brand' : 'brands'
              }`}
              ,
              {` ${orders && getItemsTotal()} ${
                orders && getItemsTotal() === 1 ? 'item' : 'items'
              }`}
            </p>
          </div>
        ) : null}

        {calculatedOrder?.item_type === 'ALL' && (
          <div className='flex justify-start items-start py-1'>
            <div className='flex-col flex  justify-start items-start'>
              <div className='flex justify-between items-center '>
                <p className='mb-1 font-bold'>Shop</p>
                {formatNumber(totalShopPrice)}
              </div>
            </div>
          </div>
        )}

        <div className='mb-4'>
          {orders &&
            orders.map((itms, ind) => {
              return (
                itms?.brand?.item_type === 'FOOD' && (
                  <div className=' flex-col flex mb-2' key={ind}>
                    <div className='mb-1 flex font-bold mt-2 justify-center items-center p-0'>
                      <label className='font-bold'>{itms && itms.brand && itms.brand.name}</label>
                    </div>

                    <div className='px-1'>
                      {itms &&
                        itms.meals.map((item, i) => {
                          mealTotal(item);
                          <div className='flex justify-between items-start mb-1'>
                            <p>
                              <span className='bold'>
                                1.{' '}
                                {order?.calculated_order?.user?.first_name +
                                  ' ' +
                                  order?.calculated_order?.user?.last_name}{' '}
                              </span>
                              (host)
                            </p>

                            <p>
                              {`   ${orders && orders.length} ${
                                orders && orders.length === 1 ? 'brand' : 'brands'
                              }`}
                              ,
                              {` ${orders && getItemsTotal()} ${
                                orders && getItemsTotal() === 1 ? 'item' : 'items'
                              }`}
                            </p>
                          </div>;
                          return (
                            <div key={i + 'items'} className='flex justify-between py-1 w-full'>
                              <div className='flex-col flex  items-start justify-start'>
                                {item?.order_note && <span className='bold mr-2 '>Note: </span>}
                                <h6 className='mr-2 font-semibold text-xl'>
                                  {item?.quantity + '×'}
                                </h6>
                              </div>
                              <div className='flex-col flex  items-start justify-end'>
                                <label className='text-sm mb-1 ml-auto'>{item?.order_note}</label>
                                <div className='flex justify-between items-center gap-6'>
                                  <p className='font-bold'>{item && item.name}</p>
                                  {formatNumber(mealTotal(item))}
                                </div>

                                {item &&
                                  item.addons &&
                                  item.addons.map((itm, ind) => {
                                    return (
                                      <label className='text-sm mt-1' key={ind + 'itm'}>
                                        {itm && itm.meal_data && itm.meal_data.name}
                                        <span> x {itm && itm.quantity}</span>
                                      </label>
                                    );
                                  })}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )
              );
            })}
        </div>
        {/* receipt Sub orders */}

        {subOrders?.map((subOrder, i) => {
          return (
            <>
              <div className='flex justify-between items-start mb-1'>
                <p className='bold pointer'>
                  {i + 2}. {subOrder?.user?.first_name + ' ' + subOrder?.user?.last_name}{' '}
                  {subOrder?.first_order ? (
                    <div className='flex items-center gap-1 uppercase font-bold'> NEW!</div>
                  ) : null}
                </p>
                <p className={`text-left`}>
                  {`   ${subOrder?.meals?.length} ${
                    subOrder?.meals?.length === 1 ? 'brand' : 'brands'
                  }`}
                  ,
                  {` ${subOrder?.meals && getItemsTotal(subOrder?.meals)} ${
                    subOrder?.meals && getItemsTotal(subOrder?.meals) === 1 ? 'item' : 'items'
                  }`}
                </p>
              </div>
              {subOrder?.meals &&
                subOrder?.meals?.map((itms, ind) => {
                  return (
                    <div className=' flex-col flex mb-2 w-full' key={ind}>
                      <div className='mb-1 flex font-bold  mt-2 justify-center items-center p-0 w-full'>
                        <label className='bold'>{itms && itms.brand && itms.brand.name}</label>
                      </div>

                      <div className='px-1'>
                        {itms &&
                          itms.meals.map((item, i) => {
                            return (
                              <div key={i + 'items'} className='flex justify-between py-1 w-full'>
                                <div className='flex-col flex  items-start justify-start'>
                                  {item?.order_note && <span className='bold mr-2 '>Note: </span>}
                                  <h6 className='mr-2 font-semibold text-xl'>
                                    {item?.quantity + '×'}
                                  </h6>
                                </div>
                                <div className='flex-col flex  items-start justify-end'>
                                  <label className='text-sm ml-auto mb-1'>{item?.order_note}</label>
                                  <div className='flex justify-between items-center gap-6'>
                                    <p className='font-bold'>{item && item.name}</p>₦
                                    {formatNumber(mealTotal(item))}
                                  </div>

                                  {item &&
                                    item.addons &&
                                    item.addons.map((itm, ind) => {
                                      return (
                                        <label className='text-sm mt-1' key={ind + 'itm'}>
                                          {itm && itm.meal_data && itm.meal_data.name}
                                          <span> x {itm && itm.quantity}</span>
                                        </label>
                                      );
                                    })}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </>
          );
        })}
        {/*receipt sub orders end */}
        {/* Receipt footer start */}
        {FOOTER_LIST.map(({ name, value, style }, i) =>
          value !== null ? (
            <div key={i} className={`${style} flex justify-between items-center  mb-1`}>
              <p className='mb-1'>{name}</p>
              <p className='mb-1'>{value}</p>
            </div>
          ) : null
        )}
        <div className=' px-1 pt-2'>
          <p className=' mb-2 text-center'>12.5% tax included in price</p>
          <p className=' mb-2 text-center'>
            complaints? email us <br />
            hello@getfoodcourt.com
          </p>
          <p className=' mb-2 text-center'>www.getfoodcourt.com</p>
          <p className=' mb-1 text-center'>Thanks, order again soon!</p>
        </div>
        {/* Receipt footer end */}
      </div>

      {/* Receipt View End */}
    </Fragment>
  );
};

export default ReceiptContent;
