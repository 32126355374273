import React, { useEffect, useState } from 'react';
import SettingsTitleBar from '../../components/settings-title-bar';
import { BrandsIcon } from 'cokitchen-icons';
import { ExistingCSProfileType, PhoneNoType } from '../../utils/types';
import ProfileDetails from '../../components/profile-details';
import AddContactModal from './modals/add-contact-modal';
import { STORAGE_KEYS } from '../../utils/constants';
import { apiService } from '../../services/base.service';
import { Switch, ToastMessage } from '@cokitchen/cokitchen-components';
import { toast } from 'react-toastify';
import SettingHeaderCard from '../../components/settings-header-card';
import DeleteNumberModal from './modals/delete-number-modal';

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showDelModal, setShowDelModal] = useState(false);
  const [phoneNos, setPhoneNos] = useState({} as PhoneNoType);
  const [selectedNo, setSelectedNo] = useState<ExistingCSProfileType | null>(null);
  const [isOnline, setIsOnline] = useState<boolean | null>(null);
  const [error, setError] = useState(false);
  const [toggleSwitch, setToggleSwitch] = useState(false);

  const contactModal = (withRes?: boolean) => {
    if (withRes) getPhoneNumber();
    setShowContactModal(!showContactModal);
  };

  const deleteModal = (fetchPhoneNo?: boolean) => {
    if (fetchPhoneNo) getPhoneNumber();
    setShowDelModal(!showDelModal);
  };
  const user_id = localStorage.getItem(STORAGE_KEYS.USER_ID);

  const firstName = localStorage.getItem(STORAGE_KEYS.FIRST_NAME) || '';
  const lastName = localStorage.getItem(STORAGE_KEYS.LAST_NAME) || '';
  const email = localStorage.getItem(STORAGE_KEYS.EMAIL) || '';

  const PROFILE_DETAILS = [
    {
      title: 'First name',
      text: firstName
    },
    {
      title: 'Last name',
      text: lastName
    },
    {
      title: 'Email',
      text: email
    }
  ];

  const editNumber = () => {
    contactModal();
  };

  useEffect(() => {
    if (!showContactModal) {
      setSelectedNo(null);
    }
  }, [showContactModal]);

  const getOnlineStatus = async () => {
    try {
      const res = await apiService.get(`cs/staff/check-cs-status/${user_id}`, {});
      if (res?.data) setIsOnline(res?.data?.data?.is_online);
    } catch (err: any) {
      console.log(err);
    }
  };

  const getPhoneNumber = async () => {
    setLoading(true);

    try {
      const res = await apiService.get('cs/staff/phone-number', null);
      if (res?.data) setPhoneNos(res?.data);
    } catch (err: any) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPhoneNumber();
  }, []);

  const onlineText = () => {
    if (isOnline) {
      return 'Online';
    } else {
      return 'Offline';
    }
  };

  const toggleOnline = async () => {
    setError(false);
    try {
      const res = await apiService.put('cs/staff/phone-number/status', { is_online: isOnline });
      if (res) {
        toast(<ToastMessage type='success' message={`You are ${onlineText()}`} />);
      }
    } catch (err: any) {
      setError(true);
      toast(<ToastMessage type='error' message={err.message} />);
    }
  };

  useEffect(() => {
    if (error) setIsOnline(!isOnline);
  }, [error]);

  useEffect(() => {
    getOnlineStatus();
  }, []);

  useEffect(() => {
    if (toggleSwitch) toggleOnline();
  }, [isOnline, toggleSwitch]);

  return (
    <>
      {loading ? (
        <span className='text-[10px]'>Loading...</span>
      ) : (
        <div className='w-full space-y-12'>
          {phoneNos?.exisiting_cs_profile?.length === 0 && (
            <SettingHeaderCard
              title={`Welcome ${firstName}`}
              details='We need a few more details to complete your profile'
              icon={<BrandsIcon />}
              onClick={() => contactModal()}
              withArrow
            />
          )}

          <div className='space-y-6'>
            <SettingsTitleBar title='Personal info' />
            <div className='flex justify-between'>
              {PROFILE_DETAILS.map(({ text, title }, i) => (
                <ProfileDetails title={title} text={text} key={i} />
              ))}
            </div>
          </div>

          {phoneNos?.exisiting_cs_profile?.length > 0 && (
            <div className='space-y-6'>
              <SettingsTitleBar withAdd onAddClick={() => contactModal()} title='Contact number' />

              {phoneNos?.exisiting_cs_profile?.map((item, i) => (
                <ProfileDetails
                  title={`Mobile no. ${i + 1}.`}
                  text={item?.phone_number}
                  call={item?.is_call_reachable}
                  whatsapp={item?.is_whatsapp_reachable}
                  key={item?.id}
                  withEdit
                  withDelete
                  onEditClick={() => editNumber()}
                  onDelClick={() => deleteModal()}
                  onClick={() => setSelectedNo(item)}
                />
              ))}
              {
                <div className=' flex items-center gap-3'>
                  <Switch
                    active={isOnline ?? false}
                    onChange={() => {
                      setIsOnline(!isOnline);
                      setToggleSwitch(true);
                    }}
                    small
                  />{' '}
                  <span className=' -mt-[1px] text-sm font-semibold'>Online</span>
                </div>
              }
            </div>
          )}

          <AddContactModal
            onClose={contactModal}
            show={showContactModal}
            phoneNoData={selectedNo as ExistingCSProfileType}
          />

          <DeleteNumberModal
            onClose={deleteModal}
            show={showDelModal}
            phoneNo={selectedNo as ExistingCSProfileType}
          />
        </div>
      )}
    </>
  );
};

export default Profile;
