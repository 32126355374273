import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrderStatus } from '../../utils/functions';
import { OrderType } from '../../utils/types';
import OrderStatus from '../base/order-status';
import OrderCardDetails from './order-card-details';
import OrderCardFooter from './order-card-footer';
import './styles.scss';
const OrderCard = ({ orders }: { orders: OrderType[] }) => {
  const navigate = useNavigate();
  const goToOrder = (order: OrderType) => {
    const { order_code } = order;
    navigate(`/order/${order_code}`);
  };
  return (
    <div className='grid md:grid-cols-2 lg:grid-cols-3 gap-4'>
      {orders.map((order, i) => (
        <div
          key={order.id + i}
          onClick={() => goToOrder(order)}
          role='link'
          className='cursor-pointer live-trip-card relative'>
          <div className='px-4'>
            <OrderStatus type={getOrderStatus(order)} />
          </div>
          <OrderCardDetails orderDetails={order} />
          <OrderCardFooter order={order} />
        </div>
      ))}
    </div>
  );
};

export default OrderCard;
