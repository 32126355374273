import React, { useEffect, useState } from 'react';
import BankCard from '../../components/bank-card';
import { BrandsIcon } from 'cokitchen-icons';
import SettingHeaderCard from '../../components/settings-header-card';
import { apiService } from '../../services/base.service';
import ToggleStatusModal from './modals/toggle-status-modal';
import { BANK_NAMES } from '../../utils/constants';
import { getBankLogo } from '../../utils/functions';
import { ENUM_BANK_NAMES } from '../../utils/enum';

export type BankType = {
  name: string;
  active: boolean;
  fullname: ENUM_BANK_NAMES;
};

const Banks = () => {
  const [loading, setLoading] = useState(false);
  const [banks, setBanks] = useState([] as BankType[]);
  const [selectedBank, setSelectedBank] = useState<BankType | null>(null);
  const [showModal, setShowModal] = useState(false);

  const getBanksStatus = async () => {
    setLoading(true);
    const res = await apiService.get('cs/get-bani-status', null);
    const response = res?.data?.bani;
    setBanks([
      ...response.banks,
      {
        name: 'access',
        fullname: BANK_NAMES?.ACCESS_BANK,
        active: response?.manual_access
      }
    ]);
    setLoading(false);
  };

  const toggleOnline = (bank: BankType) => {
    setSelectedBank(bank);
  };

  const toggleStatusModal = (noBankSelected?: boolean, withRes?: boolean) => {
    if (noBankSelected) {
      if (selectedBank) {
        selectedBank.active = !selectedBank?.active;
      }
      setSelectedBank(null);
    }

    if (withRes) getBanksStatus();
    setShowModal(!showModal);
  };

  const updateStatus = () => {
    toggleStatusModal();
    if (selectedBank) {
      selectedBank.active = !selectedBank?.active;
    }
  };

  useEffect(() => {
    if (selectedBank !== null) updateStatus();
  }, [selectedBank]);

  useEffect(() => {
    getBanksStatus();
  }, []);
  return (
    <div className='w-full'>
      {loading ? (
        <span className='text-[10px]'>Loading...</span>
      ) : (
        <div className='space-y-12'>
          <SettingHeaderCard
            details='Easily manage availability on the foodcourt App'
            icon={<BrandsIcon />}
            title='Manage Banks  '
          />
          <div className='flex flex-wrap justify-start gap-7'>
            {banks?.map((bank, i) => (
              <BankCard
                key={i}
                active={bank?.active}
                onChange={() => toggleOnline(bank)}
                type={bank?.name == 'access' ? 'manual' : 'bani'}
                name={bank?.fullname}
                bankLogo={getBankLogo(bank.fullname)}
              />
            ))}
          </div>
        </div>
      )}

      <ToggleStatusModal
        show={showModal}
        bank={selectedBank as BankType}
        onClose={toggleStatusModal}
      />
    </div>
  );
};

export default Banks;
