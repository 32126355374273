import React, { useContext, useEffect, useState } from 'react';
import { Button, ToastMessage } from '@cokitchen/cokitchen-components';
import { apiService } from '../../../services/base.service';
import { CreateOrderContext } from '../contexts/create-order-context';
import { allMealsTotal, getPayload, getPriceDifference } from '../functions';
import OrderTotal, { TotalArrType } from './order-total';
import PaymentMethod from './payment-method';
import Loader from '../../../components/base/loader';
import { useLocation, useParams } from 'react-router-dom';
import { CardIcon } from 'cokitchen-icons';
import { BasketContext } from '../contexts/basket-context';
import { toast } from 'react-toastify';

type CalculatedOrderType = {
  id: string;
  amount: string;
  prev_price: string;
};

const BasketFooter = () => {
  const { formData, addressDetails, editForm, type, resetTabs } = useContext(CreateOrderContext);
  const [totalAmt, setTotalAmount] = useState(0);
  const [calculatedOrder, setCalculatedOrder] = useState({} as CalculatedOrderType);
  const { setCardArr, setUser, paymentType, cardId } = useContext(BasketContext);
  const arr: TotalArrType[] = [
    {
      name: 'Subtotal',
      price: 0
    }
  ];
  const [step, setStep] = useState(0);
  const [paymentArr, setPaymentArr] = useState([...arr]);
  const [loading, setLoading] = useState(false);

  const updatePrice = () => {
    paymentArr[0].price = allMealsTotal(formData?.selectedMeals);
    paymentArr[0].editVal = getPriceDifference(formData?.selectedMeals, editForm);

    setPaymentArr([...paymentArr]);
  };
  const { id } = useParams();
  const { state: order } = useLocation();
  const createMealTotal = async (payload: any) => {
    try {
      const res = await apiService.post(`cs/user/one/${id}/order/calculate`, payload, true, true);
      const response = res?.data?.calculated_order;
      setCalculatedOrder({
        id: response?.id,
        amount: response?.total_amount,
        prev_price: response?.prev_price
      });

      setPaymentArr([
        {
          name: 'Subtotal',
          price: paymentArr[0]?.price
        },
        {
          name: 'Delivery fee',
          price: Number(response?.delivery_fee).toFixed(2)
        },
        {
          name: 'Delivery service fee',
          price: response?.delivery_service_charge
        },
        {
          name: 'Service fee',
          price: response?.service_charge
        }
      ]);
      setTotalAmount(response?.total_amount);
      setStep(1);
    } catch (err) {
      console.log(err);
    }
  };

  const existingMealTotal = async (payload: any) => {
    const data = {
      ...payload,
      calculated_order_id: order?.calculated_order?.id
    };
    try {
      const res = await apiService.post(`cs/user/one/${id}/order/recalculate`, data, true, true);
      const response = res?.data?.calculated_order;
      const total = response?.total_amount;
      const prev_price = res?.data?.prev_price;

      setCalculatedOrder({
        id: response?.id,
        amount: response?.total_amount,
        prev_price: response?.prev_price
      });

      setPaymentArr([
        ...paymentArr,
        {
          name: 'Delivery fee',
          price: response?.delivery_fee
        },
        {
          name: 'Delivery service fee',
          price: response?.delivery_service_charge
        },
        {
          name: 'Service fee',
          price: response?.service_charge
        }
      ]);
      setTotalAmount(total);
      if (prev_price > total) {
        setStep(2);
      } else {
        setStep(1);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getCards = async () => {
    const res = await apiService.get(`cs/user/one/${id}/cards`, {});
    const response = res?.data?.data;
    const arr = response?.map((card: any) => ({
      id: card?.id,
      title: `*** ${card.last_four_digit}`,
      icon: <CardIcon />
    }));
    setCardArr(arr);
  };

  const getUser = async () => {
    console.log('BasketFooter', id);
    const res = await apiService.get(`user/one/${id}`, null);
    setUser(res?.data?.user);
  };

  const getMealTotal = async () => {
    setLoading(true);
    const payload = getPayload(formData, addressDetails);
    if (type === 'create') {
      await createMealTotal(payload);
    } else {
      await existingMealTotal(payload);
    }
    await getUser();
    setLoading(false);
  };

  const handleConfirmClick = async () => {
    if (step === 0) {
      await getMealTotal();
    } else {
      handlePayment();
    }
  };

  const handleCancelClick = async () => {
    if (step > 0) setStep(0);
  };

  const cardPayment = async (user_id: string) => {
    const res = await apiService.post(`cs/user/one/${id}/order/pay/exisiting-card`, {
      card_id: cardId,
      order_id: user_id
    });
    return res;
  };

  const creditPayment = async () => {
    const payload = {
      owe: String(calculatedOrder?.amount),
      reason: 'Paid for an order on credit',
      user_id: id
    };
    const res = await apiService.post(`admin/user/owe`, payload);
    return res;
  };

  const walletPayment = async () => {
    const payload = {
      amount: String(calculatedOrder?.amount),
      email: order,
      reason: 'Paid for an order',
      user_id: id
    };
    const res = await apiService.post('user/wallet/subtract', payload);
    return res;
  };

  const createOrder = async () => {
    const payload = {
      calculated_order_id: calculatedOrder?.id,
      order_details: {
        phone_number: addressDetails?.phoneNo,
        rider_note: 'test',
        cutlery: true
      }
    };
    const orderRes = await apiService.post(`cs/user/one/${id}/order/create`, payload);

    return orderRes?.data;
  };

  const handlePayment = async () => {
    // create order
    const orderRes = await createOrder();
    if (orderRes) {
      let res;
      if (paymentType === 'card') {
        res = await cardPayment(orderRes?.order?.id);
      } else if (paymentType === 'credit') {
        res = await creditPayment();
      } else if (paymentType === 'wallet') {
        res = await walletPayment();
      }

      if (res?.data || paymentType === 'transfer') {
        setStep(2);
      }
    }
  };

  useEffect(() => {
    updatePrice();
  }, [formData]);

  useEffect(() => {
    getCards();
  }, []);

  useEffect(() => {
    if (step == 2) {
      toast(
        <ToastMessage
          type='success'
          message={`Order ${type === 'create' ? 'Created' : 'Updated'} Successfully`}
        />
      );
      resetTabs();
    }
  }, [step]);

  return (
    <div className='px-4 h-[50%] text-gray-4 font-medium no-scroll-bar no-wrap'>
      <OrderTotal total={totalAmt} arr={paymentArr} />
      {step !== 2 ? (
        <>
          {loading && <Loader />}
          {step === 1 && <PaymentMethod />}
          <div className='flex -mx-4 px-4 my-12 pt-6 justify-center gap-x-4 border-t border-[#E2E7EE]'>
            <Button small color={'primary'} variant='outline' onClick={handleCancelClick}>
              Cancel
            </Button>
            <Button
              small
              disabled={
                (step == 1 && !paymentType) ||
                (step == 0 && Object.values(formData?.selectedMeals).length === 0)
              }
              color={'primary'}
              variant='block'
              onClick={handleConfirmClick}>
              {step === 0 ? 'Charge' : 'Confirm'}
            </Button>
          </div>
        </>
      ) : (
        <p className='text-center pt-6'>Order Has Been Created</p>
      )}
    </div>
  );
};

export default BasketFooter;
