import React, { FC, useEffect, useState } from 'react';
import { apiService } from '../../../services/base.service';
import { CoKitchenPolygons, CoKitchenType, LiveTripOrderType } from '../../../utils/types';
import { ExtendedFilterType } from '../types';
import { SocketIOConnection } from '../../../utils/socket_client';
import { ALL_POLYGONS_ID, COKITCHEN_LOCATIONS, STORAGE_KEYS } from '../../../utils/constants';
import { NumberOfPolygonItems, StatusFilterConfig } from './filter-config';
import LiveTripCards from '../../../components/live-trips/live-trip-cards';
import { PolygonsTab } from '../../../components/base/polygons-tab';
import { StatusTabs } from '../live-trips-header/status-tabs';
import { Dropdown, DropdownItem, DropdownList } from '@cokitchen/cokitchen-components';
import './styles.scss';
import CokitchenSelector from '../../../components/base/cokitchen-selector';
import { MailIcon, OrdersIcon, PhoneIcon, RiderAltIcon, UserIcon } from 'cokitchen-icons';

const getOrderLatestTime = (order: LiveTripOrderType): number => {
  const times = [order.updated_at];
  const maxTime: Date = times.reduce((maxTime: Date, timeString: string | null): Date => {
    if (timeString === null) return maxTime;
    const time = new Date(timeString);
    return time > maxTime ? time : maxTime;
  }, new Date(0));
  return Number(maxTime);
};

const LiveTripsLiveView: FC = () => {
  const filterByOrderCode = () => {
    const searchValuetoLowerCase = searchValue.toLowerCase();
    const filtered = polygonOrders.filter(({ order_code }) =>
      order_code.toLowerCase()?.includes(searchValuetoLowerCase)
    );
    setFilteredOrders(filtered);
  };

  const filterByUserName = () => {
    const searchValuetoLowerCase = searchValue.toLowerCase();
    const filtered = polygonOrders.filter(({ calculated_order }) =>
      (calculated_order?.user?.first_name + ' ' + calculated_order?.user?.last_name)
        .toLowerCase()
        ?.includes(searchValuetoLowerCase)
    );
    setFilteredOrders(filtered);
  };

  const filterByRiderName = () => {
    const searchValuetoLowerCase = searchValue.toLowerCase();
    const filtered = polygonOrders.filter(({ rider }) =>
      (rider?.first_name + ' ' + rider?.last_name).toLowerCase()?.includes(searchValuetoLowerCase)
    );
    setFilteredOrders(filtered);
  };

  const filterByUserPhone = () => {
    const searchValuetoLowerCase = searchValue.toLowerCase();
    const filtered = polygonOrders.filter(({ calculated_order }) =>
      calculated_order?.user?.phone_number.toLowerCase()?.includes(searchValuetoLowerCase)
    );
    setFilteredOrders(filtered);
  };

  const filterByUserEmail = () => {
    const searchValuetoLowerCase = searchValue.toLowerCase();
    const filtered = polygonOrders.filter(({ calculated_order }) =>
      calculated_order?.user?.email.toLowerCase()?.includes(searchValuetoLowerCase)
    );
    setFilteredOrders(filtered);
  };

  const SEARCH_DROPDOWN = {
    'Order code': filterByOrderCode,
    'User name': filterByUserName,
    'Rider name': filterByRiderName,
    'User phone': filterByUserPhone,
    'User email': filterByUserEmail
  };

  const [filter, setActiveFilter] = useState<ExtendedFilterType>('All');
  const [filters, setFilters] = useState({
    activeKitchen: {} as CoKitchenType
  });
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<LiveTripOrderType[]>([]);
  const [polygonOrders, setPolygonOrders] = useState<LiveTripOrderType[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<LiveTripOrderType[]>([]);
  const [activeCokitchen, setActiveCokitchen] = useState({} as CoKitchenType);
  const [activePolygon, setActivePolygon] = useState({} as CoKitchenPolygons);
  const [ordersDict, setOrdersDict] = useState({});
  const [downloadingInitData, setDownloadingInitData] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [numberOfItems, setNumberOfItems] = useState<Record<ExtendedFilterType, number>>();
  const [searchType, setSearchType] = useState<keyof typeof SEARCH_DROPDOWN>('Order code');
  const [showSearchTypeDropdown, setShowSearchTypeDropdown] = useState(false);

  const initPage = async (connection: SocketIOConnection) => {
    connection.joinRoom('order-update', process.env.REACT_APP_WEBOCKETAPP_KEY);
    try {
      setLoading(true);
      setDownloadingInitData(true);
      const resOrders = await getAllLiveTrips();
      resOrders.map(handleUpdatedOrder);
    } finally {
      setDownloadingInitData(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (downloadingInitData) return;
    setOrders(
      Object.values(ordersDict)
        .filter((order: any) => !order.del)
        .sort((a: any, b: any) => getOrderLatestTime(b) - getOrderLatestTime(a)) as any
    );
  }, [ordersDict, downloadingInitData]);

  const updateOrdersDataDict = (order: any) => {
    setOrdersDict((prevState: any) => {
      const prevOrder = prevState[order.id];
      const prevOrderTime = prevOrder ? new Date(prevOrder.updated_at) : new Date(0);
      const newOrderDataTime = new Date(order.updated_at);
      return {
        ...prevState,
        [order.id]: newOrderDataTime >= prevOrderTime ? order : prevOrder
      };
    });
  };

  const removeOrderIfExist = (order: LiveTripOrderType) => {
    updateOrdersDataDict({
      id: order.id,
      del: true,
      updated_at: String(new Date())
    });
  };

  const checkOrderValidity = (order: LiveTripOrderType) => {
    return !(order.cancelled || order.completed);
  };

  const handleUpdatedOrder = (order: LiveTripOrderType) => {
    const isValidOrder = checkOrderValidity(order);
    if (isValidOrder) {
      updateOrdersDataDict(order);
    } else {
      removeOrderIfExist(order);
    }
  };

  const connectSocket = async () => {
    const connection = await SocketIOConnection.getConnection();
    connection.onReconnect(() => initPage(connection));
    connection.joinRoom('order-update', process.env.REACT_APP_WEBOCKETAPP_KEY);
    connection.addEventListener('order-update', 'updated', (order: LiveTripOrderType) => {
      handleUpdatedOrder(order);
    });
    connection.addEventListener('order-update', 'removed', (order: LiveTripOrderType) => {
      removeOrderIfExist(order);
    });
    initPage(connection);
  };

  const getAllLiveTrips = async () => {
    const res = await apiService.get('cs/all/trips', { page: 1 });
    return res.data.results;
  };

  useEffect(() => {
    setDefaultLocation();
    connectSocket();
  }, []);

  const cokitchenArray: CoKitchenType[] = JSON.parse(
    localStorage.getItem(STORAGE_KEYS.COKITCHEN_LIST) || '[]'
  );

  const setDefaultLocation = () => {
    const lekki =
      cokitchenArray.find(({ name }) => name === COKITCHEN_LOCATIONS.LEKKI) ?? cokitchenArray[0];
    setActiveCokitchen(lekki || {});
  };

  useEffect(() => {
    const filtered = StatusFilterConfig(orders, filter);
    const allFilters =
      activePolygon.id === ALL_POLYGONS_ID
        ? filtered
        : filtered.filter(
            (order) => order.calculated_order.cokitchen_polygon_id === activePolygon.id
          );

    setPolygonOrders(allFilters);
    setNumberOfItems(NumberOfPolygonItems(orders, activePolygon.id));
  }, [filter, orders, activePolygon]);

  useEffect(() => {
    setSearchValue('');
  }, [filter]);

  useEffect(() => {
    if (!searchValue) {
      setFilteredOrders(polygonOrders);
      return;
    }
    const fn = SEARCH_DROPDOWN[searchType];
    fn();
  }, [searchValue, searchType, polygonOrders]);

  const getSearchTypeIcon = (key?: keyof typeof SEARCH_DROPDOWN) => {
    const iconMapping: Record<keyof typeof SEARCH_DROPDOWN, any> = {
      'Order code': <OrdersIcon />,
      'Rider name': <RiderAltIcon />,
      'User email': <MailIcon />,
      'User name': <UserIcon />,
      'User phone': <PhoneIcon />
    };
    return key ? iconMapping[key] : iconMapping[searchType];
  };

  return (
    <div key={activeCokitchen.id} className='flex flex-col gap-5 flex-wrap'>
      <div className='flex justify-between '>
        <h1 className='font-semibold text-2xl'>Live on-going trips ({orders.length})</h1>
        {
          <CokitchenSelector
            label='Select Location'
            activeKitchen={filters.activeKitchen}
            setActiveKitchen={(k) => setFilters({ ...filters, activeKitchen: k })}
          />
        }
      </div>
      <div className='w-full'>
        <div className='flex justify-between items-center gap-4'>
          <PolygonsTab
            className='font-medium w-full'
            activeKitchen={filters.activeKitchen}
            activePolygon={activePolygon}
            setActivePolygon={setActivePolygon}
            hasAll
            hideHr
          />
        </div>
        <hr className='text-[#DCE2EA]' />
      </div>

      <div className='flex justify-between gap-4 items-center'>
        <StatusTabs
          filter={filter}
          setActiveFilter={setActiveFilter}
          numberOfItems={numberOfItems}
        />
        <div className='live-trip-search__wrapper'>
          <input
            type='search'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            placeholder={`Search ${searchType.toLowerCase()}`}
            className='live-trip-search'
          />
          <Dropdown
            visible={showSearchTypeDropdown}
            content={
              <div
                onClick={() => setShowSearchTypeDropdown(!showSearchTypeDropdown)}
                className='text-gray-8 bg-gray-border cursor-pointer rounded-lg p-1.5'>
                {getSearchTypeIcon()}
              </div>
            }>
            <DropdownList>
              {Object.keys(SEARCH_DROPDOWN).map((key: any) => (
                <DropdownItem
                  onClick={() => {
                    setSearchType(key);
                    setShowSearchTypeDropdown(false);
                  }}
                  buttonClassName='gap-2 py-2'
                  key={key}>
                  <span className='text-gray-8'>{getSearchTypeIcon(key)}</span>
                  {key}
                </DropdownItem>
              ))}
            </DropdownList>
          </Dropdown>
        </div>
      </div>
      {loading && orders?.length === 0 ? (
        <h2 className='font-bold text-center md:h-[200px] flex items-center mx-auto text-lg md:text-2xl'>
          Loading...
        </h2>
      ) : filteredOrders.length === 0 ? (
        <h2 className='font-bold text-center md:h-[200px] flex items-center mx-auto text-lg md:text-2xl'>
          There are no {filter === 'All' ? '' : filter} orders to show
          {activePolygon.id !== ALL_POLYGONS_ID ? ` for ${activePolygon.name}` : ''}{' '}
          {searchValue ? `with ${searchType.toLowerCase()} ${searchValue}` : ''}
        </h2>
      ) : (
        <LiveTripCards orders={filteredOrders} />
      )}
    </div>
  );
};

export default LiveTripsLiveView;
