import React from 'react';
import DashboardLayout from '../../layouts/dashboard.layout';
import LiveTripsLiveView from './live-view';

const LiveTrips = () => {
  return (
    <DashboardLayout>
      <LiveTripsLiveView />
    </DashboardLayout>
  );
};

export default LiveTrips;
