import React, { FC } from 'react';

type RiderDetailsHeaderType = {
  date: number | string;
};

const RiderDetailsHeader: FC<RiderDetailsHeaderType> = ({ date }) => {
  return (
    <div className='text-sm  text-gray-6 font-medium  px-6 pb-3 border-b border-gray-border'>
      <div className='border-left flex gap-4 items-center'>
        <div className='bg-[#0EA3FD] w-[4px] h-8'></div>
        <div>
          <h6>Rider personal details</h6>
          <p className='text-xs text-gray-8'>Joined {date}</p>
        </div>
      </div>
    </div>
  );
};

export default RiderDetailsHeader;
