import React, { useEffect, useState } from 'react';
import { apiService } from '../../../../services/base.service';
import { OrderType, UserType } from '../../../../utils/types';
import UserOrderCard from '../../../base/cards/user-order-card';
import Loader from '../../../base/loader';
import { Paginate } from '@cokitchen/cokitchen-components';

const UserOrders = ({ user }: { user: UserType }) => {
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([] as OrderType[]);

  const loadOrders = async (pageNo = 1) => {
    setLoading(true);
    const { id: user_id } = user;
    const res = await apiService.post(`user/one/orders/${pageNo}`, {
      user_id
    });
    const response = res?.data;
    const data = response?.data;

    const pageCount = Math.ceil(response?.total / 10);
    setPageCount(pageCount);
    setOrders(data);
    setLoading(false);
  };

  const getSelectedPage = (val: { selected: number }) => {
    const pageNo = val?.selected + 1;
    loadOrders(pageNo);
  };

  useEffect(() => {
    loadOrders();
  }, []);

  return (
    <div className='pt-8 pb-3 space-y-6'>
      {loading ? (
        <Loader />
      ) : orders.length === 0 ? (
        <div className={`flex items-center justify-center h-full  font-semibold`}>
          No Orders To Show
        </div>
      ) : (
        <div className='grid lg:grid-cols-2 gap-6'>
          {orders?.map((order) => (
            <UserOrderCard key={order.id} item={order} />
          ))}
        </div>
      )}
      <Paginate onPageChange={getSelectedPage} pageCount={pageCount} />
    </div>
  );
};

export default UserOrders;
