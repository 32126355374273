import React from 'react';
import DetailsCard from '../../../../components/base/cards/details-card';
import { AddonCategoryType, AddonMealType, AddonType } from '../../../../utils/types';
import { doesAddonExist } from '../../functions';

const MultiSelectionWithQuantityAddon = ({
  filteredAddOn,
  addonSelection,
  onAddClick,
  onSubtractClick
}: {
  filteredAddOn: AddonCategoryType[];
  handleSelectAddon: (addon: AddonType, item: AddonCategoryType) => void;
  onAddClick: (item: AddonType) => void;
  onSubtractClick: (item: AddonType) => void;
  addonSelection: AddonMealType;
}) => {
  return (
    <div>
      {filteredAddOn[0]?.meal_category_selection_type.name === 'MULTI_SELECTION_WITH_QUANTITY' && (
        <div className=' space-y-6'>
          {filteredAddOn[0].summary && (
            <p className='pb-4 font-medium'>* {filteredAddOn[0].summary}</p>
          )}
          <div className='grid md:grid-cols-2 lg:grid-cols-3 justify-between gap-4 gap-y-6'>
            {filteredAddOn[0]?.addons.map((addon, i) => (
              <DetailsCard
                cardType='MULTI_SELECTION_WITH_QUANTITY'
                active={doesAddonExist(addon.meal_addon_id, addonSelection)}
                title={addon.meal_data.name}
                key={i}
                price={addon.meal_data.amount}
                quantity={
                  addonSelection[addon.meal_addon_id] &&
                  addonSelection[addon.meal_addon_id]?.quantity
                }
                onAddClick={() => onAddClick(addon)}
                onSubtractClick={() => onSubtractClick(addon)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiSelectionWithQuantityAddon;
