import React from 'react';
import { ReactComponent as FoodCourtLogo } from '../../assets/logo.svg';
import { ROUTE_KEYS, STORAGE_KEYS } from '../../utils/constants';
import { NavLink } from 'react-router-dom';
import { ArrowDownIcon, NoteIcon, SearchIcon } from 'cokitchen-icons';
import { ClockIcon } from 'cokitchen-icons';
import { RecordPaymentIcon } from 'cokitchen-icons';
import HeaderDropdown from './header-dropdown';
import { Dropdown } from '@cokitchen/cokitchen-components';

const Header = () => {
  const tabs = [
    {
      name: 'Pending tasks',
      icon: <ClockIcon className='w-4 h-4' />,
      path: ROUTE_KEYS.PENDING_TASKS
    },
    {
      name: 'Live Trips',
      icon: <NoteIcon className='w-4 h-4' />,
      path: ROUTE_KEYS.LIVE_TRIPS
    },
    {
      name: 'Search',
      icon: <SearchIcon fill='none' className='w-4 h-4' />,
      path: ROUTE_KEYS.SEARCH
    },

    {
      name: 'Reviews',
      icon: <RecordPaymentIcon fill='none' className='w-4 h-4' />,
      path: ROUTE_KEYS.REVIEWS
    }
  ];
  const navStyles = {
    active: 'flex mx-[15px] border-b-[3.5px] text-red-4 cursor-pointer py-5 border-b-[#E60A2B]',
    inactive:
      'flex mx-[15px] border-b-[3.5px] text-[#858585] cursor-pointer py-5 border-b-transparent'
  };

  const firstName = localStorage.getItem(STORAGE_KEYS.FIRST_NAME) ?? 'Customer';
  const lastName = localStorage.getItem(STORAGE_KEYS.LAST_NAME) ?? 'Service';
  const initials = `${firstName[0]}${lastName[0]}`;
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  return (
    <header className='border-b border-gray-border px-6 sticky top-0 bg-white z-10'>
      <div className='container mx-auto flex justify-between items-center'>
        <NavLink to={ROUTE_KEYS.PENDING_TASKS}>
          <FoodCourtLogo />
        </NavLink>
        <div className='flex justify-start'>
          {tabs.map(({ path, icon, name }, index) => (
            <NavLink
              to={path}
              key={index}
              className={({ isActive }) => (isActive ? navStyles.active : navStyles.inactive)}>
              <span className='pr-[6px] pt-1'>{icon}</span>
              {name}
            </NavLink>
          ))}
        </div>
        <Dropdown
          content={
            <div className='flex gap-[.6rem] items-center'>
              <div
                className='border-[1px] relative w-10 h-10
               rounded-[100%] flex items-center justify-center  border-red-2'>
                <div
                  className='text-sm  font-medium  flex justify-center uppercase items-center rounded-[100%] w-[96%] h-[96%]
                  text-red-5 bg-red-1  border-red-2'>
                  {initials}
                </div>
              </div>
              <div className=' flex items-center gap-1'>
                <div className='hidden sm:flex flex-col gap-[1px]'>
                  <h4 className='text-base font-medium uppercase mb-0'>
                    {firstName} {lastName}
                  </h4>
                  <span className='text-[12px] text-gray-5'>{role}</span>
                </div>
                <div className='rounded-full text-gray-5 cursor-pointer hover:bg-gray-1 transition-all'>
                  <ArrowDownIcon fill='none' className='w-5 h-5' />
                </div>
              </div>
            </div>
          }>
          <HeaderDropdown />
        </Dropdown>
      </div>
    </header>
  );
};

export default Header;
