import React from 'react';

import styles from '../index.module.css';
import TrackOrderState from './track-order-state';
import { OrderType } from '../../../utils/types';

type Props = {
  order: OrderType;
  expand?: boolean;
};

const TrackOrderStatesGroup = ({ order, expand }: Props) => {
  return (
    <div>
      <div className={styles['track-states-container']}>
        <TrackOrderState
          time={order.created_at}
          timeSince={order.kitchen_verified_time}
          start={true}
          text='Order Created'
          innerText='Waiting for kitchen to confirm order'>
          <></>
        </TrackOrderState>

        <TrackOrderState
          time={order.kitchen_verified_time}
          timeSince={order.kitchen_dispatched_time}
          start={true}
          text='Order Received'
          innerText='Waiting for kitchen to confirm order'>
          <>
            {expand && (
              <>
                <TrackOrderState
                  hideMark={true}
                  time={order.kitchen_verified_time}
                  timeSince={order.kitchen_verified_time}
                  text='Order Received'
                />
                <TrackOrderState
                  hideMark={true}
                  time={order.kitchen_verified_time}
                  timeSince={order.kitchen_completed_time}
                  text='Order Started'
                />
                <TrackOrderState
                  hideMark={true}
                  time={order.kitchen_completed_time}
                  timeSince={order.kitchen_dispatched_time}
                  text='Order Prepared'
                />
              </>
            )}
          </>
        </TrackOrderState>

        <TrackOrderState
          time={order.kitchen_dispatched_time}
          timeSince={order.completed_time}
          text='Order dispatched'
          innerText='Rider is enroute'
        />
        <TrackOrderState
          time={order.completed_time}
          timeSince={null}
          end={true}
          text='Order delivered'
          innerText={
            order.completed_time ? 'User has received meal' : 'User is yet to receive meal'
          }
        />
      </div>
    </div>
  );
};

export default TrackOrderStatesGroup;
