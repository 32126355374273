import React, { FC } from 'react';
import PersonalDetailsHeader from './personal-details-header';
import PersonalDetailsBody from './personal-details-body';
import { OrderType } from '../../../utils/types';
import moment from 'moment';

type PersonalDetailsType = {
  order: OrderType;
};
const PersonalDetails: FC<PersonalDetailsType> = ({ order }) => {
  const dateJoined =
    order?.calculated_order?.user?.created_at &&
    moment(order.calculated_order.user.created_at).format('Do, MMM YYYY');
  return (
    <div className='flex flex-col rounded-[8px] border border-gray-border pt-6 pb-1 relative'>
      <PersonalDetailsHeader date={dateJoined} />
      <PersonalDetailsBody order={order} />
    </div>
  );
};

export default PersonalDetails;
