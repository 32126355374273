import React, { useEffect, useState } from 'react';
import LiveTripCardFooter from './live-trip-card-footer';
import LiveTripCardHeader from './live-trip-card-header';
import LiveTripCardDetails from './live-trip-card-details';
import { LiveTripOrderType } from '../../../utils/types';
import './styles.scss';
import { LiveCardMenuFunctionType } from './menu-config';
import { useSearchParams } from 'react-router-dom';
import OrderDetail from '../../../pages/order-details';
import { getLiveTripOrderStatus } from '../../../utils/functions';
import { SideModal } from '@cokitchen/cokitchen-components';
import LiveTripModals from '../live-trip-modals';

type Props = {
  orders: LiveTripOrderType[];
  modalView?: boolean;
};
const LiveTripCards = ({ orders, modalView }: Props) => {
  const [selectedOrder, setSelectedOrder] = useState({} as LiveTripOrderType);
  const [selectedFnTitle, setSelectedFnTitle] = useState('' as LiveCardMenuFunctionType);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeOrder, setActiveOrder] = useState<LiveTripOrderType>();
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [sortedOrders, setSortedOrders] = useState<LiveTripOrderType[]>([]);

  useEffect(() => {
    const sorted = orders.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
    setSortedOrders(sorted);
  }, [orders]);

  useEffect(() => {
    setShowOrderModal(!!activeOrder);
    if (activeOrder) {
      searchParams.set('order', activeOrder.order_code);
      searchParams.set('orderStatus', getLiveTripOrderStatus(activeOrder));
    } else {
      if (searchParams.has('order')) {
        searchParams.delete('order');
        searchParams.delete('orderStatus');
      }
    }
    setSearchParams(searchParams);
  }, [activeOrder]);

  const handleSelectMenu = (menuItem: LiveCardMenuFunctionType, order: LiveTripOrderType) => {
    setSelectedOrder(order);
    setSelectedFnTitle(menuItem);
  };

  const closeOrderModal = () => {
    setActiveOrder(undefined);
    setSelectedFnTitle('' as LiveCardMenuFunctionType);
  };

  return (
    <div
      className={`grid ${!modalView ? 'md:grid-cols-2 lg:grid-cols-3' : 'lg:grid-cols-2'}  gap-4`}>
      {sortedOrders.map((order) => (
        <div key={order.id} className='cursor-pointer live-trip-card relative'>
          <LiveTripCardHeader
            order={order}
            onSelectMenu={(menu) => handleSelectMenu(menu, order)}
          />
          <LiveTripCardDetails orderDetails={order} />
          <LiveTripCardFooter
            order={order}
            onClick={() => {
              setActiveOrder(order);
            }}
          />
        </div>
      ))}

      <SideModal
        isOpen={showOrderModal}
        onRequestClose={closeOrderModal}
        size='full-width'
        title={''}
        hideFooter>
        {activeOrder && (
          <OrderDetail liveTripOrderCode={activeOrder?.order_code} headerClass='hidden' />
        )}
      </SideModal>

      <LiveTripModals
        selectedFnTitle={selectedFnTitle}
        selectedOrder={selectedOrder}
        closeOrderModal={closeOrderModal}
      />
    </div>
  );
};

export default LiveTripCards;
