import React, { ReactNode } from 'react';

const CardWithHeader = ({ title, children }: { title: string; children: ReactNode }) => {
  return (
    <div className='min-h-[100%]'>
      <div className='border py-3 border-gray-border rounded-lg max-w-[240px] h-full'>
        <p className='uppercase tracking-[2px] font-medium text-xs text-gray-4 border-b border-gray-border pb-3 pl-5'>
          {title}
        </p>
        <div className='pl-5 pt-3 text-sm text-gray-6 font-normal space-y-1 grow'>{children}</div>
      </div>
    </div>
  );
};

export default CardWithHeader;
