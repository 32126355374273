import React, { FC } from 'react';
import { ReactComponent as UserIcon } from '../../../assets/icons/user.svg';
import { ReactComponent as RiderIcon } from '../../../assets/icons/motorbike.svg';
import { ReactComponent as CurrencyIcon } from '../../../assets/icons/coin.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg';
import OrderTag from '../../base/order-tag';
import { LiveTripOrderType } from '../../../utils/types';
import { formatNumber } from '../../../utils/functions';
type LiveTripCardDetailsProps = {
  orderDetails: LiveTripOrderType;
};
const LiveTripDetails: FC<LiveTripCardDetailsProps> = ({ orderDetails }) => {
  const { order_code } = orderDetails;
  const surge =
    orderDetails.surge?.logistics_surge && orderDetails.surge?.logistics_surge !== '1.0';
  const late = orderDetails.kitchen_time_elapsed;
  const name =
    orderDetails?.calculated_order?.user?.first_name ||
    orderDetails?.user?.first_name + ' ' + orderDetails?.user?.last_name ||
    orderDetails?.calculated_order?.user?.last_name;

  const rider_name = orderDetails.rider
    ? `${orderDetails.rider.first_name} ${orderDetails.rider.last_name}`
    : 'nil';
  const delivery_fee = orderDetails?.calculated_order?.delivery_fee;
  const address_details = orderDetails?.calculated_order?.address_details.address_line;
  return (
    <div className='px-4 py-3 space-y-2 grow'>
      <div className='flex justify-between items-center text-sm gap-10'>
        <div className='flex items-center gap-2 flex-wrap'>
          <h4 className='font-medium  text-base text-gray-6'>#{order_code?.toUpperCase()}</h4>
          {surge && <OrderTag type='surge' />}
          {late && <OrderTag type='late' />}
        </div>

        <p className='flex min-w-fit items-center gap-2'>
          <UserIcon />
          {name}
        </p>
      </div>
      <div className='flex justify-between items-center text-sm'>
        <p className='flex items-center gap-2'>
          <RiderIcon />
          {orderDetails?.rider ? (
            <span>{rider_name}</span>
          ) : (
            <span className='text-red-4'> No rider yet</span>
          )}
        </p>
        <p className='flex items-center gap-2'>
          <CurrencyIcon />
          {formatNumber(delivery_fee)}
        </p>
      </div>
      <p className='flex items-start gap-2'>
        <LocationIcon className='mt-[4px]' /> {address_details}
      </p>
    </div>
  );
};

export default LiveTripDetails;
