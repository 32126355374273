import React from 'react';
import { ListObjectType, ListType } from './types';
import { DropdownItem, DropdownList } from '@cokitchen/cokitchen-components';

const BaseDropdownList: React.FC<ListType> = ({ list, onSelect, className = '150px', order }) => {
  const hideCompleteTrip = (item: ListObjectType) => {
    if (order && item) {
      return !order?.pickup && item?.function === 'completeTrip';
    }
    return false;
  };

  return (
    <DropdownList className={className}>
      {list.map((item, index) => (
        <DropdownItem
          key={index}
          onClick={() => {
            item.onClick?.();
            onSelect?.(item);
          }}
          className={`${item.className} ${
            hideCompleteTrip(item) ? 'hidden' : ''
          } cursor-pointer flex text-[14px] font-medium items-center rounded`}>
          <span className='pr-4'>{item.icon}</span>
          {item.name}
        </DropdownItem>
      ))}
    </DropdownList>
  );
};

export default BaseDropdownList;
