import React, { useEffect, useState } from 'react';
import styles from '../index.module.css';
import { getAdmin, getKitchenById } from '../../../utils/functions';
import PickupOrder from '../../base/pickup-order';
import { LiveTripOrderType, OrderType } from '../../../utils/types';
import {
  DistanceIcon,
  PhoneIcon,
  DeliveryIcon,
  ClockIcon,
  AmountIcon,
  LocationIcon,
  EditAltIcon
} from 'cokitchen-icons';
import ChangeAddressModal from './change-address-modal';

type Props = {
  order: OrderType;
  addressModal: (withRes?: boolean) => void;
  show: boolean;
};

const heaviside = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  const R = 6371e3; // metres
  const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const φ2 = (lat2 * Math.PI) / 180;
  const Δφ = ((lat2 - lat1) * Math.PI) / 180;
  const Δλ = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
    Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c; // in metres
  return d;
};

const DeliveryDetail = ({ order, addressModal, show }: Props) => {
  const [distance, setDistance] = useState('0.000');
  const rider_note = order?.order_details?.rider_note;

  let duration = 'In progress';
  if (!order.calculated_order.pickup) {
    if (order.completed) {
      const start_time = new Date(order.kitchen_dispatched_time ?? 0);
      const end_time = new Date(order.completed_time ?? 0);
      const duration_in_millsec = Number(end_time) - Number(start_time);
      const duration_in_min = Math.floor(duration_in_millsec / 1000 / 60);
      duration = `${duration_in_min} mins`;
    }
  }

  const calculateDistance = async () => {
    if (order.calculated_order.pickup) return;
    const kitchen = await getKitchenById(order.calculated_order.cokitchen_id);
    const distance = heaviside(
      Number(order.calculated_order.lat),
      Number(order.calculated_order.lng),
      Number(kitchen.lat),
      Number(kitchen.lng)
    );
    setDistance((distance / 1000).toFixed(3));
  };

  const orderInProgress = !(order?.cancelled || order?.completed);
  const deliveryFee =
    Number(order.calculated_order.delivery_fee) +
    Number(order.calculated_order.delivery_service_charge);
  useEffect(() => {
    calculateDistance();
  }, []);

  return (
    <div className={`${styles['section']} text-gray-5`}>
      {order.calculated_order.pickup ? (
        <PickupOrder />
      ) : (
        <>
          <div className={`px-5 py-3 ${styles['section-header-2']}`}>
            <div className='px-3 text-gray-6'>Delivery Detail</div>
          </div>
          <div className='flex flex-col gap-4 py-4 px-5 '>
            <div className='flex'>
              <div className='grow flex items-center gap-x-2'>
                <LocationIcon />
                <div>{order.calculated_order.address_details.address_line}</div>
                <span></span>
              </div>

              {orderInProgress && getAdmin() && (
                <EditAltIcon className='cursor-pointer' onClick={() => addressModal()} />
              )}
            </div>

            <div className='flex items-center gap-x-2'>
              <PhoneIcon />
              <div className='grow'>{order.calculated_order.address_details.phone_number}</div>
              <span className='text-sm text-blue-5'>Recipient no.</span>
            </div>
            <div className='flex items-center gap-x-2'>
              <DeliveryIcon />
              <div className='grow'>Delivery fee: ₦{deliveryFee.toFixed(2)}</div>
              <span className='text-sm text-blue-5 font-medium'>{order?.order_type?.name}</span>
            </div>
            <div className='flex items-center gap-x-2'>
              <AmountIcon />
              <div>Amount paid by wallet: ₦{order.order_details.wallet_amount ?? 0}</div>
              <span></span>
            </div>
            <div className='flex items-center gap-x-2'>
              <ClockIcon />

              <div>Total trip time:{duration}</div>
              <span></span>
            </div>
            <div className='flex items-center gap-x-2'>
              <DistanceIcon />
              <div>Total trip distance: {distance} km</div>
              <span></span>
            </div>
            {rider_note && (
              <div>
                <div className={`${styles['delivery-note']} text-gray-6 text-sm p-3`}>
                  <span className='font-semibold'>NOTE: </span>
                  {rider_note}
                </div>
              </div>
            )}
          </div>
        </>
      )}

      {show && <ChangeAddressModal onClose={addressModal} order={order as LiveTripOrderType} />}
    </div>
  );
};

export default DeliveryDetail;
