import React, { useState } from 'react';
import styles from '../index.module.css';
import TrackOrderStatesGroup from './track-order-states-group';
import { OrderType } from '../../../utils/types';
import { SideModal } from '@cokitchen/cokitchen-components';

type Props = {
  order: OrderType;
};
const TrackOrder = ({ order }: Props) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div className={styles['section']}>
      <div className={`px-5 py-3 ${styles['section-header-2']}`}>
        <div className='px-3'>Track Order(30 mins)</div>
        <button className='text-sm text-red-8' onClick={() => setShowModal(true)}>
          View details
        </button>
      </div>
      <div className='px-5'>
        <TrackOrderStatesGroup order={order} />
      </div>
      <SideModal
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
        title='Track Order'
        hideFooter>
        <div>
          <TrackOrderStatesGroup expand={true} order={order} />
        </div>
      </SideModal>
    </div>
  );
};

export default TrackOrder;
