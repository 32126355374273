import React from 'react';
import PickupOrder from '../../base/pickup-order';
import RiderDetailsBody from './rider-details-body';
import RiderDetailsHeader from './rider-details-header';
import moment from 'moment';
import NoOrderYet from '../../base/no-order-yet';
import { OrderType } from '../../../utils/types';

type Props = {
  order: OrderType;
};
const RiderDetails = ({ order }: Props) => {
  if (order.calculated_order.pickup) return <PickupOrder />;
  if (!order.rider) return <NoOrderYet />;
  const rider = {
    rider_name: order.rider.first_name + ' ' + order.rider.last_name,
    rider_phone: order.rider.phone_number,
    rider_email: order.rider.email,
    ...order.rider,
    rider_id: order?.rider?.id
  };
  return (
    <div className='flex flex-col rounded-[8px] border border-gray-border pt-6 pb-1 relative'>
      <RiderDetailsHeader date={moment(order.rider.created_at).format('MMM DD, YYYY')} />
      <RiderDetailsBody details={rider} />
    </div>
  );
};

export default RiderDetails;
