import React, { useContext, useState } from 'react';
import { LocationIcon, EditAltIcon, DeleteIcon } from 'cokitchen-icons';
import AddressModal from '../../../components/modals/user-profile-modal/address-modal';
import { BaseModal, Button } from '@cokitchen/cokitchen-components';
import { CreateOrderContext } from '../contexts/create-order-context';
import { CreatOrderForm } from '../../../utils/constants';
import { getTotalMeals } from '../functions';
import { isEqual } from 'lodash';

const BasketHeader = () => {
  const { formData, addressDetails, setAddressDetails, setFormData, resetTabs, type, editForm } =
    useContext(CreateOrderContext);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const handleRemove = () => {
    if (type == 'create') {
      setShowRemoveModal(true);
    } else {
      undoChanges();
    }
  };
  const closeModal = () => setShowRemoveModal(false);

  const handleRemoveMeals = () => {
    resetTabs();
    setFormData(CreatOrderForm);
    closeModal();
  };

  const undoChanges = () => {
    const editFormCopy = JSON.parse(JSON.stringify(editForm));
    setFormData({ ...formData, selectedMeals: editFormCopy, deletedMeals: {} });
  };
  const selectedMeals = formData?.selectedMeals;
  const equalMeal = () => {
    return isEqual(formData.selectedMeals, editForm);
  };

  return (
    <div className='px-4 py-6 space-y-2'>
      <div className='flex justify-between items-start '>
        <div>
          <p className='font-medium'>Basket</p>
          <p className='text-xs text-gray-5'>
            {Object.keys(selectedMeals).length} brands,
            {getTotalMeals(selectedMeals)} meals
          </p>
        </div>

        <p className='text-red-5 text-xs cursor-pointer' onClick={handleRemove}>
          {type == 'create' && getTotalMeals(selectedMeals) > 0 && ' Remove all'}
          {type == 'edit' && !equalMeal() && 'Undo changes'}
        </p>
      </div>
      <div className='flex gap-3 items-center text-sm text-gray-8 pt-2'>
        <LocationIcon className='text-gray-4' />
        <span className='grow truncate'>{addressDetails?.address}</span>
        <EditAltIcon
          className='text-gray-4 cursor-pointer'
          onClick={() => setShowAddressModal(!showAddressModal)}
        />
      </div>
      <AddressModal
        data={addressDetails}
        setData={setAddressDetails}
        onClose={() => setShowAddressModal(!showAddressModal)}
        show={showAddressModal}
        isEdit
      />

      <BaseModal
        isOpen={showRemoveModal}
        title='Remove Items'
        description='Are you sure you want to remove all meals from basket?'
        onRequestClose={closeModal}
        titleIcon={<DeleteIcon className='text-red-5' />}
        titleIconColor='danger'>
        <div className='grid md:grid-cols-2 gap-5'>
          <Button small color='primary' variant='outline' onClick={closeModal}>
            Cancel
          </Button>
          <Button small color='primary' variant='block' onClick={handleRemoveMeals}>
            Confirm
          </Button>
        </div>
      </BaseModal>
    </div>
  );
};

export default BasketHeader;
