import React, { useContext, useEffect } from 'react';
import ActionDropdown from './action-dropdown';
import UserFooter from './user-footer';
import UserInformation from './user-info';
import UserProfileHeader from './user-profile-header';
import { apiService } from '../../../services/base.service';
import { UserProfileContext } from './context';
import { SideModal } from '@cokitchen/cokitchen-components';

type UserProfileModalProps = {
  closeModal: () => void;
  id: string;
};

const UserProfileContent = ({ closeModal, id }: UserProfileModalProps) => {
  const { setUser } = useContext(UserProfileContext);

  const getUser = async () => {
    const res = await apiService.get(`user/one/${id}`, {});
    setUser(res?.data?.user);
  };

  useEffect(() => {
    if (id) getUser();
  }, [id]);

  return (
    <SideModal isOpen onRequestClose={closeModal} size='wide' title='' hideFooter>
      <div className='space-y-6'>
        <ActionDropdown />
        <UserProfileHeader />
        <UserInformation />
        <UserFooter />
      </div>
    </SideModal>
  );
};

export default UserProfileContent;
