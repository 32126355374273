import React, { FC, useEffect, useState } from 'react';
import { LiveTripOrderType } from '../../../utils/types';
import { LiveCardMenuFunctionType } from '../live-trip-cards/menu-config';
import ConfirmPaymentModal from './confirm-payment-modal';
import ChangeAddressModal from '../../order-details-content/delivery-details/change-address-modal';
import CancelTripModal from '../../modals/cancel-trip-modal';
import CompleteTripModal from '../../modals/complete-trip-modal';

type LiveTripModalsProps = {
  selectedOrder: LiveTripOrderType;
  selectedFnTitle: LiveCardMenuFunctionType;
  closeOrderModal: () => void;
};
const LiveTripModals: FC<LiveTripModalsProps> = ({
  selectedFnTitle,
  selectedOrder,
  closeOrderModal
}) => {
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false);
  const [showChangeAddressModal, setShowChangeAddressModal] = useState(false);
  const [showCancelTripModal, setShowCancelTripModal] = useState(false);
  const [showCompleteTripModal, setShowCompleteTripModal] = useState(false);

  const confirmPayment = () => {
    setShowConfirmPaymentModal(!showConfirmPaymentModal);
  };

  const changeAddress = () => {
    setShowChangeAddressModal(!showChangeAddressModal);
  };

  const cancelTrip = () => {
    setShowCancelTripModal(!showCancelTripModal);
  };
  const completeTrip = () => {
    setShowCompleteTripModal(!showCompleteTripModal);
  };

  useEffect(() => {
    if (selectedFnTitle === 'confirmPayment') {
      confirmPayment();
    } else if (selectedFnTitle === 'changeAddress') {
      changeAddress();
    } else if (selectedFnTitle === 'cancelTrip') {
      cancelTrip();
    } else if (selectedFnTitle === 'completeTrip') {
      completeTrip();
    }
    closeOrderModal();
  }, [selectedFnTitle]);
  return (
    <>
      {showConfirmPaymentModal && (
        <ConfirmPaymentModal onClose={confirmPayment} order={selectedOrder} />
      )}
      {showChangeAddressModal && (
        <ChangeAddressModal order={selectedOrder} onClose={changeAddress} />
      )}
      {showCancelTripModal && <CancelTripModal order={selectedOrder} onClose={cancelTrip} />}
      {showCompleteTripModal && <CompleteTripModal order={selectedOrder} onClose={completeTrip} />}
    </>
  );
};

export default LiveTripModals;
