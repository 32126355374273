import { apiService } from '../services/base.service';
import { STORAGE_KEYS } from './constants';
import { MONTHS_OF_YEAR } from './constants';
import { ENUM_BANK_NAMES, ENUM_CS_ROLES, ENUM_STATUS } from './enum';
import { CoKitchenType, OrderType, LiveTripOrderType, UserType } from './types';
import { ENUM_DATE_RANGE } from '@cokitchen/cokitchen-components';

const isDateValid = (date: string): boolean => {
  const dateValue = new Date(date);
  return !isNaN(dateValue.getTime());
};

const getOrdinal = (n: number) => {
  const s = ['th', 'st', 'nd', 'rd'],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getDate = (date: string): number | null | string => {
  const dateValue = new Date(date);
  const value = dateValue.getDate();
  const ordinalValue = getOrdinal(value);
  return isDateValid(date) ? ordinalValue : null;
};

export const getMonth = (date: string): string | null => {
  const dateValue = new Date(date);
  return isDateValid(date) ? MONTHS_OF_YEAR[dateValue.getMonth() ?? 0] : null;
};

export const getShortMonth = (date: string): string | null => {
  const month = getMonth(date);
  return month ? month.slice(0, 3) : null;
};

export const getYear = (date: string): number | null => {
  const dateValue = new Date(date);
  return isDateValid(date) ? dateValue.getFullYear() : null;
};

export const getSimpleDateFormat = (date: string): string | null => {
  return `${getDate(date)} ${getShortMonth(date)}, ${getYear(date)}`;
};

export const getOrderStatus = (order: OrderType): ENUM_STATUS => {
  // TODO: Compute order state
  let status = ENUM_STATUS?.PENDING;
  const {
    kitchen_prepared,
    kitchen_dispatched,
    calculated_order,
    kitchen_accepted,
    shop_accepted,
    shop_prepared,
    kitchen_cancelled,
    cancelled,
    completed
  } = order;
  if (kitchen_cancelled || cancelled) {
    status = ENUM_STATUS.CANCELLED;
  } else if (completed) {
    status = ENUM_STATUS.COMPLETED;
  } else if (
    (kitchen_accepted || shop_accepted) &&
    (!kitchen_prepared || !shop_prepared) &&
    !kitchen_dispatched
  ) {
    status = ENUM_STATUS.PROCESSING;
  } else if (kitchen_prepared && shop_prepared && !kitchen_dispatched && !calculated_order.pickup) {
    status = ENUM_STATUS.READY;
  } else if (!calculated_order.pickup && kitchen_dispatched) {
    status = ENUM_STATUS.ONGOING;
  } else if (!kitchen_accepted) {
    status = ENUM_STATUS.PENDING;
  }
  return status;
};

export const getAdmin = () => {
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  return (
    role === ENUM_CS_ROLES.CS_ADMIN ||
    role === ENUM_CS_ROLES.SUPER_ADMIN ||
    role === ENUM_CS_ROLES.CS_LEAD
  );
};
export const getLiveTripOrderStatus = (order: LiveTripOrderType): ENUM_STATUS | 'completed' => {
  const {
    kitchen_prepared,
    kitchen_dispatched,
    calculated_order,
    kitchen_accepted,
    shop_accepted,
    shop_prepared,
    completed
  } = order;
  if (completed) return 'completed';
  if (!kitchen_accepted) {
    return ENUM_STATUS.PENDING;
  } else if (
    (kitchen_accepted || shop_accepted) &&
    (!kitchen_prepared || !shop_prepared) &&
    !kitchen_dispatched
  ) {
    return ENUM_STATUS.PROCESSING;
  } else if (kitchen_prepared && shop_prepared && !kitchen_dispatched && !calculated_order.pickup) {
    return ENUM_STATUS.READY;
  } else if (!calculated_order?.pickup && kitchen_dispatched) {
    return ENUM_STATUS.ONGOING;
  }
  return ENUM_STATUS.PENDING;
};

export const numberWithCommas = (x: string | number): string => {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const copyTextToClipboard = async (text: string) => {
  return await navigator.clipboard.writeText(text);
};

export const formatPhoneNumber = (number: string) => {
  // add - after every three characters
  let formattedNumber = '';
  for (let index = 0; index < number.length; index += 1) {
    formattedNumber += number[index];
    if ((index + 1) % 4 === 0) {
      formattedNumber += ' - ';
    }
  }
  return formattedNumber;
};

export const initKitchenData = async () => {
  const url = 'logistics/super-admin/cokitchens';
  const res = await apiService.get(url, {});
  const data = res.data.cokitchens;
  localStorage.setItem(STORAGE_KEYS.COKITCHEN_LIST, JSON.stringify(data));
  return data;
};

export const getCokitchenList = async () => {
  let data = [];
  const dataString = localStorage.getItem(STORAGE_KEYS.COKITCHEN_LIST);
  if (dataString) {
    data = JSON.parse(dataString);
  } else {
    data = await initKitchenData();
  }
  return data;
};

export const getLocationOptions = (item: CoKitchenType[]) => {
  const options = [];
  for (let i = 0; i < item?.length; i++) {
    if (item)
      options.push({
        value: item[i]?.id,
        text: item[i]?.name
      });
  }
  return options;
};

export const getKitchenById = async (kitchen_id: string): Promise<CoKitchenType> => {
  const data = await getCokitchenList();
  return data.find((kitchen: CoKitchenType) => kitchen.id === kitchen_id);
};

export const getEmoji = (name: string) => {
  const nameLower = name.toLowerCase();
  let emoji;
  if (nameLower.includes('extra')) {
    emoji = '🍕';
  } else if (
    nameLower.includes('protein') ||
    nameLower.includes('chicken') ||
    nameLower.includes('wing')
  ) {
    emoji = '🍗';
  } else if (nameLower.includes('side')) {
    emoji = '🍎';
  } else if (nameLower.includes('egg')) {
    emoji = '🥚';
  } else if (nameLower.includes('drink')) {
    emoji = '🥤';
  } else if (nameLower.includes('sauce') || nameLower.includes('spicy')) {
    emoji = '🌶️';
  } else if (nameLower.includes('drizzle')) {
    emoji = '🤤';
  } else if (
    nameLower.includes('swallow') ||
    nameLower.includes('number') ||
    nameLower.includes('favourite') ||
    nameLower.includes('quantity')
  ) {
    emoji = '😋';
  } else if (nameLower.includes('bacon')) {
    emoji = '🥓';
  } else if (nameLower.includes('cheese')) {
    emoji = '🧀';
  } else if (nameLower.includes('sausage')) {
    emoji = '🌭';
  }

  return emoji;
};

export function timeConvert(n: number) {
  const num = n;
  const hours = num / 3600;
  const rhours = Math.floor(hours);
  const minutes = (num % 3600) / 60;
  const rminutes = Math.round(minutes);

  let dateString;

  if (rhours === 0 && rminutes !== 0) {
    dateString = rminutes + ' mins';
  } else if (rminutes === 0 && rhours !== 0) {
    dateString = rhours + ' hr';
  } else if (rhours === 1 && rminutes !== 0) {
    dateString = rhours + ' hr ' + rminutes + ' mins';
  } else if (rhours === 1 && rminutes === 0) {
    dateString = rhours + ' hr ';
  } else if (rhours !== 0 && rminutes === 1) {
    dateString = rhours + ' hr ' + rminutes + ' min';
  } else if (rhours === 0 && rminutes === 1) {
    dateString = rminutes + ' min';
  } else {
    dateString = rhours + ' hrs ' + rminutes + ' mins';
  }

  return dateString;
}

export const getInterval = (activeDateRangeSelector: ENUM_DATE_RANGE) => {
  let interval;
  if (activeDateRangeSelector === ENUM_DATE_RANGE.TODAY) {
    interval = 'TODAY';
  } else if (activeDateRangeSelector === ENUM_DATE_RANGE.THIS_WEEK) {
    interval = 'THIS_WEEK';
  } else if (activeDateRangeSelector === ENUM_DATE_RANGE.THIS_MONTH) {
    interval = 'THIS_MONTH';
  } else if (activeDateRangeSelector === ENUM_DATE_RANGE.LAST_MONTH) {
    interval = 'LAST_MONTH';
  } else if (activeDateRangeSelector === ENUM_DATE_RANGE.YESTERDAY) {
    interval = 'YESTERDAY';
  }
  return interval;
};

export const getBankLogo = (bankName: ENUM_BANK_NAMES) => {
  let logo_url;
  if (bankName === ENUM_BANK_NAMES['9PSB']) {
    logo_url =
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1685545971591f6f886df-ad3e-4237-9175-de3bea9dbe96';
  } else if (bankName === ENUM_BANK_NAMES.ACCESS) {
    logo_url =
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/16855462250115d4895d1-eda3-4838-bbb1-9c6a6765e51a';
  } else if (bankName === ENUM_BANK_NAMES.FCMB) {
    logo_url =
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1685546344704e6def974-3104-4d2a-b939-d2348920218b';
  } else if (bankName === ENUM_BANK_NAMES.GT) {
    logo_url =
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/168554625311341b615fc-d7f4-436c-8b1e-2692ac2c4b65';
  } else if (bankName === ENUM_BANK_NAMES.HERITAGE) {
    logo_url =
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1685546365023459912c8-0de4-4c9f-8f19-0c17bf180115';
  } else if (bankName === ENUM_BANK_NAMES.PROVIDUS) {
    logo_url =
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/168554618629907c1d4b1-36af-4bac-b3d6-ecf7424bb00a';
  } else if (bankName === ENUM_BANK_NAMES.STANBIC) {
    logo_url =
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/1685546310976e3583334-c01c-44f2-825b-5fdc97b96428';
  } else {
    logo_url =
      'https://d3u7b9fq2opvwp.cloudfront.net/upload-service/16855462843845e654de2-5f08-4b4e-b107-15d169c4b796';
  }
  return logo_url;
};

export const FormatNumberOptions: Record<
  'currency' | 'decimal' | 'percent' | 'currencyWithNoDp' | 'decimalWithNoDp' | 'percentWithNoDp',
  Intl.NumberFormatOptions
> = {
  currency: {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: 'NGN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  },
  decimal: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'decimal'
  },
  percent: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'percent'
  },
  currencyWithNoDp: {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: 'NGN',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  },
  decimalWithNoDp: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'decimal'
  },
  percentWithNoDp: {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'percent'
  }
};

export function formatNumber(value: number | string, options?: Intl.NumberFormatOptions) {
  if (!value && value !== 0) return '-';
  let styleObj: Intl.NumberFormatOptions = {
    style: 'currency',
    currencyDisplay: 'symbol',
    currency: 'NGN',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  };
  if (options) styleObj = { ...styleObj, ...options };

  return new Intl.NumberFormat('en-NG', styleObj).format(+value);
}
export const formatTime = (timeStr: string) => {
  return Intl.DateTimeFormat('en-NG', {
    timeStyle: 'short',
    hourCycle: 'h12'
  }).format(new Date(timeStr));
};

export const noBaniAccountNo = (user: UserType) => {
  return (
    !user?.bani_account_number &&
    !user?.bani_account_number_fcmb &&
    !user?.bani_account_number_9ps &&
    !user?.bani_account_number_gt &&
    !user?.bani_account_number_providus &&
    !user?.bani_account_number_stanbic &&
    !user?.bani_account_number_heritage
  );
};
