import React, { useContext } from 'react';
import OrderActionTag, { ActionType } from '../../../components/base/order-action-tags';
import { formatNumber } from '../../../utils/functions';
import { CreateOrderContext } from '../contexts/create-order-context';

export type TotalArrType = {
  name: string;
  price: number;
  editVal?: { type: ActionType; val: number };
  // step: boolean;
};
const OrderTotal = ({ arr, total }: { arr: TotalArrType[]; total: number }) => {
  const { type } = useContext(CreateOrderContext);
  return (
    <>
      <div className='bg-[#F7F7F7] p-4 space-y-2 rounded-t-[10px] drop-shadow-xl'>
        {arr.map(({ name, price, editVal }, i) => (
          <div className='text-gray-4 text-sm flex items-center pt-2' key={i}>
            <p className='grow'>{name}</p>
            <div className='flex items-center gap-2'>
              <p> {formatNumber(price)}</p>
              {type == 'edit' && editVal && editVal.val > 0 && (
                <OrderActionTag val={editVal?.val} type={editVal?.type} variant='outline' />
              )}
            </div>
          </div>
        ))}
      </div>
      {total > 0 && (
        <div className='bg-[#F0F0F0] px-4 py-2 space-y-2 rounded-b-[10px]'>
          <div className='text-gray-6 text-sm flex items-center pt-2'>
            <p className='grow'>Total</p>
            <p> {formatNumber(total)}</p>
          </div>
        </div>
      )}
    </>
  );
};

export default OrderTotal;
