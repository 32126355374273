import React from 'react';
import { ArrowUpCircleIcon } from 'cokitchen-icons';

const SettingHeaderCard = ({
  icon,
  title,
  withArrow,
  details,
  onClick
}: {
  icon: JSX.Element;
  title: string;
  details: string;
  withArrow?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div className='border w-full border-gray-1 p-4 rounded-[4px] flex items-center flex-wrap'>
      <div className=' grow flex items-center gap-3'>
        <div className='text-red-5 w-8 h-8 p-[6px] bg-red-9 border border-red-2 rounded-[9px] flex justify-center items-center'>
          {icon}
        </div>
        <div>
          <h3>{title}</h3>
          <p className='text-xs text-gray-5'>{details}</p>
        </div>
      </div>
      {withArrow && (
        <button
          onClick={onClick}
          className='flex cursor-pointer items-center text-red-5 gap-1 text-xs'>
          Proceed
          <ArrowUpCircleIcon className='rotate-90' />
        </button>
      )}
    </div>
  );
};

export default SettingHeaderCard;
