import React, { FC, useContext, useState } from 'react';
import { ReactComponent as TitleIcon } from '../../../assets/icons/update-surge-modal-icon.svg';

import { toast } from 'react-toastify';
import { ToastMessage, BaseModal, Input, Button } from '@cokitchen/cokitchen-components';
import { apiService } from '../../../services/base.service';
import { UserProfileContext } from './context';
interface OweModalProps {
  show: boolean;
  onClose: (withRes?: boolean) => void;
}

interface OweModalFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
  disabled: boolean;
}

const OweModalFooter: FC<OweModalFooterProps> = ({ onCancel, onConfirm, loading, disabled }) => (
  <div className='grid grid-cols-2 gap-x-4'>
    <Button small color='primary' variant='outline' className='mx-auto' onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      loading={loading}
      disabled={disabled}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const OweModal: FC<OweModalProps> = ({ show, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('');
  const { user } = useContext(UserProfileContext);
  const updateDebt = async () => {
    setLoading(true);
    const payload = {
      owe: amount,
      user_id: user?.id
    };
    try {
      await apiService.post('admin/user/owe', payload);
      toast(<ToastMessage type='success' message='Debt Updated successfully' />);
      onClose(true);
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    } finally {
      setLoading(false);
    }
  };

  const name = user?.first_name + ' ' + user?.last_name;

  return (
    <BaseModal
      isOpen={show}
      title={`Update ${name} debt`}
      titleIcon={<TitleIcon />}
      titleIconColor='info'
      onRequestClose={() => onClose()}
      footer={
        <OweModalFooter
          loading={loading}
          onCancel={() => onClose()}
          onConfirm={updateDebt}
          disabled={!amount}
        />
      }>
      <Input
        className='mb-2'
        type='number'
        label='New Amount'
        onChange={(ev) => setAmount(ev.target.value)}
        value={amount}
        placeholder='Amount'
      />
    </BaseModal>
  );
};

export default OweModal;
