import React from 'react';
import BankTag, { BankType } from '../base/bank-tag';
import { Switch } from '@cokitchen/cokitchen-components';

const BankCard = ({
  type,
  bankLogo,
  active,
  name,
  onChange
}: {
  type: BankType;
  bankLogo: string;
  active: boolean;
  name: string;
  onChange: (item: boolean) => void;
}) => {
  return (
    <div className='flex flex-col gap-3 border border-gray-1 p-4 w-[230px]  rounded-[4px]'>
      <div className='ml-auto'>
        <BankTag type={type} />
      </div>
      <img src={bankLogo} alt='' className='w-10 h-10 object-contain ' />{' '}
      <p className='font-medium text-sm'>{name}</p>
      <Switch active={active} onChange={onChange} small />
    </div>
  );
};

export default BankCard;
