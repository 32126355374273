import EventEmitter from 'events';
import { io, Socket } from 'socket.io-client';

// eslint-disable-next-line
const delay = (delayTime: number): Promise<boolean> => {
  return new Promise((resolve) => {
    window.setTimeout(() => resolve(true), delayTime);
  });
};

const getRoomEventName = (room: string, eventName: string): string => room + ':' + eventName;

interface SocketEvent<T> {
  broadcast_group_key: string;
  event_name: string;
  message: T;
}

interface IJoinEventMessage {
  roomName: string;
  status: boolean;
  message: string;
}

export class SocketIOConnection {
  private static instance: SocketIOConnection | null = null;
  private static connecting = false;
  private readonly socket: Socket;
  private readonly joinEventBus = new EventEmitter();
  private readonly eventEmitter = new EventEmitter();

  private constructor(socket: Socket) {
    this.socket = socket;
    socket.on('join-status', (message: string) => {
      const messageObj: IJoinEventMessage = JSON.parse(message) as IJoinEventMessage;
      this.joinEventBus.emit(messageObj.roomName, messageObj);
    });
    socket.on('disconnecting', () => {
      console.log('socket disconnecting');
    });
    socket.on('disconnect', () => {
      console.log('socket disconnected');
    });
    socket.on('connect', () => {
      console.log('socket connected');
      this.eventEmitter.emit('reconnected', true);
    });
    // eslint-disable-next-line
    socket.on('push', (data: SocketEvent<any>) => {
      const roomEventName = getRoomEventName(data.broadcast_group_key, data.event_name);
      this.eventEmitter.emit(roomEventName, data.message);
    });
  }

  public onReconnect(func: () => void): void {
    this.eventEmitter.on('reconnected', func);
  }

  // eslint-disable-next-line
  public static getConnection(): Promise<SocketIOConnection> {
    const socketUrl: string = process.env.REACT_APP_SOCKET_URL ?? '';
    // eslint-disable-next-line
    const getOrCreateConnection = async (resolve: any) => {
      if (SocketIOConnection.connecting) {
        while (SocketIOConnection.connecting) {
          // wait for the first instance to get connection
          await delay(1000);
        }
        // eslint-disable-next-line
        resolve(SocketIOConnection.instance);
      }
      if (SocketIOConnection.instance === null) {
        SocketIOConnection.connecting = true;
        const socket = io(socketUrl, {});
        socket.on('connect', () => {
          SocketIOConnection.instance = new SocketIOConnection(socket);
          SocketIOConnection.connecting = false;
          // eslint-disable-next-line
          resolve(SocketIOConnection.instance);
        });
      } else {
        // eslint-disable-next-line
        resolve(SocketIOConnection.instance);
      }
    };
    // eslint-disable-next-line
    return new Promise((resolve, reject) => getOrCreateConnection(resolve));
  }

  // eslint-disable-next-line
  public joinRoom(roomName: string, authKey: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.socket.emit(
        'join',
        JSON.stringify({
          socket_id: this.socket.id,
          auth_key: authKey,
          room: roomName
        })
      );
      this.joinEventBus.once(roomName, (data: IJoinEventMessage) => {
        if (data.status) {
          resolve(data.message);
        }
        reject(data.message);
      });
    });
  }

  public removeListener(roomName: string, eventName: string): void {
    const roomEventName = getRoomEventName(roomName, eventName);
    this.eventEmitter.removeAllListeners(roomEventName);
  }

  public addEventListener<T>(
    roomName: string,
    eventName: string,
    callback: (message: T) => void
  ): void {
    const roomEventName = getRoomEventName(roomName, eventName);
    this.eventEmitter.on(roomEventName, callback);
  }
}
