import React, { FC, useEffect, useState } from 'react';
import { Input } from '@cokitchen/cokitchen-components';

type Props = {
  label: string;
  placeholder: string;
  value: string | number;
  onSearch: (val: any) => void;
  onSelect: (val: any) => void;
  options: any[];
  loading: boolean;
  emptyText: string;
  minStrLength?: number;
};

const AutoComplete: FC<Props> = ({
  label,
  placeholder,
  options,
  onSelect,
  onSearch,
  value,
  loading,
  emptyText,
  minStrLength = 4
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const [showMinWarning, setShowMinWarning] = useState(false);
  useEffect(() => {
    const show = loading || `${value}`.length >= minStrLength;
    setShowOptions(show);
  }, [loading, value]);

  useEffect(() => {
    const showWarn = `${value}`.length < minStrLength && `${value}`.length > 0;
    setShowMinWarning(showWarn);
  }, [value]);

  return (
    <div className='relative'>
      <Input
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={(val) => onSearch(val.target.value)}
      />
      {(showOptions || showMinWarning) && (
        <ul className='py-1 max-h-[300px] overflow-auto shadow-md absolute z-10 bg-white rounded w-full'>
          {showMinWarning ? (
            <li className='leading-[48px] text-center text-lg text-gray-5'>
              Enter at least {minStrLength} characters...
            </li>
          ) : loading ? (
            <li className='leading-[48px] text-center text-lg text-gray-5'>Loading...</li>
          ) : options.length === 0 ? (
            <li className='leading-[48px] text-center text-lg text-gray-5'>{emptyText}</li>
          ) : (
            options?.map((o) => (
              <li
                role='listitem'
                className='py-2 px-4 cursor-pointer hover:bg-[#fafafa]'
                onClick={() => {
                  onSelect(o);
                  setShowOptions(false);
                }}
                key={o.value}>
                {o.text}
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
};

export default AutoComplete;
