import React, { FC, useState } from 'react';
import { ReactComponent as TitleIcon } from '../../assets/icons/update-surge-modal-icon.svg';

import { toast } from 'react-toastify';
import { apiService } from '../../services/base.service';
import { LiveTripOrderType } from '../../utils/types';
import { ToastMessage, BaseModal, Input, Button } from '@cokitchen/cokitchen-components';
interface CompleteTripProps {
  onClose: () => void;
  order: LiveTripOrderType;
}

interface CompleteTripFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const CompleteTripFooter: FC<CompleteTripFooterProps> = ({ onCancel, onConfirm, loading }) => (
  <div className='grid grid-cols-2 gap-x-4'>
    <Button small color='primary' variant='outline' className='mx-auto' onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      loading={loading}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const CompleteTripModal: FC<CompleteTripProps> = ({ onClose, order }) => {
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState('0');
  const [bankTransferPayment, setBankTransferPayment] = useState(false);

  const confirmCompleteTrip = async () => {
    setLoading(true);
    try {
      await apiService.post('logistics/order/complete', {
        order_id: order.id,
        amount_paid: amount,
        bank_transfer: bankTransferPayment
      });
      toast(<ToastMessage type='success' message='Updated successfully' />);
      onClose();
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    } finally {
      setLoading(false);
    }
  };

  return (
    <BaseModal
      isOpen
      title='Complete Trip'
      description={`Are you sure you want to mark this order #${order?.order_code?.toUpperCase()} as complete?`}
      titleIcon={<TitleIcon />}
      titleIconColor='info'
      onRequestClose={onClose}
      footer={
        <CompleteTripFooter loading={loading} onCancel={onClose} onConfirm={confirmCompleteTrip} />
      }>
      <div>
        <Input
          className='mb-2'
          type='number'
          label='Amount'
          onChange={(ev) => setAmount(ev.target.value)}
        />

        <Input
          type='checkbox'
          onChange={() => setBankTransferPayment(!bankTransferPayment)}
          checked={bankTransferPayment}
          label='Bank Transfer'
        />
      </div>
    </BaseModal>
  );
};

export default CompleteTripModal;
