import React, { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as CancelTripIcon } from '../../assets/icons/cancel-trip-modal-icon.svg';
import { ToastMessage, BaseModal, Textarea, Button } from '@cokitchen/cokitchen-components';
import { apiService } from '../../services/base.service';
import { LiveTripOrderType } from '../../utils/types';

interface CancelTripModalProps {
  onClose: () => void;
  order: LiveTripOrderType;
}

interface CancelTripFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const CancelTripFooter: FC<CancelTripFooterProps> = ({ onCancel, onConfirm, loading }) => (
  <div className='grid grid-cols-2 gap-x-4'>
    <Button small color='primary' variant='outline' className='mx-auto' onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      loading={loading}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const CancelTripModal: FC<CancelTripModalProps> = ({ onClose, order }) => {
  const [loading, setLoading] = useState(false);
  const [comment, setComment] = useState('');
  const cancelTrip = async () => {
    try {
      setLoading(true);
      await apiService.post('logistics/super-admin/order/cancel', {
        order_code: order.order_code,
        cancelled_note: comment
      });
      toast(<ToastMessage type='success' message='Trip cancelled successfully' />);
      onClose();
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    } finally {
      setLoading(false);
    }
  };
  return (
    <BaseModal
      isOpen
      title='Cancel trip'
      description={`Any reason why you are cancelling this trip #${order?.order_code?.toUpperCase()}`}
      titleIcon={<CancelTripIcon />}
      titleIconColor='danger'
      onRequestClose={onClose}
      footer={<CancelTripFooter loading={loading} onCancel={onClose} onConfirm={cancelTrip} />}>
      <Textarea
        placeholder='Add a comment'
        value={comment}
        onChange={(ev) => setComment(ev.target.value)}
      />
    </BaseModal>
  );
};

export default CancelTripModal;
