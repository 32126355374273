import React from 'react';

export type ListType = {
  name: string;
  emoji?: string;
  onClick?: () => void;
  className?: string;
};

type TabType = 'block' | 'outline';

const StatusTabs = ({
  list,
  type = 'block',
  setActive,
  active,
  disabled,
  ...rest
}: {
  list: ListType[];
  type?: TabType;
  setActive: (item: string) => void;
  active: string;
  disabled?: boolean;
}) => {
  const styles = {
    active: `${
      type === 'block'
        ? 'bg-gray-6 border-none text-white'
        : type === 'outline' && 'bg-red-11 border-red-4 text-gray-6'
    }`,
    inactive: ` ${
      type === 'block'
        ? ' border-[#0000004D] bg-black bg-opacity-5 border-[0.4px]  text-gray-5'
        : type === 'outline' && 'bg-gray-11 border-gray-1 text-gray-4'
    }`
  };
  return (
    <div className='flex gap-6'>
      {list.map((item, i) => (
        <button
          onClick={() => setActive(item.name)}
          key={i}
          className={`disabled:cursor-not-allowed rounded-[20px] border px-5 py-[5px] min-w-fit flex items-center gap-2 justify-center capitalize cursor-pointer transition-all duration-300  ${
            active === item.name ? styles.active : styles.inactive
          } ${item?.className}`}
          disabled={disabled}
          {...rest}>
          {item?.emoji && (
            <span
              className={`w-7 h-7 flex justify-center items-center rounded-[100%] border ${
                active === item.name ? ' border-red-4 ' : 'border-[#DEDEDE]'
              }`}>
              {item.emoji}
            </span>
          )}
          {item.name}
        </button>
      ))}
    </div>
  );
};

export default StatusTabs;
