import React, { FC } from 'react';
import { IssueType } from '../../../utils/types';
import PendingSource from '../../base/pending-status';
import { ClockIcon, ContactSupportIcon, SurgeFilledIcon, UserAltIcon } from 'cokitchen-icons';
import { getSimpleDateFormat } from '../../../utils/functions';
import { Input } from '@cokitchen/cokitchen-components';
import { useNavigate } from 'react-router-dom';

type Props = {
  issue: IssueType;
  isAllSelected: boolean;
  markIssue?: () => void;
  isComplete?: boolean;
};

const IssueCard: FC<Props> = ({ issue, markIssue, isAllSelected, isComplete }) => {
  const dateObj = new Date(issue.created_at);
  const time = dateObj.toLocaleString('en-NG', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
  const name = `${issue.user.first_name} ${issue.user.last_name}`;

  const navigate = useNavigate();

  const goToOrder = () => {
    const order_code = issue?.order?.order_code?.toLowerCase();
    navigate(`/order/${order_code}`);
  };
  return (
    <div className='border border-gray-border rounded-lg flex flex-col'>
      <div className='px-4 py-3 grow'>
        <div className='flex items-center gap-2 flex-wrap justify-between'>
          <h4 className='font-medium  text-base text-gray-5  cursor-pointer' onClick={goToOrder}>
            #{issue.order.order_code?.toUpperCase()}
          </h4>
          {!isComplete ? (
            <Input
              type='checkbox'
              readOnly
              checked={false}
              onChange={markIssue}
              label='Mark as done'
            />
          ) : (
            <div className='inline-flex items-center gap-1 rounded-2xl border-2 border-blue-5 text-xs py-1 px-2 bg-[#f0f7fb] text-blue-5'>
              <SurgeFilledIcon className='w-4 h-4' /> Resolved
            </div>
          )}
        </div>
        <p className='flex min-w-fit items-center gap-2 text-lg'>
          <UserAltIcon className='w-4 h-4 text-gray-5' />
          {name}
        </p>
        <p className='flex items-start gap-2 mt-4 text-sm text-[#3e3e3e]'>
          <ContactSupportIcon className='mt-1 min-w-[1rem] h-4' />
          {issue.issue_text || '--'}
        </p>
      </div>
      <div className='border-t border-gray-border py-2 px-4 flex justify-between items-center'>
        {isAllSelected && <PendingSource type={issue.issue_source} />}
        <div className='flex items-center gap-2 py-2 text-xs'>
          <ClockIcon className='w-4 h-4 text-gray-5' />
          <div className='flex items-center gap-2'>
            {getSimpleDateFormat(issue.created_at)}
            <div className='bg-gray-2 min-w-[8px] w-2 h-2 rounded-full'></div>
            {time}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IssueCard;
