import React, { FC } from 'react';
import { ENUM_PENDING_SOURCE } from '../../../utils/enum';
import { PENDING_SOURCE } from '../../../utils/constants';

export type Props = {
  type: ENUM_PENDING_SOURCE;
};

const PendingSource: FC<Props> = ({ type }) => {
  const { bgColor, borderColor, text, textColor } = PENDING_SOURCE[type];
  return (
    <span
      style={{
        backgroundColor: bgColor,
        borderColor: borderColor,
        color: textColor
      }}
      className={`border rounded-[40px] text-xs leading-[26px] px-4`}>
      {text}
    </span>
  );
};

export default PendingSource;
