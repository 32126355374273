import React from 'react';
import PickupOrderImage from '../../../assets/images/pickup-order.png';

const PickupOrder = () => {
  return (
    <div className='center'>
      <div>
        <img src={PickupOrderImage} alt='pickup order' width={180} height={180} />
        <div className='text-center'>Pick up Order</div>
      </div>
    </div>
  );
};

export default PickupOrder;
