import { AddressIcon, AmountIcon, RiderAltIcon } from 'cokitchen-icons';
import moment from 'moment';
import React from 'react';
import { getSimpleDateFormat, formatNumber } from '../../../utils/functions';
import { OrderType } from '../../../utils/types';
import { useNavigate } from 'react-router-dom';

const UserOrderCard = ({ item }: { item: OrderType }) => {
  const navigate = useNavigate();
  const goToOrder = () => {
    navigate(`/order/${item?.order_code}`);
    window.location.reload();
  };
  const rider_name = item.rider ? `${item.rider.first_name} ${item.rider.last_name}` : 'nil';
  return (
    <div
      className='text-gray-8 border py-3 border-[#E4E6E7] text-sm rounded-lg flex flex-col min-w-[360px] cursor-pointer'
      onClick={goToOrder}>
      <div className='flex justify-between text-gray-6 px-3 text-base font-medium'>
        <p>{`#${item?.order_code?.toUpperCase()}`}</p>
        <p>{formatNumber(item?.calculated_order?.total_amount)}</p>
      </div>
      <div className='flex flex-col gap-2 grow px-3 mb-3 mt-2'>
        <div className='flex justify-between'>
          <p className='flex gap-2 items-center '>
            <RiderAltIcon className='w-5 h-5' />
            {item?.pickup ? (
              <span> Pickup </span>
            ) : item?.rider ? (
              <span>{rider_name}</span>
            ) : (
              <span className='text-red-4'> No rider yet</span>
            )}
          </p>
          <p className='flex gap-1 items-center text-gray-6 font-medium'>
            <AmountIcon />
            {formatNumber(item?.calculated_order?.delivery_fee)}
          </p>
        </div>
        <p className='flex gap-2 items-center'>
          <AddressIcon />
          {item?.calculated_order?.address_details.address_line}
        </p>
      </div>
      <hr className='text-[#DCE2EA] pt-3' />
      <div className='flex px-4 text-xs justify-between'>
        <p>{getSimpleDateFormat(item.created_at)}</p>
        <p>{moment(item.created_at).format('hh:mm a')}</p>
        <p> {moment(item.completed_time).format('hh:mm a')} </p>
      </div>
    </div>
  );
};

export default UserOrderCard;
