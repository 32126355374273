import React, { useState, useEffect, useContext } from 'react';
import DisplayPicture from '../../../assets/images/dp.png';
import moment from 'moment';
import BaseStats, { BaseStatsType } from '../../base/stats-details';
import Loader from '../../base/loader';
import { apiService } from '../../../services/base.service';
import { getInterval, timeConvert } from '../../../utils/functions';
import { UserProfileContext } from './context';
import { DateRangeSelector, ENUM_DATE_RANGE } from '@cokitchen/cokitchen-components';

type ResponseType = {
  total_orders: string;
  basket_abandonment_rate: number;
  average_order_duration: number;
  total_amount_spent: number;
};

const UserProfileHeader = () => {
  const { user, filters, setFilters } = useContext(UserProfileContext);
  const [userSummary, setUserSummary] = useState([] as BaseStatsType[]);
  const [loading, setLoading] = useState(false);

  const getUsersStatsByDate = async () => {
    const { startDate: start_date, endDate: end_date } = filters.date;

    const params: any = {
      start_date,
      end_date
    };
    const res = await apiService.post(`cs/user/one/${user?.id}`, params, true);
    const data: ResponseType = res?.data?.data;
    setUserSummary([
      {
        title: 'total orders',
        value: data?.total_orders
      },
      {
        title: 'total spent',
        value: data?.total_amount_spent ? String(data?.total_amount_spent) : '-',
        withNaira: data?.total_amount_spent > 0
      },
      {
        title: 'AVG. ORDER DURATION',
        value: data?.average_order_duration ? timeConvert(data?.average_order_duration) : '-'
      },
      {
        title: 'BASKET ABANDONMENT',
        value: data?.basket_abandonment_rate
          ? `${Number(data?.basket_abandonment_rate).toFixed(2)}%`
          : '-'
      }
    ]);
  };

  const getUsersStatsByInterval = async () => {
    const res = await apiService.get(
      `cs/user/one/${user?.id}/deviations?interval=${getInterval(filters.range)}`,
      {},
      true
    );
    const current_data: ResponseType = res?.data?.current_data;
    const previous_data: ResponseType = res?.data?.previous_data;
    const percentage_deviation: ResponseType = res?.data?.percentage_deviation;

    setUserSummary([
      {
        title: 'total orders',
        value: current_data?.total_orders,
        type:
          Number(previous_data?.total_orders) > Number(current_data?.total_orders)
            ? 'decrease'
            : 'increase',
        weeklyVal: `${Number(percentage_deviation?.total_orders).toFixed(2)}%`
      },
      {
        title: 'total spent',
        value: current_data?.total_amount_spent ? `₦ ${current_data?.total_amount_spent}` : '-',
        type:
          previous_data?.total_amount_spent > current_data?.total_amount_spent
            ? 'decrease'
            : 'increase',
        weeklyVal: `${Number(percentage_deviation?.total_amount_spent).toFixed(2)}%`
      },
      {
        title: 'AVG. ORDER DURATION',
        value: current_data?.average_order_duration
          ? timeConvert(current_data?.average_order_duration)
          : '-',
        type:
          previous_data?.average_order_duration > current_data?.average_order_duration
            ? 'decrease'
            : 'increase',
        weeklyVal: `${Number(percentage_deviation?.average_order_duration).toFixed(2)}%`
      },
      {
        title: 'BASKET ABANDONMENT',
        value: current_data?.basket_abandonment_rate
          ? `${Number(current_data?.basket_abandonment_rate).toFixed(2)}%`
          : '-',
        type:
          previous_data?.basket_abandonment_rate > current_data?.basket_abandonment_rate
            ? 'decrease'
            : 'increase',
        weeklyVal: `${Number(percentage_deviation?.basket_abandonment_rate).toFixed(2)}%`
      }
    ]);
  };
  const loadSummaryData = () => {
    setLoading(true);
    if (filters.range === ENUM_DATE_RANGE.SELECT || ENUM_DATE_RANGE.ALL) {
      getUsersStatsByDate();
    } else {
      getUsersStatsByInterval();
    }
    setLoading(false);
  };

  useEffect(() => {
    loadSummaryData();
  }, [filters]);
  return (
    <div className='space-y-10'>
      <div className=' flex items-center gap-4 flex-wrap'>
        <img
          src={DisplayPicture}
          alt='user display picture'
          className='w-9 h-9 rounded-[50%] flex justify-center items-center'
        />
        <div className='grow'>
          <p className='font-semibold text-2xl capitalize'>
            {user.first_name} {user.last_name}
          </p>
          <span className='text-sm text-gray-5 '>
            Joined {moment(user?.created_at).format('MMM Do, YYYY')}
          </span>
        </div>
        <DateRangeSelector onChange={(v) => setFilters(v)} />
      </div>

      {loading ? (
        <Loader />
      ) : (
        <div className='flex justify-between items-start w-full bg-[#F7F8F8]  p-4 rounded-[8px] border border-[#E9EBEC]'>
          {userSummary.length === 0 && (
            <div className='leading-[130px] text-center w-full'>
              <p>Loading...</p>
            </div>
          )}
          {userSummary?.map(({ title, type, time, value, weeklyVal, withNaira }, i) => (
            <div
              className={` w-[20%] flex justify-start items-start ${
                userSummary.length - 1 === i ? '' : 'border-r border-gray-2'
              }`}
              key={i}>
              <BaseStats
                title={title}
                type={type}
                weeklyVal={weeklyVal}
                value={value}
                time={time}
                withNaira={withNaira}
                activeDateRange={filters.range}
                small
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserProfileHeader;
