import React, { FC, useState } from 'react';
import { WalletIcon } from 'cokitchen-icons';
import { toast } from 'react-toastify';
import { BaseModal, Button, ToastMessage } from '@cokitchen/cokitchen-components';
import { LiveTripOrderType } from '../../../utils/types';
import { apiService } from '../../../services/base.service';

interface ConfirmPaymentModalProps {
  onClose: () => void;
  order: LiveTripOrderType;
}

interface ConfirmPaymentFooterProps {
  loading: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const ConfirmPaymentFooter: FC<ConfirmPaymentFooterProps> = ({ onCancel, onConfirm, loading }) => (
  <div className='grid grid-cols-2 gap-x-4'>
    <Button small color='primary' variant='outline' className='mx-auto' onClick={onCancel}>
      Cancel
    </Button>
    <Button
      small
      color='primary'
      variant='block'
      className='mx-auto'
      loading={loading}
      onClick={onConfirm}>
      Confirm
    </Button>
  </div>
);

const ConfirmPaymentModal: FC<ConfirmPaymentModalProps> = ({ onClose, order }) => {
  const [loading, setLoading] = useState(false);
  const cancelTrip = async () => {
    try {
      setLoading(true);
      await apiService.post(`kitchen/order/paid/${order?.order_code}`, null);
      toast(<ToastMessage type='success' message='Order confirmed successfully' />);
      onClose();
    } catch (err: any) {
      toast(<ToastMessage type='error' message={err.response.data.message} />);
    } finally {
      setLoading(false);
    }
  };
  return (
    <BaseModal
      isOpen
      title='Confirm Payment'
      className={'border-opacity-40'}
      description={`Are you sure you want to confirm order #${order?.order_code?.toUpperCase()} ?`}
      titleIcon={<WalletIcon />}
      titleIconColor='gray'
      onRequestClose={onClose}
      footer={<ConfirmPaymentFooter loading={loading} onCancel={onClose} onConfirm={cancelTrip} />}
    />
  );
};

export default ConfirmPaymentModal;
