import moment from 'moment';
import React, { useContext, useState } from 'react';
import CardWithHeader from '../../../base/cards/card-with-header';
import DetailsCard from '../../../base/cards/details-card';
import UserStats from './user-stats';
import CreditWalletModal from '../credit-wallet-modal';
import DebitWalletModal from '../debit-wallet-modal';
import OweModal from '../owe-modal';
import { UserProfileContext } from '../context';
import { apiService } from '../../../../services/base.service';
import { getAdmin, noBaniAccountNo } from '../../../../utils/functions';
const UserInformation = () => {
  const { user, setUser } = useContext(UserProfileContext);

  const style =
    'bg-red-9 rounded-[100%] w-7 h-7 text-red-4 p-2 flex justify-center items-center text-lg font-bold hover:bg-opacity-50 transition-all transition-all duration-100';
  const [showCreditModal, setShowCreditModal] = useState(false);

  const [showDebitModal, setShowDebitModal] = useState(false);

  const [showOweModal, setShowOweModal] = useState(false);

  const getUser = async () => {
    if (!user?.id) return;
    const res = await apiService.get(`user/one/${user.id}`, {});
    setUser(res?.data?.user);
  };
  const creditWallet = async (withRes?: boolean) => {
    if (withRes) await getUser();
    setShowCreditModal(!showCreditModal);
  };

  const debitWallet = async (withRes?: boolean) => {
    if (withRes) await getUser();
    setShowDebitModal(!showDebitModal);
  };

  const oweModal = async (withRes?: boolean) => {
    if (!getAdmin()) return;
    if (withRes) await getUser();
    setShowOweModal(!showOweModal);
  };

  return (
    <div className='space-y-6'>
      <div className='grid grid-cols-2 gap-6'>
        <DetailsCard cardType='PROFILE' title='Mobile number'>
          <a href={`tel:${user?.phone_number}`}>{user?.phone_number}</a>
        </DetailsCard>
        <DetailsCard cardType='PROFILE' title='Date of Birth'>
          <p>{user?.dob ? moment(user?.dob).format('Do MMM YYYY') : '-'}</p>
        </DetailsCard>

        <DetailsCard cardType='PROFILE' title='Email'>
          <a href={`mailto:${user?.email}`}>{user?.email}</a>
        </DetailsCard>
        <DetailsCard cardType='PROFILE' title='Wallet Balance'>
          <>
            <div className=' flex gap-3 items-center'>
              {getAdmin() && (
                <p className={style} onClick={() => creditWallet()}>
                  +
                </p>
              )}
              <p>₦ {Number(user?.balance).toFixed(2)}</p>
              {getAdmin() && (
                <p className={style} onClick={() => debitWallet()}>
                  -
                </p>
              )}
            </div>
          </>
        </DetailsCard>
        <DetailsCard cardType='PROFILE' title='Debt'>
          <span className=' text-red-5' onClick={() => oweModal()}>
            ₦ {Number(user?.owe).toFixed(2)}
          </span>
        </DetailsCard>
      </div>
      <div className='grid md:grid-cols-3 items-stretch gap-3'>
        <UserStats id={user.id} />
        <CardWithHeader title='bani account number'>
          {noBaniAccountNo(user) && '-'}
          {user?.bani_account_number && <p>Wema - {user?.bani_account_number}</p>}
          {user?.bani_account_number_gt && <p>Gtbank - {user?.bani_account_number_gt}</p>}
          {user?.bani_account_number_heritage && (
            <p>Heritage - {user?.bani_account_number_heritage}</p>
          )}
          {user?.bani_account_number_fcmb && <p>FCMB - {user?.bani_account_number_fcmb}</p>}
          {user?.bani_account_number_providus && (
            <p>Providus - {user?.bani_account_number_providus}</p>
          )}
          {user?.bani_account_number_9ps && <p>9Mobile Bank - {user?.bani_account_number_9ps}</p>}
          {user?.bani_account_number_stanbic && (
            <p>Stanbic - {user?.bani_account_number_stanbic}</p>
          )}
        </CardWithHeader>
      </div>
      <CreditWalletModal onClose={creditWallet} show={showCreditModal} />

      <DebitWalletModal onClose={debitWallet} show={showDebitModal} />

      <OweModal onClose={oweModal} show={showOweModal} />
    </div>
  );
};

export default UserInformation;
