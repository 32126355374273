import { Button, Input, ToastMessage } from '@cokitchen/cokitchen-components';
import React, { useState } from 'react';
import { apiService } from '../../../../services/base.service';
import { toast } from 'react-toastify';

type AddToWalletProps = {
  user_id: string;
  setAddToWallet: (item: boolean) => void;
  setChangeAddrBtn: (item: boolean) => void;
  loading: boolean;
  setLoading: (item: boolean) => void;
};
const AddToWallet = ({
  user_id,
  loading,
  setLoading,
  setAddToWallet,
  setChangeAddrBtn
}: AddToWalletProps) => {
  const [amount, setAmount] = useState('');

  const creditWallet = async () => {
    setLoading(true);
    const payload = {
      user_id,
      amount: amount,
      reason: 'Refund Delivery Fee'
    };
    try {
      const res = await apiService.post('user/wallet/add', payload);
      if (res) {
        toast(<ToastMessage type='success' message='Wallet Credited Successfully' />);
        setAddToWallet(false);
        setChangeAddrBtn(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className='mt-2 space-y-5'>
      <Input
        small
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        placeholder={`Enter amount greater than 0`}
      />
      <div className=' flex gap-2 h-10'>
        <Button
          color='primary'
          variant='outline'
          className='h-[100%]'
          small
          onClick={creditWallet}
          loading={loading}
          disabled={!amount || Number(amount) == 0 || loading}>
          Add To User&apos;s Wallet
        </Button>
      </div>
    </div>
  );
};

export default AddToWallet;
