import React, { FC } from 'react';
import { Dropdown, DropdownItem, DropdownList, Input } from '@cokitchen/cokitchen-components';
import { BrandType } from '../../../utils/types';
import { ArrowDownIcon } from 'cokitchen-icons';

type Props = {
  label?: string;
  hasAll?: boolean;
  allBrands: BrandType[];
  activeBrand: BrandType;
  setActiveBrand: (arg: BrandType) => void;
};

const BrandSelector: FC<Props> = ({
  label,
  allBrands,
  hasAll = true,
  activeBrand,
  setActiveBrand
}) => {
  return (
    <Dropdown
      placement='bottom-start'
      content={
        <div className='flex gap-x-2 items-center'>
          {label && <label className='text-gray-8'>{label}:</label>}
          <div className='px-3 py-[6px] inline-flex items-center text-sm gap-3 font-medium rounded-[20px] bg-red-1'>
            <span>{activeBrand.name}</span>
            <ArrowDownIcon />
          </div>
        </div>
      }>
      <DropdownList className='max-h-[300px]'>
        {hasAll && (
          <DropdownItem onClick={() => setActiveBrand({ name: 'All', id: 'ALL' } as BrandType)}>
            <Input
              className='text-sm hover:bg-[#efefef] transition-all duration-200'
              type='radio'
              checked={activeBrand.id === 'ALL'}
              readOnly
              label='All'
            />
          </DropdownItem>
        )}
        {allBrands.map((p, i) => (
          <DropdownItem key={i} onClick={() => setActiveBrand(p)}>
            <Input
              className='text-sm hover:bg-[#efefef] transition-all duration-200'
              type='radio'
              checked={activeBrand.id === p.id}
              readOnly
              label={p.name}
            />
          </DropdownItem>
        ))}
      </DropdownList>
    </Dropdown>
  );
};

export default BrandSelector;
