import { ClockIcon } from 'cokitchen-icons';
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { OrderType } from '../../utils/types';

type Props = {
  order: OrderType;
};

const OrderCardFooter = ({ order }: Props) => {
  const order_code = order.order_code;
  const dateObj = new Date(order.created_at);
  const time = dateObj.toLocaleString('en-NG', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
  return (
    <div className='border-t border-gray-border text-xs p-4 flex justify-between items-center'>
      <div className='flex items-center gap-2'>
        <ClockIcon stroke='none' className='w-3 h-3' />
        <div className='flex items-center gap-2'>
          {moment(order.created_at).format('Do MMM, YYYY')}
          <div className='bg-gray-2 min-w-[8px] w-2 h-2 rounded-full'></div>
          {time}
        </div>
      </div>
      <p className='underline text-red-4'>
        <Link to={'/order/' + order_code}>View details</Link>
      </p>
    </div>
  );
};

export default OrderCardFooter;
