import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { Input } from '@cokitchen/cokitchen-components';
import { AddonSelectionType } from '../../../../utils/types';
import Counter from '../../counter';
import Loader from '../../loader';
import { formatNumber } from '../../../../utils/functions';

export type DetailsCardType = 'BRAND' | 'PROFILE' | 'ADDON' | 'SHOP' | AddonSelectionType;

type DetailsCardProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode;
  title: string;
  active?: boolean;
  price?: number | string;
  onClick?: () => void;
  cardType: DetailsCardType;
  checked?: boolean;
  onChange?: () => void;
  onAddClick?: () => void;
  onSubtractClick?: () => void;
  loading?: boolean;
  quantity?: number;
  className?: string;
};

const DetailsCard: FC<DetailsCardProps> = ({
  children,
  title,
  active,
  onClick,
  checked,
  onChange,
  cardType,
  price,
  loading,
  quantity,
  onAddClick,
  onSubtractClick,
  className = '',
  ...props
}) => {
  return (
    <button
      onClick={onClick}
      className={`text-left border py-5  rounded-lg px-3 break-all space-y-1 disabled:cursor-not-allowed transition-all duration-100 ${
        active ? 'border-red-4' : 'border-gray-border'
      } ${cardType !== 'PROFILE' && 'max-w-[240px] cursor-pointer space-y-2'} ${className}`}
      {...props}>
      <div className='flex items-center justify-between gap-1'>
        <p
          className={`text-sm  ${
            cardType !== 'PROFILE' ? 'text-gray-6 font-medium' : 'text-gray-5'
          }`}>
          {title}
        </p>
        {cardType == 'MULTI_SELECTION' && (
          <Input checked={checked} onChange={onChange} className='text-sm' type='checkbox' />
        )}
        {cardType == 'SINGLE_SELECTION' && (
          <Input checked={checked} onChange={onChange} className='text-sm' type='radio' />
        )}
      </div>

      <div
        className={` ${
          cardType !== 'PROFILE' ? 'text-gray-5  text-xs' : 'font-medium text-gray-6'
        } `}>
        {children}
        {cardType !== 'PROFILE' && (
          <div className='flex items-center justify-between'>
            {price && price !== '0' && formatNumber(price || 0)}
            {loading && <Loader />}
            {(cardType === 'MULTI_SELECTION_WITH_QUANTITY' ||
              cardType === 'SHOP' ||
              cardType === 'ADDON') && (
              <Counter
                val={quantity || 0}
                onAddClick={() => onAddClick?.()}
                onSubtractClick={() => onSubtractClick?.()}
              />
            )}
          </div>
        )}
      </div>
    </button>
  );
};

export default DetailsCard;
