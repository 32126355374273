import React, { useState } from 'react';
import DashboardLayout from '../../layouts/dashboard.layout';
import Profile from './profile';
import Password from './password';
import Banks from './banks';

type ProfileType = 'profile' | 'password' | 'banks';
const PROFILE_LIST: ProfileType[] = ['profile', 'banks', 'password'];
const Settings = () => {
  const [active, setActive] = useState<ProfileType>('profile');
  return (
    <DashboardLayout className='space-y-12 w-[80%] max-w-[1000px] mx-0 ml-10'>
      <div className='py-4 border-b border-[#DCE2EA]'>
        <h1 className='text-lg md:text-2xl font-semibold'>Settings</h1>
        <p className='text-gray-5 text-sm'>Manage your account settings and preferences</p>
      </div>

      <div className='flex items-start'>
        <ul className='flex flex-col gap-4 w-[25%] max-w-[250px]'>
          {PROFILE_LIST.map((item, i) => (
            <li
              onClick={() => setActive(item)}
              className={`capitalize text-xs py-1 pl-4 cursor-pointer transition-all duration-100 ${
                active === item ? 'border-l-2 text-gray-6' : 'text-gray-5'
              }`}
              key={i}>
              {item}
            </li>
          ))}
        </ul>

        {active === 'profile' && <Profile />}
        {active === 'banks' && <Banks />}
        {active === 'password' && <Password />}
      </div>
    </DashboardLayout>
  );
};

export default Settings;
