import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/base/loader';
import OrderCard from '../../../components/order-card';
import { apiService } from '../../../services/base.service';
import { OrderType } from '../../../utils/types';
import NoSearchResult from '../no-search-result';

const OrderSearchResult = () => {
  const [pageloading, setPageLoading] = useState(false);
  const [orders, setOrders] = useState([] as OrderType[]);
  const [total, setTotal] = useState(0);
  const [pageCount, setPageCount] = useState(1);

  const { filterType: filter_type, filterValue: filter_value } = useParams();
  const navigate = useNavigate();
  if (!filter_value || !filter_type) navigate(-1);

  const getOrders = async () => {
    const filter = { filter_type, filter_value };
    const res = await apiService.post(`cs/search/keyword/${pageCount}`, filter);
    const { total, data } = res.data;
    setTotal(total);
    setOrders([...orders, ...data]);
  };

  const loadData = async () => {
    if (pageCount === 1) setPageLoading(true);
    await getOrders();
    setPageLoading(false);
  };

  const checkIfMore = () => {
    return orders.length < total;
  };

  const fetchMoreData = () => {
    setPageCount(pageCount + 1);
  };

  useEffect(() => {
    loadData();
  }, [pageCount]);

  return (
    <div>
      {pageloading ? (
        <Loader />
      ) : orders?.length === 0 ? (
        <NoSearchResult />
      ) : (
        <InfiniteScroll
          hasMore={checkIfMore()}
          next={() => fetchMoreData()}
          dataLength={orders.length}
          loader={
            <div className='mt-5'>
              <Loader />
            </div>
          }
          endMessage={<p className='text-center mt-8'>No more orders to show</p>}>
          <OrderCard orders={orders} />
        </InfiniteScroll>
      )}
    </div>
  );
};

export default OrderSearchResult;
