import React, { useContext } from 'react';
import { AddonCategoryType, AddonSelectionType, AddonType } from '../../../../utils/types';
import { CreateOrderContext } from '../../contexts/create-order-context';
import { doesAddonExist, handleAddAddon, handleSubtractAddon } from '../../functions';
import MultiSelectionAddon from './multi-selection-addon';
import MultiSelectionWithQuantityAddon from './multi-selection-quantity-addon';
import SingleSelectionAddon from './single-selection-addon';

const AddOns = () => {
  const { addonCategory, active, addonSelection, setAddonSelection } =
    useContext(CreateOrderContext);
  const filteredAddOn = addonCategory.filter(({ name }) => active === name);
  const handleSelectAddon = (addon: AddonType, item: AddonCategoryType) => {
    const addonId: string = item?.id;
    const type: AddonSelectionType = item?.meal_category_selection_type?.name;
    if (type === 'SINGLE_SELECTION') {
      addonSelection[addonId] = {
        name: addon.meal_data.name,
        id: addon.meal_data.id,
        quantity: 1,
        price: Number(addon.meal_data?.amount),
        is_addon: true
      };
    }
    if (type === 'MULTI_SELECTION') {
      const maxLength = Number(item?.selection_no) || 100;
      const arrLength = Object.keys(addonSelection).length;
      const addonId = addon.meal_data.id;
      if (doesAddonExist(addonId, addonSelection)) {
        delete addonSelection[addon.meal_addon_id];
      } else if (arrLength < maxLength) {
        addonSelection[addon.meal_addon_id] = {
          name: addon.meal_data.name,
          id: addon.meal_data.id,
          quantity: 1,
          price: Number(addon.meal_data?.amount),
          is_addon: true
        };
      }
    }
    setAddonSelection({ ...addonSelection });
  };

  const onAddClick = (addon: AddonType) => {
    setAddonSelection(handleAddAddon(addon, addonSelection));
  };
  const onSubtractClick = (addon: AddonType) => {
    setAddonSelection(handleSubtractAddon(addon, addonSelection));
  };

  return (
    <div>
      <SingleSelectionAddon
        filteredAddOn={filteredAddOn}
        handleSelectAddon={handleSelectAddon}
        addonSelection={addonSelection}
      />
      <MultiSelectionAddon
        filteredAddOn={filteredAddOn}
        addonSelection={addonSelection}
        handleSelectAddon={handleSelectAddon}
      />
      <MultiSelectionWithQuantityAddon
        filteredAddOn={filteredAddOn}
        handleSelectAddon={handleSelectAddon}
        onAddClick={onAddClick}
        onSubtractClick={onSubtractClick}
        addonSelection={addonSelection}
      />
    </div>
  );
};

export default AddOns;
