import React, { useContext } from 'react';
import BasketAccordion from '../../../components/base/basket-accordion';
import { CreateOrderContext } from '../contexts/create-order-context';

const BasketMeals = () => {
  const { formData } = useContext(CreateOrderContext);
  const selectedmealsArray = Object.values(formData?.selectedMeals);

  return (
    <div className='h-[60%] no-scroll-bar no-wrap'>
      {selectedmealsArray.map((item, index) => {
        return <BasketAccordion list={item} key={index} />;
      })}
    </div>
  );
};

export default BasketMeals;
