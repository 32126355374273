import React, { FC } from 'react';

type typeOrderDetailsHeader = {
  totalOrderAmount: number | string;
  totalNoOfBrands: number | string;
  totalNoOfItems: number | string;
  note: string | null;
};

const OrderDetailsHeader: FC<typeOrderDetailsHeader> = ({
  totalOrderAmount,
  totalNoOfItems,
  totalNoOfBrands,
  note
}) => {
  return (
    <div className='px-6 pb-3 border-b border-gray-border space-y-4'>
      <div className='text-sm  text-gray-6 font-medium flex justify-between items-center '>
        <div className='border-left flex gap-4 items-center'>
          <div className='bg-yellow-5 w-[4px] h-8'></div>
          <div>
            <h6>Order details</h6>
            <p className='text-xs text-gray-8'>
              {totalNoOfBrands} brands, {totalNoOfItems} items
            </p>
          </div>
        </div>
        <p> {totalOrderAmount} </p>
      </div>
      {note && (
        <div className='font-semibold uppercase text-xs '>
          Cancellation Note: <span className='text-red-5'>{note}</span>
        </div>
      )}
    </div>
  );
};

export default OrderDetailsHeader;
