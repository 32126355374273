import React, { FC } from 'react';
import { BrandType, ReviewType } from '../../../utils/types';
import { ClockIcon, ContactSupportIcon, SurgeFilledIcon, UserAltIcon } from 'cokitchen-icons';
import { getSimpleDateFormat } from '../../../utils/functions';
import { Input } from '@cokitchen/cokitchen-components';
import { useNavigate } from 'react-router-dom';
import UserRating from '../../base/user-rating';

type Props = {
  review: ReviewType;
  brand: BrandType;
  isAllSelected: boolean;
  markIssue?: () => void;
  onBrandSelect: () => void;
  isResolved?: boolean;
};

const ReviewCard: FC<Props> = ({
  review,
  brand,
  isAllSelected,
  markIssue,
  onBrandSelect,
  isResolved
}) => {
  const dateObj = new Date(review.created_at);
  const time = dateObj.toLocaleString('en-NG', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  });
  const name = `${review.user.first_name} ${review.user.last_name}`;

  const navigate = useNavigate();

  const goToOrder = () => {
    const order_code = review.order.order_code?.toLowerCase();
    navigate(`/order/${order_code}`);
  };

  return (
    <div className='border border-gray-border rounded-lg flex flex-col'>
      <div className='grow px-4 py-3 space-y-2'>
        <div className='flex items-center gap-2 flex-wrap justify-between'>
          <h4 className='font-medium  text-base text-gray-5 cursor-pointer' onClick={goToOrder}>
            #{review.order.order_code?.toUpperCase()}
          </h4>
          {!isResolved ? (
            <Input
              type='checkbox'
              readOnly
              checked={false}
              onChange={markIssue}
              className='scale-[90%] text-sm'
              label='Mark as resolved'
              labelDirection='left'
            />
          ) : (
            <div className='inline-flex items-center gap-1 rounded-2xl border-2 border-blue-5 text-xs my-1 py-1 px-2 bg-[#f0f7fb] text-blue-5'>
              <SurgeFilledIcon className='w-4 h-4' /> Resolved
            </div>
          )}
        </div>
        <div className=' flex justify-between items-center'>
          <p className='flex min-w-fit items-center gap-2 text-lg'>
            <UserAltIcon className='w-4 h-4 text-gray-5' />
            {name}
          </p>
          <UserRating rating={review?.rate} />
        </div>

        <p className='flex items-start gap-2 mt-4 text-sm text-[#3e3e3e]'>
          <ContactSupportIcon className='mt-1 min-w-[1rem] h-4' />
          {review.comment || '--'}
        </p>
      </div>
      <div className='border-t border-gray-border py-2 px-4 flex justify-between items-center'>
        {isAllSelected && (
          <p
            className='flex min-w-fit items-center gap-2 text-sm hover:underline'
            role='button'
            onClick={onBrandSelect}>
            <img className='w-[28px] h-[28px]' src={brand.logo} />
            {brand.name}
          </p>
        )}
        <div className='flex items-center gap-2 py-2 text-xs'>
          <ClockIcon className='w-4 h-4 text-gray-5' />
          <div className='flex items-center gap-2'>
            {getSimpleDateFormat(review.created_at)}
            <div className='bg-gray-2 min-w-[8px] w-2 h-2 rounded-full'></div>
            {time}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
