import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  BaseModal,
  Button,
  ToastMessage,
  Multiselect,
  Input
} from '@cokitchen/cokitchen-components';
import { AddIcon, NotificationIcon } from 'cokitchen-icons';
import { apiService } from '../../../services/base.service';
import { toast } from 'react-toastify';
import { IssueType, OrderType } from '../../../utils/types';
import AutoComplete from '../../base/autocomplete';

type Props = {
  onCreateNewIssue?: (issue: IssueType) => void;
};

const ISSUE_SOURCE_OPTIONS = [
  { text: 'Kitchen', value: 'KITCHEN' },
  { text: 'Delivery', value: 'DELIVERY' },
  { text: 'Review', value: 'REVIEW' }
];

const EMPTY_FORM_DATA = {
  issue_text: '',
  order_id: '',
  issue_source: ''
};

const CreateIssue: FC<Props> = ({ onCreateNewIssue }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(EMPTY_FORM_DATA);
  const [formIsValid, setFormIsValid] = useState(false);
  const [orderCode, setOrderCode] = useState('');
  const [orderCodeOptions, setOrderCodeOptions] = useState<{ text: string; value: string }[]>([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const searchOrderCode = async () => {
    if (orderCode.length < 4 || orderCode.length > 6) return;
    try {
      setLoadingOptions(true);
      const res = await apiService.post(`logistics/super-admin/order/search/${orderCode}`, {
        search_query: orderCode
      });
      const options =
        res.data?.data?.map(({ id, order_code, user }: OrderType) => ({
          value: id,
          text: `${order_code?.toUpperCase()} by ${user.first_name} ${user.last_name}`
        })) ?? [];
      setOrderCodeOptions(options);
    } catch (error) {
      setOrderCodeOptions([]);
    } finally {
      setLoadingOptions(false);
    }
  };

  const createIssue = async () => {
    try {
      setLoading(true);
      const res = await apiService.post('cs/issue', formData);
      onCreateNewIssue?.(res.data?.cs_issue);
      setShow(false);
      toast(<ToastMessage type='success' message={res.data.message} />);
    } catch {
      toast(
        <ToastMessage
          type='error'
          message='There was an error creating your issue. Contact tech support'
        />
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const isComplete = Object.values(formData).every((v) => v);
    setFormIsValid(isComplete);
  }, [formData]);

  useEffect(() => {
    if (show) {
      setFormData(EMPTY_FORM_DATA);
      setLoading(false);
      setOrderCodeOptions([]);
      setOrderCode('');
    }
  }, [show]);

  useEffect(() => {
    const minifiedOrderCode = orderCode.split(' ')[0].toLowerCase();
    if (orderCode && minifiedOrderCode !== formData.order_id) {
      searchOrderCode();
    }
  }, [orderCode]);

  const updateOrderCode = (val: { text: string; value: string }) => {
    setFormData({ ...formData, order_id: val.value });
    setOrderCode(val.text);
  };

  return (
    <Fragment>
      <Button small onClick={() => setShow(true)} color='primary' variant='block'>
        <AddIcon className='w-4 h-4 mr-2' />
        <span>Create Issue</span>
      </Button>
      <BaseModal
        isOpen={show}
        onRequestClose={() => setShow(false)}
        title='Log New Task Reminder'
        titleIcon={<NotificationIcon className='text-blue-5' />}
        titleIconColor='info'
        footer={
          <Button
            color='primary'
            variant='block'
            small
            disabled={!formIsValid}
            loading={loading}
            onClick={createIssue}>
            Create Task
          </Button>
        }>
        <div className='flex flex-col gap-3'>
          <Input
            label='Issue/Task'
            placeholder='Enter issue or task'
            value={formData.issue_text}
            onChange={(e) => setFormData({ ...formData, issue_text: e.target.value })}
          />
          <AutoComplete
            emptyText='No orders match your search'
            label='Order Code'
            loading={loadingOptions}
            onSearch={setOrderCode}
            onSelect={updateOrderCode}
            options={orderCodeOptions}
            placeholder='Start typing order code...'
            value={orderCode}
          />
          <label>
            Issue source
            <Multiselect
              isSingle
              placeholder='Where is the issue from?'
              options={ISSUE_SOURCE_OPTIONS}
              values={formData.issue_source ? [formData.issue_source] : []}
              onChange={(val) => setFormData({ ...formData, issue_source: val[0] })}
            />
          </label>
        </div>
      </BaseModal>
    </Fragment>
  );
};
export default CreateIssue;
