import React, { useState, useEffect } from 'react';
import { ReactComponent as TitleIcon } from '../../../assets/icons/location-add.svg';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { apiService } from '../../../services/base.service';
import { useNavigate } from 'react-router-dom';
import { getCokitchenList, getLocationOptions } from '../../../utils/functions';
import { Button, BaseModal, Multiselect, Input } from '@cokitchen/cokitchen-components';
import { AddressDetailsType } from '../../../utils/types';
import { ORDER_ACTION } from '../../../utils/constants';

type LocationType = {
  text: string;
  value: string;
};
const AddressModal = ({
  show,
  onClose,
  phoneNo,
  id,
  data,
  setData,
  isEdit
}: {
  show: boolean;
  isEdit?: boolean;
  onClose: () => void;
  phoneNo?: string;
  id?: string;
  data?: any;
  setData?: (item: AddressDetailsType) => void;
}) => {
  const [loadingPolygon, setLoadingPolygon] = useState(false);
  const [formData, setFormData] = useState({} as AddressDetailsType);
  const [locations, setLocations] = useState([] as LocationType[]);
  const [error, setError] = useState('');

  const loadCokitchen = async () => {
    const cokitchenArrays = await getCokitchenList();
    setLocations(getLocationOptions(cokitchenArrays) ?? []);
  };
  const navigate = useNavigate();

  const createOrder = () => {
    if (isEdit) {
      setData?.({ ...formData });
      onClose();
    } else {
      navigate(`/${ORDER_ACTION.CREATE}/${id}`, {
        state: formData
      });
    }
  };
  useEffect(() => {
    if (phoneNo)
      setFormData({
        ...formData,
        phoneNo
      });
  }, []);

  useEffect(() => {
    if (isEdit) setFormData(data);
  }, [data]);

  const checkPolygon = async (payload: any) => {
    try {
      const res = await apiService.post('internal/order/polygon/change', payload, true, true);
      setFormData((prev) => ({ ...prev, cokitchen_polygon_id: res?.data?.cokitchen_polygon?.id }));
    } catch (err) {
      console.log(err);
      setError('Polygon Not Found, Try Another Address!');
    }
  };

  const handleChange = async (val: string) => {
    setError('');
    setLoadingPolygon(true);
    const results = await geocodeByAddress(val);
    const latLng = await getLatLng(results[0]);
    const user_address = {
      name: 'other',
      building_number: results[0]?.address_components[0]?.short_name,
      city: results[0]?.address_components[2]?.long_name,
      address_line: results[0]?.formatted_address,
      phone_number: phoneNo ?? ''
    };
    const payload = {
      lat: String(latLng?.lat),
      lng: String(latLng?.lng),
      cokitchen_id: formData?.cokitchenId[0]
    };
    setFormData((prev) => ({
      ...prev,
      address: val,
      user_address,
      lat: String(latLng?.lat),
      lng: String(latLng?.lng)
    }));
    await checkPolygon(payload);
    setLoadingPolygon(false);
  };

  const searchOptions = {
    componentRestrictions: { country: ['ng'] }
  };

  useEffect(() => {
    loadCokitchen();
  }, []);

  const isDisabled = () => {
    return (
      !formData?.address ||
      !formData?.phoneNo ||
      !formData?.cokitchen_polygon_id ||
      error.length !== 0 ||
      loadingPolygon
    );
  };

  return (
    <BaseModal
      isOpen={show}
      title='Create Order'
      onRequestClose={onClose}
      titleIcon={<TitleIcon />}
      titleIconColor='info'>
      <div className='space-y-4'>
        <div>
          <label className='font-medium'>Location served</label>
          <Multiselect
            placeholder='Select Location'
            values={formData?.cokitchenId ?? ['']}
            options={locations}
            onChange={(val) =>
              setFormData((prev) => ({
                ...prev,
                cokitchenId: val,
                address: ''
              }))
            }
            isSingle
          />
        </div>
        {formData?.cokitchenId?.length > 0 && (
          <PlacesAutocomplete
            value={formData?.address}
            onChange={(val) => setFormData({ ...formData, address: val })}
            onSelect={handleChange}
            searchOptions={searchOptions}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div>
                <Input
                  {...getInputProps({
                    placeholder: 'Enter address...',
                    className: 'mb-2'
                  })}
                  label='User Address'
                />
                <div className=' '>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion, i) => {
                    const className = 'px-3 py-2 ';
                    const style = suggestion.active
                      ? {
                          backgroundColor: '#F5F5F5',
                          transition: 'all .3s',
                          cursor: 'pointer'
                        }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };

                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                        key={i}>
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>

                {loadingPolygon && (
                  <span className='capitalize text-gray-6 text-sm font-semibold'>
                    Checking Polygon...
                  </span>
                )}
                {error && (
                  <span className='capitalize text-sm text-red-5 font-semibold'>{error}</span>
                )}
              </div>
            )}
          </PlacesAutocomplete>
        )}
        <Input
          name='phoneNo'
          label='Phone Number'
          placeholder='08012312312'
          value={formData?.phoneNo}
          onChange={(e) =>
            setFormData({
              ...formData,
              phoneNo: e?.target?.value
            })
          }
        />
        <Button color='primary' variant='block' disabled={isDisabled()} onClick={createOrder}>
          Confirm
        </Button>
      </div>
    </BaseModal>
  );
};

export default AddressModal;
