import React, { FC } from 'react';
import { ORDER_STATUS } from '../../../utils/constants';
import { ENUM_STATUS } from '../../../utils/enum';

export type OrderHeaderType = {
  type: ENUM_STATUS | 'completed';
};

const OrderStatus: FC<OrderHeaderType> = ({ type }) => {
  const { bgColor, borderColor, text, textColor } = ORDER_STATUS[type];
  return (
    <span
      style={{
        backgroundColor: bgColor,
        borderColor: borderColor,
        color: textColor
      }}
      className={`border-[1.5px] rounded-[40px] text-xs leading-[19px] px-4 py-1`}>
      {text}
    </span>
  );
};

export default OrderStatus;
