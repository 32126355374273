import React, { useContext, useEffect, useState } from 'react';
import CardWithHeader from '../../../base/cards/card-with-header';
import { apiService } from '../../../../services/base.service';
import { UserProfileContext } from '../context';

type BrandMealType = {
  name: string;
  order_length: number;
};

type PayloadType = {
  start_date: Date;
  end_date: Date;
};

const UserStats = ({ id }: { id: string }) => {
  const [topBrands, setTopBrands] = useState([] as BrandMealType[]);
  const [topMeals, setTopMeals] = useState([] as BrandMealType[]);
  const [loading, setLoading] = useState(false);

  const { filters } = useContext(UserProfileContext);

  const getMeals = async (payload: PayloadType) => {
    const res = await apiService.post(`user/one/meals/${id}`, payload);
    return res.data.data;
  };
  const getBrands = async (payload: PayloadType) => {
    const res = await apiService.post(`user/one/brands/${id}`, payload);
    return res.data.data;
  };

  const getStats = async () => {
    const { startDate: start_date, endDate: end_date } = filters.date;
    const payload = { start_date, end_date };
    setLoading(true);
    const [mealRes, brandRes] = await Promise.all([getMeals(payload), getBrands(payload)]);

    let brands: BrandMealType[] = brandRes?.map((brand: any) => ({
      name: brand?.brand?.name,
      order_length: brand?.orders.length
    }));

    brands = brands?.sort((a, b) => b?.order_length - a?.order_length)?.splice(0, 10);

    let meals: BrandMealType[] = mealRes?.map((meal: any) => ({
      name: meal?.meal?.name,
      order_length: meal?.orders_length
    }));

    meals = meals?.sort((a, b) => b?.order_length - a?.order_length)?.splice(0, 10);

    setTopBrands(brands);
    setTopMeals(meals);
    setLoading(false);
  };

  useEffect(() => {
    getStats();
  }, [filters]);
  return (
    <>
      <CardWithHeader title='top ordered items'>
        {loading ? (
          <p>Loading...</p>
        ) : topMeals.length == 0 ? (
          '-'
        ) : (
          topMeals?.map(({ name, order_length }, i) => (
            <div key={i}>
              <p key={i}>
                {name} ({`${order_length} ${order_length > 1 ? 'orders' : 'order'}`})
              </p>
            </div>
          ))
        )}
      </CardWithHeader>
      <CardWithHeader title='top brands'>
        {loading ? (
          <p>Loading...</p>
        ) : topMeals.length == 0 ? (
          '-'
        ) : (
          topBrands?.map(({ name, order_length }, i) => (
            <div key={i}>
              <p key={i}>
                {name} ({`${order_length} ${order_length > 1 ? 'orders' : 'order'}`})
              </p>
            </div>
          ))
        )}
      </CardWithHeader>
    </>
  );
};

export default UserStats;
