import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { BRAND_NAMES, CREATE_ORDER_LIST } from '../../../utils/constants';
import { SelectedMealType, UserType } from '../../../utils/types';
import { formatAddonCategory, formatMealCategory } from '../functions';
import DeleteToast from '../right-section/delete-toast';
import { AccordionType, PaymentMethodType, PaymentType } from '../types';
import { CreateOrderContext } from './create-order-context';

export const BasketContext = createContext({} as AccordionType);

const BasketContextProvider = ({ children }: { children: ReactNode }) => {
  const {
    formData,
    setFormData,
    setAddonSelection,
    setLoading,
    setIdx,
    setIsDelete,
    setIsEdit,
    isEdit,
    setAddonCategory,
    brands,
    setActive,
    setTabList,
    resetTabs,
    editStep,
    setEditStep,
    type,
    tempMeal,
    setTempMeal
  } = useContext(CreateOrderContext);
  const [cardArr, setCardArr] = useState([] as PaymentType[]);
  const selectedmealsArray = Object.values(formData?.selectedMeals);
  const [paymentType, setPaymentType] = useState('' as PaymentMethodType);
  const [cardId, setCardId] = useState('');
  const [user, setUser] = useState({} as UserType);
  const arr = selectedmealsArray.map((item) => String(item?.name));
  const [open, setOpen] = useState<string[]>(arr);

  const handleOpenAccordion = (name: string) => {
    if (open?.includes(name)) {
      const filteredIdx = open?.filter((brandName) => brandName !== name);
      setOpen(filteredIdx);
    } else {
      setOpen([...open, name]);
    }
  };

  const onAddClick = (meal: SelectedMealType, idx: number) => {
    const brandName = meal?.brandName || '';
    const curr = formData.selectedMeals[brandName].meals[idx];
    formData.selectedMeals[brandName].meals[idx] = {
      ...curr,
      quantity: curr.quantity + 1
    };
    setFormData({ ...formData });
  };

  const onSubtractClick = (meal: SelectedMealType, idx: number) => {
    const brandName = meal?.brandName || '';
    const curr = formData.selectedMeals[brandName].meals[idx];
    formData['selectedMeal'] = curr?.name;
    if (curr.quantity > 1) {
      formData.selectedMeals[brandName].meals[idx] = {
        ...curr,
        quantity: curr.quantity - 1
      };
    } else {
      formData.selectedMeals[brandName].meals = formData.selectedMeals[brandName].meals.filter(
        ({ id }) => id !== meal?.id
      );
    }
    setFormData({ ...formData });
  };

  const handleEditShopMeal = (meal: SelectedMealType) => {
    setTabList(CREATE_ORDER_LIST);
    setActive(CREATE_ORDER_LIST[1].name);
    const filteredbrand = brands.find(({ name }) => name === formData?.brandName);
    if (filteredbrand) {
      const mealCat = formatMealCategory(
        JSON.parse(filteredbrand?.meal_categories),
        meal?.quantity
      );
      setFormData({ ...formData, meal_categories: mealCat });
    }
  };

  const handleEditAddon = async (meal: SelectedMealType) => {
    setTabList([]);
    const res = await formatAddonCategory(meal?.id);
    if (res.length > 0) {
      setAddonCategory(res);
      setAddonSelection({
        ...meal?.addons
      });
    }
  };

  const onEditClick = async (meal: SelectedMealType, idxx: number) => {
    setAddonCategory([]);
    if (isEdit) {
      resetTabs();
      return;
    }
    if (type == 'edit') {
      if (editStep) setEditStep(false);
    }
    setIsDelete(false);
    setLoading?.(true);
    setIdx(idxx);
    const mealCopy = JSON.parse(JSON.stringify(meal));
    setTempMeal(mealCopy);
    formData.brandName = meal?.brandName || '';
    formData.selectedMeal = meal?.name;
    if (
      meal?.is_addon ||
      meal?.item_type === 'SHOP' ||
      formData?.brandName === BRAND_NAMES?.COCKTAIL_CLUB
    ) {
      handleEditShopMeal(meal);
    } else {
      await handleEditAddon(meal);
    }
    setLoading?.(false);
  };

  const handleCreateDeleteClick = (meal: SelectedMealType, brandName: string) => {
    if (type === 'create') {
      formData.selectedMeals[brandName].meals = formData?.selectedMeals[brandName].meals.filter(
        (item) => item.id !== meal?.id
      );
      setFormData({ ...formData });
      resetTabs();
    }
  };

  const handleEditDeleteClick = (meal: SelectedMealType, brandName: string, idx: number) => {
    if (type === 'edit') {
      toast(
        <DeleteToast
          meal={meal}
          brandName={brandName}
          formData={formData}
          setFormData={setFormData}
          idx={idx}
          setIsDelete={setIsDelete}
        />
      );
    }
  };

  const onDeleteClick = (meal: SelectedMealType, idxx: number) => {
    setIsDelete(true);
    setIsEdit(false);
    setIdx(idxx);
    const mealCopy = JSON.parse(JSON.stringify(meal));
    setTempMeal(mealCopy);
    const brandName = meal?.brandName || '';
    handleCreateDeleteClick(meal, brandName);
    handleEditDeleteClick(meal, brandName, idxx);
  };

  const onUndoClick = (meal: SelectedMealType, idxx: number) => {
    const brandName = meal?.brandName || '';
    if (tempMeal) formData.selectedMeals[brandName].meals[idxx] = tempMeal;
    setFormData({ ...formData });
    resetTabs();
  };

  const onSaveClick = (meal: SelectedMealType, idxx: number) => {
    const brandName = meal?.brandName || '';
    formData.selectedMeals[brandName].meals[idxx].is_edited = true;
    setFormData({ ...formData });
    resetTabs();
  };

  const confirmMeal = (meal: SelectedMealType, idxx: number) => {
    const brandName = meal?.brandName || '';
    formData.selectedMeals[brandName].meals[idxx].is_confirmed = true;
    resetTabs();
    setFormData({ ...formData, selectedMeal: '' });
  };

  useEffect(() => {
    setOpen([...arr]);
  }, [formData?.selectedMeals]);

  return (
    <BasketContext.Provider
      value={{
        open,
        handleOpenAccordion,
        onAddClick,
        onSubtractClick,
        onEditClick,
        onDeleteClick,
        onUndoClick,
        onSaveClick,
        confirmMeal,
        cardArr,
        user,
        setUser,
        setCardArr,
        paymentType,
        setPaymentType,
        cardId,
        setCardId
      }}>
      {children}
    </BasketContext.Provider>
  );
};

export default BasketContextProvider;
