import React, { FC, ReactNode } from 'react';
import { ToastContainerProps } from 'react-toastify';
import styles from './toast.module.scss';
import './toast-global.scss';
import { CloseOutlineIcon } from 'cokitchen-icons';

// add more toast types here and style in the module
type ToastType = 'success' | 'error' | 'warn';

export const DEFAULT_TOAST_OPTIONS: ToastContainerProps = {
  autoClose: 15000,
  closeButton: false,
  icon: false,
  hideProgressBar: true,
  toastClassName: 'toastify__wrapper',
  bodyClassName: 'toastify__body'
};

export type ToastMessageProps = {
  type: ToastType;
  message?: string | JSX.Element;
  onClose?: () => void;
  isNetworkError?: boolean;
  children?: ReactNode;
};

const BaseToast: FC<ToastMessageProps> = ({ onClose, message, type, isNetworkError, children }) => {
  const bgColor = `toastify__${type}`;
  return (
    <div className={`${styles['toastify__content']} ${styles[bgColor]}`}>
      <span className={`${styles['toastify__alert']}`}>
        {type === 'success' ? 'ALERT 🎉' : 'OOPS 😓'}
      </span>
      <span className={styles['toastify__text']}>
        {isNetworkError ? 'Network Error, Kindly Refresh' : message}
      </span>
      {!children && (
        <button onClick={onClose} className={`${styles['toastify__close']}`}>
          <CloseOutlineIcon />
        </button>
      )}
      {children}
    </div>
  );
};

export default BaseToast;
