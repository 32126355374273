import React, { ReactElement } from 'react';
import styles from '../index.module.css';
import { ReactComponent as TickIcon } from '../../../assets/icons/tick-circle.svg';

type Props = {
  start?: boolean;
  end?: boolean;
  text: string;
  innerText?: string;
  time?: string | null;
  timeSince?: string | null;
  children?: ReactElement<any, any>;
  hideMark?: boolean;
};

const getDoubleDigitsNumber = (number: number) => {
  if (number < 10) return `0${number}`;
  return `${number}`;
};
const formatTime = (timeObj: Date) => {
  return `${getDoubleDigitsNumber(timeObj.getHours())} : ${getDoubleDigitsNumber(
    timeObj.getUTCMinutes()
  )}`;
};

const getDurationSince = (timeObj: Date, timeSince: Date) => {
  const timeDivisions = [
    { name: 'days', duration: 24 * 60 * 60 * 1000 },
    { name: 'hours', duration: 60 * 60 * 1000 },
    { name: 'mins', duration: 60 * 1000 },
    { name: 'sec', duration: 1000 }
  ];
  const diff = timeSince.getTime() - timeObj.getTime();
  const division = timeDivisions.find((division) => {
    if (diff / division.duration > 1) return true;
    return false;
  });
  if (!division) return '0 mins';
  return `${Math.ceil(diff / division.duration)} ${division.name}`;
};
const TrackOrderState = ({
  start,
  end,
  text,
  time,
  innerText,
  children,
  hideMark,
  timeSince = new Date().toISOString()
}: Props) => {
  let active = true;
  if (!time) active = false;
  const timeObj = time === undefined || time === null ? undefined : new Date(time);

  return (
    <>
      <div className='flex'>
        <div className={styles['track-mark'] + (active ? ` ${styles['active']}` : '')}>
          <div className={start ? 'invisible' : ''}></div>
          {hideMark ? <svg style={{ height: 0 }}></svg> : <TickIcon></TickIcon>}
          <div className={end ? 'invisible' : ''}></div>
        </div>
        <div className='flex-auto self-center ml-2'>
          <div className={active ? '' : 'text-gray-8'}>{text}</div>
          <div className='text-sm text-gray-5'>{innerText}</div>
        </div>
        <div className='self-center'>
          {active ? (
            <div>
              <div className='text-sm text-gray-5'>{timeObj && formatTime(timeObj)}</div>
              <div className='text-sm text-gray-5'>
                {timeObj && timeSince && getDurationSince(timeObj, new Date(timeSince))}
              </div>
            </div>
          ) : (
            <div className={`${styles['faint-background-button']} text-sm text-gray-5`}>
              Pending
            </div>
          )}
        </div>
      </div>
      {children}
    </>
  );
};

export default TrackOrderState;
