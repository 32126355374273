import React, { FC } from 'react';
import { ORDER_TAG } from '../../../utils/constants';

type OrderTagType = {
  type: keyof typeof ORDER_TAG;
};
const OrderTag: FC<OrderTagType> = ({ type }) => {
  const { text, bgColor } = ORDER_TAG[type];
  return (
    <span
      style={{
        backgroundColor: bgColor
      }}
      className='text-white rounded-[20px] px-[10px] py-[1px] leading-[19px] text-xs'>
      {text}
    </span>
  );
};

export default OrderTag;
