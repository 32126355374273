import moment from 'moment';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { apiService } from '../../../../services/base.service';
import { TransactionType, UserType } from '../../../../utils/types';
import UserTransactionCard from '../../../base/cards/user-transaction-card';
import Loader from '../../../base/loader';
import './styles.scss';

type TransactionMonthType = {
  [key: string]: TransactionType[];
};
const UserTransactions = ({ user }: { user: UserType }) => {
  const [pageCount, setPageCount] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([] as TransactionType[]);
  const [hasMore, setHasMore] = useState(false);
  const [months, setMonths] = useState({} as TransactionMonthType);

  const loadTransactions = async () => {
    setLoading(true);
    const { id: user_id } = user;
    const res = await apiService.post(`user/one/transactions/${pageCount}`, {
      user_id
    });
    const response = res?.data;
    const data: TransactionType[] = response?.data;
    setPageCount(pageCount);
    setTransactions([...transactions, ...data]);
    setTotal(response?.total);
    setLoading(false);
  };

  const getMonthKey = (curr: TransactionType) => {
    let itemDate;
    const date = new Date();
    const currYear = String(date.getFullYear());
    const itemYear = moment(curr.created_at).format('YYYY');
    if (currYear === itemYear) {
      itemDate = moment(curr.created_at).format('MMMM');
    } else {
      itemDate = moment(curr.created_at).format('MMMM YYYY');
    }

    return itemDate;
  };
  const formatData = () => {
    const months = transactions.reduce((transaction: TransactionMonthType, curr) => {
      const date = getMonthKey(curr);
      if (!transaction[date]) {
        transaction[date] = [];
      }
      transaction[date] = [...transaction[date], curr];
      return transaction;
    }, {});
    setMonths(months);
  };

  const getNextPage = () => {
    setPageCount(pageCount + 1);
  };

  useEffect(() => {
    setHasMore(transactions.length < total);
  }, [transactions]);

  useEffect(() => {
    loadTransactions();
  }, [pageCount, user]);

  useEffect(() => {
    formatData();
  }, [transactions]);

  return (
    <div className='mt-5'>
      {loading && pageCount === 1 && <Loader />}
      <InfiniteScroll
        hasMore={hasMore}
        next={() => getNextPage()}
        height={transactions.length > 0 ? 400 : 100}
        dataLength={transactions?.length}
        loader={
          <div className='mt-5'>
            <Loader />
          </div>
        }
        endMessage={<p className='text-center mt-8'>No more transactions to show</p>}>
        <div className={`wrapper no-scroll-bar ${transactions.length > 0 && 'tranx'}`}>
          {Object.entries(months)?.map(([key, value], idx) => (
            <div key={idx}>
              {value?.length > 0 && (
                <p className='title uppercase text-gray-4 text-xs py-6'>{key}</p>
              )}
              {value?.map((tranx, i) => (
                <UserTransactionCard item={tranx} key={i} />
              ))}
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default UserTransactions;
