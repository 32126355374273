import React from 'react';
import './styles.scss';
import { RatingFilledIcon, RatingIcon } from 'cokitchen-icons';
import { RatingType } from '../../../utils/types';

const UserRating = ({ rating, big = false }: { rating: RatingType; big?: boolean }) => {
  return (
    <div className={`user-rating ${big && 'big'}`}>
      {rating === '1.00' ? (
        <div className=' '>
          <RatingFilledIcon />
          <RatingIcon />
          <RatingIcon />
          <RatingIcon />
          <RatingIcon />
        </div>
      ) : rating === '2.00' ? (
        <div className=' flex gap-1 items-center'>
          <RatingFilledIcon />
          <RatingFilledIcon />
          <RatingIcon />
          <RatingIcon />
          <RatingIcon />
        </div>
      ) : rating === '3.00' ? (
        <div className=' flex gap-1 items-center'>
          <RatingFilledIcon /> <RatingFilledIcon /> <RatingFilledIcon />
          <RatingIcon />
          <RatingIcon />
        </div>
      ) : rating === '4.00' ? (
        <div className=' flex gap-1 items-center'>
          <RatingFilledIcon />
          <RatingFilledIcon /> <RatingFilledIcon /> <RatingFilledIcon />
          <RatingIcon />
        </div>
      ) : (
        rating === '5.00' && (
          <div className=' flex gap-1 items-center'>
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingFilledIcon />
            <RatingFilledIcon />
          </div>
        )
      )}
    </div>
  );
};

export default UserRating;
