import React, { useContext, useEffect, useState } from 'react';
import {
  CardIcon,
  ArrowDownIcon,
  CheckFilledIcon,
  CreditIcon,
  TransferIcon,
  WalletIcon
} from 'cokitchen-icons';
import { BasketContext } from '../contexts/basket-context';
import { formatNumber } from '../../../utils/functions';

const PaymentMethod = () => {
  const [open, setOpen] = useState(true);
  const [active, setActive] = useState('');
  const { cardArr, user, setPaymentType, paymentType, setCardId } = useContext(BasketContext);

  const handleTransfer = () => {
    setPaymentType('transfer');
  };

  const handleCredit = () => {
    setPaymentType('credit');
  };

  const handleWallet = () => {
    setPaymentType('wallet');
  };
  const PAYMENT_ARR = [
    {
      title: 'Transfer',
      description: 'Share bani accounts with customers',
      icon: <TransferIcon />,
      onclick: handleTransfer
    },
    {
      title: 'Credit (Debt)',
      description: 'payment will be added to user debt and deducted on next order',
      icon: <CreditIcon />,
      onclick: handleCredit
    },
    {
      title: `Wallet (Bal.  ${formatNumber(user?.balance)})`,
      description: 'Amount will be debited from user wallet',
      icon: <WalletIcon />,
      onclick: handleWallet
    }
  ];

  const handleCardClick = (id: string) => {
    setPaymentType('card');
    setCardId(id);
  };

  useEffect(() => {
    if (paymentType != 'card') setCardId('');
  }, [paymentType]);
  return (
    <div className='space-y-5 pt-5'>
      <label className='font-medium text-gray-6'>Select payment method</label>
      <div>
        {cardArr?.length > 0 && (
          <div>
            <div className='flex gap-4 items-start pb-3' onClick={() => setOpen(!open)}>
              <CardIcon className='w-7 h-7 p-1 flex justify-center items-center rounded-[100%] border border-gray-12' />
              <div className='w-[80%]'>
                <label className='text-gray-8 font-medium text-sm'>Saved card</label>
                <p className='text-xs'>
                  Request permission to debit saved card from customers first
                </p>
              </div>
              {!open ? (
                <ArrowDownIcon className='icon' />
              ) : (
                <ArrowDownIcon className='rotate-180 icon' />
              )}
            </div>

            {open &&
              cardArr?.map(({ id, title, icon }, i) => (
                <div
                  className={`inactive ${active === title && 'active'}`}
                  key={i}
                  onClick={() => {
                    setActive(title);
                    handleCardClick(id || '');
                  }}>
                  <div className='w-7 h-7 p-1 flex justify-center items-center rounded-[100%] border border-gray-12'>
                    {icon}
                  </div>
                  <label className='w-[80%] text-gray-6 font-medium text-sm'>
                    {title}
                    <label></label>
                  </label>
                  <CheckFilledIcon />
                </div>
              ))}
          </div>
        )}
        {PAYMENT_ARR.map(({ title, description, icon, onclick }, i) => (
          <div
            className={`inactive ${active === title && 'active'}`}
            key={i}
            onClick={() => {
              setActive(title);
              onclick();
            }}>
            <div className='w-7 h-7 p-1 flex justify-center items-center rounded-[100%] border border-gray-12'>
              {icon}
            </div>
            <div className='w-[80%]'>
              <label className='text-gray-8 font-medium text-sm'>{title}</label>
              <p className='text-xs'>{description}</p>
            </div>
            <CheckFilledIcon />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PaymentMethod;
