import { isEqual } from 'lodash';
import { ActionType } from '../../components/base/order-action-tags';
import { apiService } from '../../services/base.service';
import {
  AddonCategoryType,
  AddonMealType,
  AddonType,
  AddressDetailsType,
  AllSelectedMealsType,
  CreateOrderFormType,
  MealCategoryType,
  MealType,
  OrderType,
  SelectedMealType
} from '../../utils/types';
import { OrderActionType } from './types';

export const doesAddonExist = (itemId: string, addonSelection: AddonMealType) => {
  const val = Object.values(addonSelection).filter(({ id }) => id === itemId);
  return val.length === 1 ? true : false;
};

export const doesMealExist = (mealId: string, formData: CreateOrderFormType) => {
  const brandName = formData.brandName;
  const val = formData.selectedMeals[brandName].meals.find(({ id }) => id === mealId);
  return val;
};

export const mealIsConfirmed = (formData: CreateOrderFormType, item: MealType) => {
  const brandName = formData?.brandName;
  const hasMeal = formData?.selectedMeals[brandName]?.meals.length > 0;
  const idx = formData?.selectedMeals[brandName]?.meals?.length - 1 || 0;
  return !(
    hasMeal &&
    formData?.selectedMeals[brandName]?.meals[idx]?.is_confirmed == false &&
    formData?.selectedMeal !== item?.name
  );
};
export const handleAddAddon = (addon: AddonType, addonSelection: AddonMealType) => {
  const addonId = addon.meal_addon_id;
  if (doesAddonExist(addonId, addonSelection)) {
    addonSelection[addon.meal_addon_id].quantity++;
  } else {
    addonSelection[addon.meal_addon_id] = {
      name: addon.meal_data.name,
      id: addon.meal_data.id,
      quantity: 1,
      price: Number(addon.meal_data?.amount)
    };
  }
  return { ...addonSelection };
};

export const handleSubtractAddon = (addon: AddonType, addonSelection: AddonMealType) => {
  const addonId = addon.meal_addon_id;
  if (doesAddonExist(addonId, addonSelection) && addonSelection[addon.meal_addon_id].quantity > 1) {
    addonSelection[addon.meal_addon_id].quantity--;
  } else {
    delete addonSelection[addon.meal_addon_id];
  }
  return { ...addonSelection };
};

export const loadAddonCategory = async (id: string) => {
  const res = await apiService.post(
    'internal/meal-addon',
    {
      meal_id: id,
      by_category: true
    },
    true,
    true
  );
  return res?.data?.data;
};

export const formatAddonCategory = async (meal_id: string) => {
  const addon_cat = await loadAddonCategory(meal_id);
  if (addon_cat.length > 0 && addon_cat[0].position === -1) {
    addon_cat[0].position = addon_cat.length;
  }
  const addon_category = addon_cat.map((item: AddonCategoryType) => {
    if (item?.meal_category_selection_type?.name === 'MULTI_SELECTION_WITH_QUANTITY') {
      return {
        ...item,
        addons: item.addons.map((itm) => {
          return {
            ...itm,
            meal_data: {
              ...itm?.meal_data,
              quantity: 0
            }
          };
        })
      };
    }
    return item;
  });

  return [...addon_category];
};

export const formatMealCategory = (meal_cat: MealCategoryType[], quantity?: number) => {
  const meal_category = meal_cat.map((item: MealCategoryType) => {
    return {
      ...item,
      meals: item?.meals?.map((itm) => {
        return {
          ...itm,
          quantity: quantity ?? 0
        };
      })
    };
  });
  return [...meal_category];
};

export const updateAddons = (
  formData: CreateOrderFormType,
  addonSelection: AddonMealType,
  idx: number | null
) => {
  const index =
    idx == 0
      ? idx
      : idx !== null
      ? idx
      : formData.selectedMeals[formData?.brandName].meals.length - 1;
  const selectedMeal = formData?.selectedMeals?.[formData?.brandName]?.meals?.[index];
  formData.selectedMeals[formData.brandName].meals[index] = {
    ...selectedMeal,
    addons: { ...addonSelection }
  };
};

export const checkMealChanged = (
  type: OrderActionType,
  tempMeal: SelectedMealType,
  formData: CreateOrderFormType,
  idx: number
) => {
  const selectedMeal = formData?.selectedMeals?.[formData?.brandName]?.meals?.[idx];
  const equalMeal = isEqual(selectedMeal, tempMeal);
  if (!equalMeal) return true;
};

export const getMealTotal = (meal: SelectedMealType) => {
  const mealPrice = meal?.price;
  const mealQuantity = meal?.quantity;
  const addonTotalArr: number[] = [];
  let addonTotal = 0;
  if (meal?.addons) {
    // get addon total
    Object.values(meal?.addons).map(({ quantity, price }) => {
      const total = quantity * price;
      addonTotalArr.push(total);
    });
    addonTotal = addonTotalArr.reduce((sum, curr) => sum + curr, 0);
  }
  const mealAddontotal = mealPrice + addonTotal;
  const total = mealAddontotal * mealQuantity;
  return total;
};

export const allMealsTotal = (meals: AllSelectedMealsType) => {
  const mealArr: number[] = [];
  Object.values(meals).map(({ meals }) => meals.map((meal) => mealArr.push(getMealTotal(meal))));
  const totalMealAmount = mealArr.reduce((sum, curr) => sum + curr, 0);
  return totalMealAmount;
};

export const getTotalMeals = (selectedMeals: AllSelectedMealsType) => {
  const mealLengtharr: number[] = [];
  Object.values(selectedMeals).map(({ meals }) => mealLengtharr.push(meals.length));
  const totalNoOfMeals = mealLengtharr.reduce((sum, curr) => sum + curr, 0);
  return totalNoOfMeals;
};

export const getPayload = (formData: CreateOrderFormType, addressDetails: AddressDetailsType) => {
  const selectedMealsArr: any = [];
  Object.values(formData?.selectedMeals).map(({ meals }) =>
    meals.map(({ id, quantity, addons }) => {
      const newArr = {
        id,
        quantity,
        addons: addons
          ? Object.values(addons).map(({ id, quantity }) => ({
              id,
              quantity
            }))
          : []
      };
      selectedMealsArr.push(newArr);
    })
  );
  addressDetails.user_address.phone_number = addressDetails.phoneNo;
  const payload = {
    meals: selectedMealsArr,
    pickup: false,
    address_details: addressDetails?.user_address,
    lat: addressDetails?.lat,
    lng: addressDetails?.lng,
    cokitchen_polygon_id: addressDetails?.cokitchen_polygon_id
  };

  return payload;
};

export const getFormattedData = (data: OrderType) => {
  const orderData = data;
  const meals = orderData?.calculated_order?.meals;
  const newData: AllSelectedMealsType[] = meals?.map((item) => ({
    [item.brand.name]: {
      name: item?.brand?.name,
      meals: item.meals.map((meal) => {
        const formattedAddons: AddonMealType[] = meal?.addons.map((addon) => {
          const addon_id =
            addon?.meal_addon_category?.meal_category_selection_type?.name === 'SINGLE_SELECTION'
              ? addon?.meal_addon_category?.id
              : addon?.meal_data?.id;
          return {
            [addon_id]: {
              name: addon?.meal_data?.name,
              id: addon?.meal_data?.id,
              quantity: Number(addon?.meal_data?.quantity) || 1,
              price: addon?.meal_data?.amount,
              brandName: item?.brand?.name,
              is_addon: addon?.meal_data?.is_addon,
              item_type: addon?.meal_data?.item_type
            }
          };
        });

        return {
          name: meal?.name,
          id: meal?.id,
          quantity: meal?.quantity,
          price: Number(meal?.amount) || 1,
          brandName: item?.brand?.name,
          is_addon: meal?.is_addon,
          item_type: meal?.item_type,
          is_confirmed: true,
          is_deleted: false,
          is_edited: false,
          addons: formattedAddons?.reduce?.((result, currentObj) => {
            for (const key in currentObj) {
              result[key] = currentObj[key];
            }
            return result;
          }, {})
        };
      })
    }
  }));

  return newData;
};

export const getFormattedMeals = (data: OrderType) => {
  const newData = getFormattedData(data);
  if (newData) {
    const obj: AllSelectedMealsType = newData?.reduce((data, currentObj) => {
      for (const key in currentObj) {
        data[key] = currentObj[key];
      }
      return data;
    }, {});
    return obj;
  }
};

export const getEditedMeals = (meals: SelectedMealType[]) => {
  let count = 0;
  for (let i = 0; i < meals.length; i++) {
    if (meals[i].is_edited) count++;
  }
  return count;
};

export const getPriceDifference = (
  selectedMeals: AllSelectedMealsType,
  editedMeals: AllSelectedMealsType
) => {
  let type: ActionType;
  const editMealsSum = allMealsTotal(editedMeals);
  const totalMealsSum = allMealsTotal(selectedMeals);
  const val = Math.abs(editMealsSum - totalMealsSum);
  if (editMealsSum > totalMealsSum) {
    type = 'add';
  } else {
    type = 'remove';
  }

  return {
    type,
    val
  };
};
