import React from 'react';

const Loader = ({ className }: { className?: string }) => {
  return (
    <div className={`flex items-center justify-center h-full  font-semibold ${className}`}>
      Loading...
    </div>
  );
};

export default Loader;
