import React from 'react';
import DashboardLayout from '../../layouts/dashboard.layout';
import LeftSection from './left-section';
import RightSection from './right-section';
import CreatOrderContextProvider from './contexts/create-order-context';

const CreateOrder = () => {
  return (
    <DashboardLayout>
      <CreatOrderContextProvider>
        <div className='grid md:grid-cols-3 gap-4 items-start'>
          <LeftSection />
          <RightSection />
        </div>
      </CreatOrderContextProvider>
    </DashboardLayout>
  );
};

export default CreateOrder;
