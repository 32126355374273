import { BaseModal, Button, Input } from '@cokitchen/cokitchen-components';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { ReactComponent as LocationAdd } from '../../../../assets/icons/location-add.svg';
import { LiveTripOrderType, UserType } from '../../../../utils/types';
import { apiService } from '../../../../services/base.service';
import { formatNumber } from '../../../../utils/functions';
import AddToWallet from './add-to-wallet';
import DebitWallet from './debit-wallet';

type ActionType = 'credit' | 'debit' | 'none';

export type AddressDataType = {
  new_address: string;
  previous_fee: number;
  current_address: string;
  new_fee: number;
  added_or_deducted_fee: number;
  type: ActionType;
  name: string;
};

type LatLngType = {
  lat: string;
  lng: string;
};
const ChangeAddressModal = ({
  onClose,
  order
}: {
  onClose: (withRes?: boolean) => void;
  order: LiveTripOrderType;
}) => {
  const [address, setAddress] = useState('');
  const [addressDetails, setAddressDetails] = useState({} as any);
  const [latLng, setLatLng] = useState({} as LatLngType);
  const [loadingPolygon, setLoadingPolygon] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cordinateDetails, setCordinateDetails] = useState({} as any);
  const [error, setError] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState({} as AddressDataType);
  const [user, setUser] = useState({} as UserType);
  const [addToWallet, setAddToWallet] = useState(false);
  const [debitWallet, setDebitWallet] = useState(false);
  const [changeAddrBtn, setChangeAddrBtn] = useState(false);

  const getAddressDetails = async (value: string) => {
    const results = await geocodeByAddress(value);
    let latLng;
    if (results) latLng = await getLatLng(results[0]);

    if (results && latLng) {
      setAddressDetails(results[0]);
      setLatLng({ lat: String(latLng?.lat), lng: String(latLng?.lng) });
    }
  };

  const handleChange = async (value: string) => {
    resetStates();
    await getAddressDetails(value);
    setLoadingPolygon(false);
  };

  const resetStates = () => {
    setShowDetails(false);
    setChangeAddrBtn(false);
    setDebitWallet(false);
    setAddToWallet(false);
    setError('');
    setData({} as AddressDataType);
  };

  const getCokitchenPolygonId = async (payload: any) => {
    try {
      const res = await apiService.post('internal/location', payload, true, true);
      if (res?.data) {
        if (res?.data?.explore) {
          setError('Polygon Not Found, Try Another Address!');
        } else {
          setCordinateDetails(res?.data);
          setShowDetails(true);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUser = async () => {
    if (!order?.user_id) return;
    const res = await apiService.get(`user/one/${order?.user_id}`, {});
    setUser(res?.data?.user);
  };
  const handleModal = async (type: ActionType) => {
    if (type === 'credit') {
      setAddToWallet(true);
    } else if (type === 'debit') {
      setDebitWallet(true);
    } else if (type === 'none') {
      setChangeAddrBtn(true);
    }
  };

  const getActionType = (prevFee: number, newFee: number) => {
    let type: ActionType;
    if (prevFee === newFee) {
      type = 'none';
    } else if (prevFee < newFee) {
      type = 'debit';
    } else {
      type = 'credit';
    }
    return type;
  };

  const calcNewFee = () => {
    const name =
      order?.calculated_order?.user?.first_name + ' ' + order?.calculated_order?.user?.last_name;
    const previous_fee = parseFloat(order?.calculated_order?.delivery_fee);
    const current_address = order?.calculated_order?.address_details?.address_line;
    const new_address = addressDetails?.formatted_address;
    const new_fee = cordinateDetails
      ? parseFloat(cordinateDetails?.cokitchen_polygon?.delivery_fee || 0) *
        (order?.calculated_order?.surge ? parseFloat(order?.calculated_order?.surge) : 1)
      : 0;
    //fee to be added or deducted
    const added_or_deducted_fee = Math.abs(new_fee - previous_fee);
    const type = getActionType(previous_fee, new_fee);
    setData({
      new_address,
      previous_fee,
      current_address,
      new_fee,
      added_or_deducted_fee,
      type,
      name
    });
  };

  const changeDeliveryAddress = async () => {
    setLoading(true);
    const data = {
      cokitchen_polygon_id: cordinateDetails?.cokitchen_polygon?.id,
      lat: String(latLng.lat),
      lng: String(latLng.lng),
      order_code: order?.order_code,
      address_details: {
        name: 'other',
        city: addressDetails?.address_components[2]?.long_name,
        address_line: addressDetails?.formatted_address,
        phone_number: order?.calculated_order?.user?.phone_number
      }
    };
    const res = await apiService.post('logistics/super-admin/order/address/change', data);
    if (res) {
      setAddress('');
      resetStates();
      onClose(true);
    }
    setLoading(false);
  };
  const searchOptions = {
    componentRestrictions: { country: ['ng'] }
  };

  const fee = formatNumber(data.added_or_deducted_fee?.toFixed(2));
  useEffect(() => {
    if (addressDetails?.formatted_address && latLng.lat) getCokitchenPolygonId(latLng);
  }, [latLng, addressDetails]);

  useEffect(() => {
    calcNewFee();
  }, [cordinateDetails]);

  useEffect(() => {
    handleModal(data?.type);
    if (data?.type !== 'none') getUser();
  }, [data]);

  return (
    <BaseModal
      isOpen
      title='Change Address'
      onRequestClose={() => onClose()}
      titleIcon={<LocationAdd />}
      titleIconColor='info'
      footer={
        changeAddrBtn ? (
          <Button
            color='primary'
            variant='block'
            className='h-[100%]'
            small
            loading={loading}
            onClick={changeDeliveryAddress}
            disabled={loading}>
            Change Address
          </Button>
        ) : undefined
      }>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleChange}
        searchOptions={searchOptions}>
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              {...getInputProps({
                placeholder: 'Enter address...',
                className: 'mb-2'
              })}
              label='User Address'
            />
            <div className=' '>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, i) => {
                const className = 'px-3 py-2 ';
                const style = suggestion.active
                  ? {
                      backgroundColor: '#F5F5F5',
                      transition: 'all .3s',
                      cursor: 'pointer'
                    }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                    key={i}>
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>

            {loadingPolygon && (
              <span className='capitalize text-gray-6 text-sm font-semibold'>Loading...</span>
            )}
            {error && <span className='capitalize text-sm text-red-5 font-semibold'>{error}</span>}

            {showDetails && (
              <>
                <div className='mt-4 text-sm'>
                  <p>
                    Address Will Be Changed From:
                    <span className='text-gray-5'> {data.current_address}</span> <br />
                    To:
                    <span className='text-gray-5'> {data.new_address}</span>
                  </p>
                  <p className='mt-4 pb-1'>
                    Previous Delivery Fee:
                    <span className=' '> {formatNumber(data.previous_fee?.toFixed(2))}</span>
                  </p>
                  <p>
                    New Delivery Fee:
                    <span className=' font-bold'> {formatNumber(data.new_fee?.toFixed(2))}</span>
                  </p>
                </div>

                {addToWallet || debitWallet ? (
                  <div className='mt-4'>
                    <p className=' font-medium text-sm'>
                      {data.type === 'credit' ? (
                        `Add  ${fee} to ${data.name}'s Wallet`
                      ) : (
                        <span className='font-medium'>
                          Select a payment type - Debit <span className='font-bold'>{fee}</span>
                        </span>
                      )}
                    </p>
                    {addToWallet && (
                      <AddToWallet
                        user_id={order?.user_id}
                        loading={loading}
                        setLoading={setLoading}
                        setAddToWallet={setAddToWallet}
                        setChangeAddrBtn={setChangeAddrBtn}
                      />
                    )}
                    {debitWallet && (
                      <DebitWallet
                        user_id={order?.user_id}
                        loading={loading}
                        setLoading={setLoading}
                        setDebitWallet={setDebitWallet}
                        setChangeAddrBtn={setChangeAddrBtn}
                        user={user}
                        data={data}
                      />
                    )}
                  </div>
                ) : null}
              </>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </BaseModal>
  );
};

export default ChangeAddressModal;
