import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layouts/dashboard.layout';
import { BrandType } from '../../utils/types';
import BrandReviews from './brand-reviews';
import BrandSelector from '../../components/base/brand-selector';
import { apiService } from '../../services/base.service';

const Reviews = () => {
  const [loading, setLoading] = useState(true);
  const [brands, setBrands] = useState<BrandType[]>([]);
  const [activeBrand, setActiveBrand] = useState({ name: 'All', id: 'ALL' } as BrandType);
  const [refreshKey, setRefreshKey] = useState(0);

  const fetchBrands = async () => {
    try {
      setLoading(true);
      const res = await apiService.get(
        'marketing/brand/reviews/85c7d173-56ed-44c8-8335-ef0eecabf454',
        null
      );
      const { brand_reviews = [] } = res.data;
      setBrands(brand_reviews);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  useEffect(() => {
    setRefreshKey(refreshKey + 1);
  }, [activeBrand, brands]);
  return (
    <DashboardLayout>
      <div className='mb-6'>
        <h1 className='font-semibold text-2xl mb-2'>Reviews</h1>
        <BrandSelector
          label='Select Brand'
          allBrands={brands}
          activeBrand={activeBrand}
          setActiveBrand={setActiveBrand}
        />
      </div>
      {loading ? (
        <div className='text-2xl leading-[40vh] text-center'>Loading....</div>
      ) : (
        <BrandReviews
          key={refreshKey}
          activeBrand={activeBrand}
          setActiveBrand={setActiveBrand}
          allBrands={brands}
        />
      )}
    </DashboardLayout>
  );
};

export default Reviews;
